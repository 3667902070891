import React, {useState} from 'react';
import {FaEdit} from "react-icons/fa";
import {MdCancel} from "react-icons/md";
import {useLot} from "../../../utilities/contexts/lot-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {doDeleteLotRow, doGetLotRows, doPostLotRow, doPutLotHeader, doPutLotRow} from "../../../logics/lots";
import HeaderEditForm from "./HeaderEditForm";
import LotHeaderDisplay from "./LotHeaderDisplay";
import RowAligned from "../../UI/Structure/RowAligned";
import Table from "./Rows/Table";
import Modal from "../../UI/Modal";
import EditRowForm from "./Rows/EditRowForm";

const LotHeader = () => {

    const {currentLot, setCurrentLot, loadRows, lotRows} = useLot();
    const {call} = useAsync();

    const [editingLot, setEditingLot] = useState(false);
    const [editRowModalOpen, setEditRowModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [formType, setFormType] = useState('edit');

    const [loading, setLoading] = useState(false);

    const handleRowClick = (row) => {
        setSelectedRow(row);
        setFormType('edit');
        setEditRowModalOpen(true);
    }

    const handleAdd = () => {
        setSelectedRow(null);
        setFormType('add');
        setEditRowModalOpen(true);
    }

    const handleDelete = async (row) => {
        console.log(row);
        if (window.confirm('Eliminare riga?')) {
            await call(() => doDeleteLotRow(currentLot.nrlotto, row.idriga),
                (res) => {
                    loadRows(lotRows.filter((r) => r.idriga !== row.idriga));

                }
            )
        }
    }

    const handleSubmit = async (fields) => {
        let notEmptyFields = {};
        if (fields.idart) notEmptyFields["idart"] = fields.idart;
        if (fields.idcol) notEmptyFields["idcol"] = fields.idcol;
        if (fields.idscelta) notEmptyFields["idscelta"] = fields.idscelta;
        if (fields.sp) notEmptyFields["sp"] = fields.sp;
        if (fields.qta) notEmptyFields["qta"] = fields.qta;
        if (fields.um) notEmptyFields["um"] = fields.um;
        if (fields.prezzo) notEmptyFields["prezzo"] = fields.prezzo;
        if (fields.nrpz) notEmptyFields["nrpz"] = fields.nrpz;

        console.log(notEmptyFields);


        if (formType === "edit") {
            await call(
                () => doPutLotRow(currentLot.nrlotto, selectedRow.idriga, fields),
                async () => {
                    await call(
                        () => doGetLotRows(currentLot.nrlotto),
                        (rows) => {
                            if (rows) {
                                console.log(rows);
                                loadRows(rows);
                                setEditRowModalOpen(false);
                            }
                        }
                    );
                }
            );
        } else if (formType === "add") {

            let params = new URLSearchParams();
            fields.idart && params.append('idart', fields.idart);
            fields.idcol && params.append('idcol', fields.idcol);
            fields.idscelta && params.append('idscelta', fields.idscelta);
            fields.sp && params.append('sp', fields.sp);
            fields.qta && params.append('qta', fields.qta);
            fields.um && params.append('um', fields.um);
            fields.prezzo && params.append('prezzo', fields.prezzo);
            fields.nrpz && params.append('nrpz', fields.nrpz);

            await call(
                () => doPostLotRow(currentLot.nrlotto, params),
                async () => {
                    await call(
                        () => doGetLotRows(currentLot.nrlotto),
                        (rows) => {
                            if (rows) {
                                console.log(rows);
                                loadRows(rows);
                            }
                        }
                    )

                }
            );

            setEditRowModalOpen(false);
        }
    }

    const handleEdit = async (values) => {

        setLoading(true);

        if((currentLot.nrlotto !== "" && currentLot.nrlotto === values.nrlotto) || values.nrlotto === "") {
            delete values.nrlotto;
        }

        await call(
            () => doPutLotHeader(currentLot.nrlotto, values),
            async (res) => {
                console.log(res);
                await setCurrentLot({...currentLot, ...res});
                setEditingLot(false);
            },
            (err) => {
                console.warn(err);
            }
        )

        setLoading(false);
    }

    return (
        <div className="flex-1 overflow-y-auto relative p-2" style={{minHeight: "30px"}}>
            <RowAligned className="justify-between">
                <div className="text-md uppercase font-bold text-primary">
                    {editingLot ? "Modifica testata lotto " : `Testata Lotto `}{currentLot && currentLot.nrlotto ? currentLot.nrlotto : ""}
                </div>
                <RowAligned
                    className="gap-2 rounded px-1 text-primary hover:bg-secondary hover:text-white cursor-pointer"
                    onClick={() => setEditingLot(!editingLot)}>
                    <div className="text-sm uppercase font-bold">
                        {editingLot ? "Annulla modifiche" : "Modifica lotto"}
                    </div>
                    {editingLot ? <MdCancel/> : <FaEdit/>}
                </RowAligned>
            </RowAligned>
            {!editingLot
                ? currentLot &&
                <div>
                    <LotHeaderDisplay header={currentLot} showPrices={false} showRows={false}/>
                    <Table onRowClick={handleRowClick}
                           onAdd={handleAdd}
                           onDelete={handleDelete}
                           className="mt-4"/>
                </div>
                : <div className="flex flex-col items-center">
                    <HeaderEditForm header={currentLot}
                                    onUndo={() => setEditingLot(false)}
                                    onSubmit={handleEdit}/>
                </div>
            }

            <Modal open={editRowModalOpen}>
                <div style={{width: "400px"}}>
                    <div className="font-bold text-xl mb-2 text-gray-600">{formType === "edit" ? "MODIFICA" : "AGGIUNGI"} RIGA</div>
                    <EditRowForm row={selectedRow}
                                 onSubmit={handleSubmit}
                                 onUndo={() => setEditRowModalOpen(false)}/>
                </div>
            </Modal>
        </div>
    );
};

export default LotHeader;
