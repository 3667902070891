import React from 'react';
import Row from "../../../../UI/Structure/Row";
import VerticalLabelledField from "../../../../UI/Fields/VerticalLabelledField";
import Input from "../../../../UI/Input";

const PriceRow = ({price, um, qta = 0, onChange, setPrice, setQta, choice}) => {

    const onPriceChange = (e) => {
        let price = e.target.value;
        setPrice && setPrice(price);
    }

    const onQtaChange = (e) => {
        let qta = e.target.value;
        setQta && setQta(qta);
    }

    if (price !== null)
        return (
            <Row>
                <VerticalLabelledField label={`Quantità ${um ? `(${um})` : ""}`}>
                    <Input placeholder="Quantità" value={qta} onChange={onQtaChange}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Prezzo">
                    <Input placeholder="€" value={price} onChange={onPriceChange}/>
                </VerticalLabelledField>
                {choice && choice.name &&
                <VerticalLabelledField label="Scelta">
                    <div>{choice?.name ?? ""}</div>
                </VerticalLabelledField>

                }
            </Row>
        )
    else return null;
}

export default PriceRow;