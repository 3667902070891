import React from 'react';
import {BiPlus} from "react-icons/bi";
import ArticleRow from "./ArticleRow";

const ArticlesTable = ({articles, ...props}) => {
    const handleCreate = () => {

    }

    return (
        <table className="w-full text-left">
            <thead>
            <tr className="border-b border-gray-600">
                <th className="pl-2 ">Nome</th>
                <th>
                    <span className="flex flex-row justify-center items-center w-full">
                        <BiPlus onClick={handleCreate}
                                title={'Nuovo articolo'}
                                className="cursor-pointer hover:text-green-400"/>
                    </span>
                </th>
            </tr>
            </thead>
            <tbody className=" w-full ">
            {articles && articles.length > 0 &&
            articles.map((article, i) =>
                <ArticleRow article={article} key={i} />
            )
            }
            </tbody>
        </table>
    );
};

export default ArticlesTable;