import React from 'react';
import {BiMap} from "react-icons/bi";
import {MdOutlineEmail, MdOutlinePhone} from "react-icons/md";
import {AiOutlineIdcard} from "react-icons/ai";
import EditableText from "../UI/EditableText";
import useAsync from "../../utilities/customHooks/async-call";
import {doPutClient} from "../../logics/registry";
import {useRegistry} from "../../utilities/contexts/registry-context";

const ClientPanel = ({client, ...props}) => {

    const {call} = useAsync();
    const {updateClient, setCurrentClient} = useRegistry();

    const handleEditClient = async (data) => {
        await call(
            () => doPutClient(client.idcl, data),
            (editedClient) => {
                updateClient(editedClient);
                setCurrentClient(editedClient);
            },
        );
    }

    return (
        <div>
            <div className="font-bold text-xl mb-2 border-b-2 border-gray-200 pb-1">
                <EditableText value={client && client.ragsoccl}
                              handleConfirm={(value) => handleEditClient({ragsoccl: value})}/>
            </div>
            <div className="mb-1 pb-1 border-b border-gray-200">
                <div className="flex flex-row items-center">
                    <MdOutlinePhone className='mr-2'/>
                    <EditableText value={client && client.contatto}
                                  handleConfirm={(value) => handleEditClient({contatto: value})}/>

                </div>
                <div className="flex flex-row items-center">
                    <MdOutlineEmail className='mr-2'/>
                    <EditableText value={client && client.email}
                                  handleConfirm={(value) => handleEditClient({email: value})}/>
                </div>
            </div>
            <div className="flex flex-row items-center">
                <BiMap className='mr-2'/>
                <EditableText value={client && client.indcl}
                              handleConfirm={(value) => handleEditClient({indcl: value})}/>
            </div>
            <div className="flex flex-row items-center">
                <AiOutlineIdcard className='mr-2'/>
                <EditableText value={client && client.pivacl}
                              handleConfirm={(value) => handleEditClient({pivacl: value})}/>
            </div>

            <div className="italic text-gray-400 text-xs text-center my-1">Campi modificabili con doppio click</div>
        </div>
    );
}

export default ClientPanel;
