import React from 'react';
import Select from "../../../../UI/Select";
import VerticalLabelledField from "../../../../UI/Fields/VerticalLabelledField";
import {useLot} from "../../../../../utilities/contexts/lot-context";

const ColorInput = ({value, setValue}) => {

    const {movingData} = useLot();

    return (
        <VerticalLabelledField label="Colore" className="mb-3">
            <Select value={value}
                    onChange={(e) => setValue(e.target.value)}
                    required={!value}>
                <option value="" disabled selected>Seleziona un colore</option>
                {movingData && movingData.colors && movingData.colors.length > 0 &&
                movingData.colors.map((col, i) => <option value={col.idcol} key={i}>{col.nomecol}</option>)
                }
            </Select>
        </VerticalLabelledField>
    );
};

export default ColorInput;