import React, {useState} from 'react';
import BaseInput from "./BaseInput";
import {AiFillCheckCircle, AiFillCloseCircle} from "react-icons/ai";
import IconBtn from "./IconBtn";

const EditableText = ({value, handleConfirm, placeHolder=". . .", ...props}) => {

    const [editing, setEditing] = useState(false);
    const [newValue, setNewValue] = useState(value);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm && handleConfirm(newValue);
        setEditing(false);
    }

    const handleUndo = () => {
        setNewValue(value);
        setEditing(false)
    }

    const handleEditing = () => {
        setEditing(true);

    }

    return (
        <>
            {editing
                ? <form className="flex-1 flex flex-row" onSubmit={handleSubmit}>
                    <BaseInput value={newValue} className="flex-1 mr-1" onChange={(e) => setNewValue(e.target.value)}/>
                    <IconBtn className="text-green-600" title={'Conferma'} type={'submit'}>
                        <AiFillCheckCircle className={'text-opacity-80'}/>
                    </IconBtn>
                    <IconBtn onClick={handleUndo} className="text-red-600" title={'Annulla'}>
                        <AiFillCloseCircle className={'text-opacity-80'}/>
                    </IconBtn>
                    </form>
                : <div onDoubleClick={handleEditing} title={'Doppio click per modificare'}>{value ? value : <span className="text-gray-200">{placeHolder}</span>}</div>
            }
        </>
    );
}

export default EditableText;