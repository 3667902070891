import API from "./API";

function doGetInHouseLeather(nrlotto, date, showCosts) {
    let params = new URLSearchParams();
    nrlotto && params.append('nrlotto', nrlotto);
    date && params.append('data', date);
    showCosts && params.append('costs', showCosts);
    return API.get('/queries/storedleathers/inhouse', params);
}

function doGetSubcontractorLeathers(nrlotto, date, showCosts, subcontractor) {
    let params = new URLSearchParams();
    nrlotto && params.append('nrlotto', nrlotto);
    date && params.append('data', date);
    showCosts && params.append('costs', showCosts);
    subcontractor && params.append('idterz', subcontractor);
    return API.get('/queries/storedleathers/subcontractors', params);
}

function doGetWeeklySales(date_from, date_to) {
    let params = new URLSearchParams();
    params.append('date_from', date_from);
    params.append('date_to', date_to);

    return API.get('/queries/soldleathers', params);
}

function doGetSalesByClient(client_id, date_from, date_to, nrlotto, totals, onApproval, displayCrusts) {
    let params = new URLSearchParams();
    client_id && params.append('idcl', client_id);
    date_from && params.append('date_from', date_from);
    date_to && params.append('date_to', date_to);
    nrlotto && params.append('nrlotto', nrlotto);
    totals && params.append('totals', "true");
    onApproval && params.append('onapproval', "true");
    displayCrusts && params.append('crusts', "true");
    return API.get('/queries/soldleathers/clients', params)
}

function doGetDocumentQuery(idterz, date_from, date_to) {
    let params = new URLSearchParams();
    date_from && params.append('date_from', date_from);
    date_to && params.append('date_to', date_to);
    idterz && params.append('idterz', idterz);
    return API.get('/queries/subcontractors/documents', params);
}

export {
    doGetInHouseLeather,
    doGetSubcontractorLeathers,
    doGetWeeklySales,
    doGetSalesByClient,
    doGetDocumentQuery,
}