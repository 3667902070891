import API from "./API";

async function doPostLogin(username, password) {

    let params = new URLSearchParams();
    params.append('user', username);
    params.append('password', password);

    return await API.post('/login', params);
}

export {
    doPostLogin,
}