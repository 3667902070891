import React, {useEffect, useState} from 'react';
import {useLot} from "../../../../../utilities/contexts/lot-context";
import useAsync from "../../../../../utilities/customHooks/async-call";
import usePrices from "../../../../../utilities/customHooks/prices";
import {doGetProcessingCost, doPostMoveMovement} from "../../../../../logics/movements";
import Button from "../../../../UI/Button";
import {doGetLotBalance, doGetLotMovements} from "../../../../../logics/lots";
import FatherMovementRecap from "../../FatherMovementRecap";
import AvailableLeathersInput from "../UI/AvailableLeathersInput";
import FormTitle from "../UI/FormTitle";
import DocumentInput from "../UI/DocumentInput";
import ModalTitleWithCloseButton from "../UI/ModalTitleWithCloseButton";
import ProcessingInput from "../UI/ProcessingInput";
import ArticleInput from "../UI/ArticleInput";
import ColorInput from "../UI/ColorInput";
import SubcontractorInput from "../UI/SubcontractorInput";
import PriceRow from "../UI/PriceRow";
import useToast from "../../../../../utilities/customHooks/toast";


const MoveForm = () => {

    const {currentMovement, openModal, modal, movingData, currentLot, loadMovements, loadBalance} = useLot();
    const {call} = useAsync();
    const {prices, setters, PriceRows, initializePrices, resetPrices} = usePrices();
    const {toastWarning} = useToast();

    const formRef = React.createRef();

    const [nrpz, setNrpz] = useState(currentMovement.available_leathers ?? 0);
    const [processing, setProcessing] = useState(movingData.selected.processing);
    const [subcontractor, setSubcontractor] = useState("");
    const [color, setColor] = useState("");
    const [article, setArticle] = useState("");

    const [subcontractorError, setSubcontractorError] = useState("");
    const [datadocmov, setDatadocmov] = useState(new Date().toISOString().substring(0,10));
    const [nrdocmov, setNrdocmov] = useState(null);
    const [tipodocmov, setTipodocmov] = useState("");

    const loadCosts = async (idlav, idterz) => {
        await call(
            () => doGetProcessingCost(idlav, idterz),
            (res) => {
                if (res.costs) {
                    initializePrices(res.costs);
                } else
                    resetPrices();
            },
            (err) => {
                resetPrices();
            }
        );
    }

    const handleProcessingChange = async (processing) => {
        let idterz = currentMovement.subcontractor.id;
        setProcessing(processing);

        if (processing) {
            await loadCosts(processing, idterz);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(currentMovement);

        if (!subcontractor) {
            setSubcontractorError("Campo obbligatorio");
            return;
        }

        if(nrpz < 1 || nrpz > currentMovement.available_leathers) {
            toastWarning("Numero pelli da movimentare incorretto");
            return;
        }


        let fields = {
            nrpz,
            article,
            processing,
            subcontractor,
            color,
        }

        if (prices.prezzo1) fields['prezzo1'] = prices.prezzo1;
        if (prices.um_1?.id) fields['idum1'] = prices.um_1.id;
        if (prices.qta_1) fields['qta1'] = prices.qta_1;
        if (prices.prezzo2) fields['prezzo2'] = prices.prezzo2;
        if (prices.um_2?.id) fields['idum2'] = prices.um_2.id;
        if (prices.qta_2) fields['qta2'] = prices.qta_2;
        if (prices.prezzo3) fields['prezzo3'] = prices.prezzo3;
        if (prices.um_3?.id) fields['idum3'] = prices.um_3.id;
        if (prices.qta_3) fields['qta3'] = prices.qta_3;
        if (tipodocmov) fields['tipodocmov'] = tipodocmov;
        if (datadocmov) fields['datadocmov'] = datadocmov;
        if (nrdocmov) fields['nrdocmov'] = nrdocmov;

        //TODO create new
        await call(
            () => doPostMoveMovement(currentLot.nrlotto, currentMovement.idnodo, fields),
            async (res) => {
                console.log(res);
                await call(
                    () => doGetLotMovements(currentLot.nrlotto),
                    async (res) => {
                        if (res) {
                            console.log("loadingMovements")
                            loadMovements(res[0]);
                            openModal('add', false);
                            await call(
                                () => doGetLotBalance(currentLot.nrlotto),
                                (res) => {
                                    loadBalance(res)
                                }
                            );
                        } else {
                            loadMovements(null);
                        }
                    },
                    (err) => {
                        console.warn(err);
                        loadMovements(null);
                    }
                );
            }
        )
        //TODO reload tree
    }

    useEffect(() => {
        const load = async () => {
            if (processing) {
                let idterz = currentMovement.subcontractor.id;
                await loadCosts(processing, idterz);
            }
        }

        load();
    }, [modal.add]);

    return (

        <div>
            <ModalTitleWithCloseButton title="Inserimento nuovo movimento"
                                       modal="add"/>

            <FatherMovementRecap/>

            <form onSubmit={handleSubmit} ref={formRef}>
                <FormTitle>Inserimento dati documento e costi</FormTitle>

                <AvailableLeathersInput available={currentMovement?.available_leathers}
                                        value={nrpz}
                                        setValue={setNrpz}/>

                <ProcessingInput value={processing}
                                 onChange={handleProcessingChange}/>

                <ArticleInput value={article}
                              setValue={setArticle}/>

                <ColorInput value={color}
                            setValue={setColor}/>

                <div className="mb-2">
                    <FormTitle>Nr. Documento di arrivo</FormTitle>
                    <DocumentInput number={nrdocmov}
                                   setNumber={setNrdocmov}
                                   date={datadocmov}
                                   type={tipodocmov}
                                   setDate={setDatadocmov}
                                   setType={setTipodocmov}/>
                </div>

                {(prices.prezzo1 || prices.prezzo2 || prices.prezzo3) &&
                <div className="mb-2">
                    <div className="text-md uppercase font-medium text-gray-500 leading-none">Costi movimento</div>
                    <PriceRow price={prices.prezzo1}
                              um={prices.um_1?.name}
                              qta={prices.qta_1}
                              setPrice={setters.setPrezzo1}
                              setQta={setters.setQta_1}/>
                    <PriceRow price={prices.prezzo2}
                              um={prices.um_2?.name}
                              qta={prices.qta_2}
                              setPrice={setters.setPrezzo2}
                              setQta={setters.setQta_2}/>
                    <PriceRow price={prices.prezzo3}
                              um={prices.um_3?.name}
                              qta={prices.qta_3}
                              setPrice={setters.setPrezzo3}
                              setQta={setters.setQta_3}/>
                </div>
                }

                <FormTitle>Invio pelli a</FormTitle>

                <SubcontractorInput value={subcontractor}
                                    setValue={setSubcontractor}/>

                <div className="mt-4 flex flex-row justify-end">
                    <Button type="submit" className="text-lg rounded font-medium">Conferma</Button>
                </div>
            </form>
        </div>
    );
};

export default MoveForm;
