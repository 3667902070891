
function getMissingLeathers(movement) {
    let childPz = 0;
    if(movement && movement.children) {
        if(movement.children.length > 0)
            movement.children.forEach((child) => {
                if(child && child.nrpz)
                childPz += parseInt(child.nrpz)
            });
        return parseInt(movement.nrpz) - childPz;
    } else {
        return 0;
    }
}

function canContinue(movement) {
    return !movement.chiuso && getMissingLeathers(movement) > 0;
}

export {
    getMissingLeathers,
    canContinue,
}