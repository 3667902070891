import React, {useState} from 'react';
import Layout from "../components/UI/Layout";
import BaseInput from "../components/UI/BaseInput";
import {AiOutlineReload} from "react-icons/ai";
import useFilter from "../utilities/customHooks/filter";

const Warehouses = () => {

    const filterByName = () => {

    }

    const {filterText, setFilterText, filteredData} = useFilter([], filterByName);

    const reload = () => {

    }

    return (
        <Layout title="Magazzini">
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
            <div className="mb-4">
                <BaseInput placeholder={'Cerca magazzino'}
                           className={'w-full mb-1'}
                           value={filterText}
                           onChange={(e) => setFilterText(e.target.value)}/>
                <div className="text-sm pl-1 flex flex-row gap-1 items-center">
                    <span>Magazzini elencati:</span>
                    <span>{filteredData.length}</span>
                    <AiOutlineReload onClick={reload} className="hover:text-gray-200 cursor-pointer"
                                     title="Ricarica magazzini"/>
                </div>
            </div>
                <div className="h-full overflow-y-auto ">

                </div>
            </div>
        </Layout>
    );
};

export default Warehouses;