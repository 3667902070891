import React from 'react';
import {useRegistry} from "../../utilities/contexts/registry-context";
import useAsync from "../../utilities/customHooks/async-call";
import {doPutSubcontractor} from "../../logics/registry";
import EditableText from "../UI/EditableText";
import {MdNotes, MdOutlineEmail, MdOutlinePhone} from "react-icons/md";
import {BiMap} from "react-icons/bi";
import {AiOutlineIdcard} from "react-icons/ai";
import {BsCurrencyExchange} from "react-icons/bs";

const SubcontractorPanel = ({subcontractor, ...props}) => {

    const {updateSubcontractor, setCurrentSubcontractor} = useRegistry();
    const {call} = useAsync();

    const handleEditSupplier = async (data) => {
        await call(
            () => doPutSubcontractor(subcontractor.idterz, data),
            (editedClient) => {
                updateSubcontractor(editedClient);
                setCurrentSubcontractor(editedClient);
            },
        );
    }

    return (
        <div>
            <div className="font-bold text-xl mb-2 border-b-2 border-gray-200 pb-1">
                <EditableText value={subcontractor && subcontractor.ragsoctz}
                              handleConfirm={(value) => handleEditSupplier({ragsoctz: value})}/>
            </div>

            <div className="mb-1 pb-1 border-b border-gray-200">
                <div className="flex flex-row items-center">
                    <MdOutlinePhone className='mr-2'
                                    title="Contatto"/>
                    <EditableText value={subcontractor && subcontractor.contatto}
                                  handleConfirm={(value) => handleEditSupplier({contatto: value})}/>

                </div>
                <div className="flex flex-row items-center">
                    <MdOutlineEmail className='mr-2'
                                    title="Email"/>
                    <EditableText value={subcontractor && subcontractor.email}
                                  handleConfirm={(value) => handleEditSupplier({email: value})}/>
                </div>
            </div>

            <div className="flex flex-row items-center">
                <BiMap className='mr-2'
                       title="Indirizzo"/>
                <EditableText value={subcontractor && subcontractor.indtz}
                              handleConfirm={(value) => handleEditSupplier({indtz: value})}/>
            </div>
            <div className="flex flex-row items-center">
                <AiOutlineIdcard className='mr-2'
                                 title="PIVA"/>
                <EditableText value={subcontractor && subcontractor.pivatz}
                              handleConfirm={(value) => handleEditSupplier({pivatz: value})}/>
            </div>

            <div className="flex flex-row items-center">
                <MdNotes className='mr-2'
                         title="Note"/>
                <EditableText value={subcontractor && subcontractor.note}
                              handleConfirm={(value) => handleEditSupplier({note: value})}/>
            </div>
            <div className="flex flex-row items-center">
                <BsCurrencyExchange className='mr-2'
                                    title="Valuta (EUR/USD)"/>
                <EditableText value={subcontractor && subcontractor.valuta}
                              handleConfirm={(value) => handleEditSupplier({valuta: value})}/>
            </div>

            <div className="italic text-gray-400 text-xs text-center my-1">Campi modificabili con doppio click</div>
        </div>
    )
}

export default SubcontractorPanel;
