import React from 'react';

const TableRow = ({className, ...props}) => {

    const {onClick} = props;

    const handleClick = () => {
        onClick && onClick()
    }

    return (
        <tr onClick={handleClick} className={`${className} p-1 rounded shadow hover:bg-gray-100 cursor-pointer`}>
            {props.children}
        </tr>
    );
}

export default TableRow;