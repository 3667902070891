import React from 'react';

const VerticalLabelledField = ({label, className, ...props}) => {
    return (
        <div className={className} {...props}>
            <div className="uppercase font-medium text-gray-500 leading-none mb-0.5"
                 style={{fontSize: "10px"}}>{label}</div>
            {props.children}
        </div>
    );
};

export default VerticalLabelledField;
