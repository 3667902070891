import React from 'react';
import lotReducer, {defaultState} from "../reducers/lot-reducer";
import actions from "../actions/lot";

const LotContext = React.createContext();

function useLotContextValue() {
    const [state, dispatch] = React.useReducer(lotReducer, defaultState);

    const {lots, currentLot, lotHeader, currentMovement, movements, modal, openDetails, pagination, lotRows, balance, movingData} = state;

    const loadLots = React.useCallback((lots) => dispatch(actions.loadLots(lots)), []);
    const loadPagination = React.useCallback((pagination) => dispatch(actions.loadPagination(pagination)), []);
    const setCurrentLot = React.useCallback((lot) => dispatch(actions.setCurrentLot(lot)), []);
    const setCurrentMovement = React.useCallback((movement) => dispatch(actions.setCurrentMovement(movement)), []);
    const openModal = React.useCallback((modal, status) => dispatch(actions.openModal(modal, status)), []);
    const loadMovements = React.useCallback((movements) => dispatch(actions.loadMovements(movements)), []);
    const setOpenDetails = React.useCallback((status) => dispatch(actions.openDetails(status)), []);
    const loadLotHeader = React.useCallback((lotHeader) => dispatch(actions.loadHeader(lotHeader)), []);
    const loadRows = React.useCallback((rows) => dispatch(actions.loadRows(rows)), []);
    const loadBalance = React.useCallback((balance) => dispatch(actions.loadBalance(balance)), []);
    const updateMovement = React.useCallback((movement) => dispatch(actions.updateMovement(movement)), []);
    const loadMovingData = React.useCallback((data) => dispatch(actions.loadMovingData(data)), []);

    return React.useMemo(() => {
        return {
            state,
            lots, loadLots,
            pagination, loadPagination,
            currentLot, setCurrentLot,
            currentMovement, setCurrentMovement,
            modal, openModal,
            movements, loadMovements,
            openDetails, setOpenDetails,
            lotHeader, loadLotHeader,
            lotRows, loadRows,
            balance, loadBalance,
            updateMovement,
            movingData, loadMovingData,
        }
    }, [
        state,
        lots, loadLots,
        pagination, loadPagination,
        currentLot, setCurrentLot,
        currentMovement, setCurrentMovement,
        modal, openModal,
        movements, loadMovements,
        openDetails, setOpenDetails,
        lotHeader, loadLotHeader,
        lotRows, loadRows,
        balance, loadBalance,
        updateMovement,
        movingData, loadMovingData,
    ]);
}

function LotProvider(props) {
    const value = useLotContextValue();
    return <LotContext.Provider value={value} {...props} />
}

function useLot() {
    const context = React.useContext(LotContext);
    if (!context) {
        throw new Error("useLot must be used within a LotProvider");
    }
    return context;
}

export {
    LotProvider,
    useLot,
}