import React from 'react';
import useAsync from "../../utilities/customHooks/async-call";
import {doGetProcessing} from "../../logics/registry";
import TableRow from "../UI/table/TableRow";
import {FaTrashAlt} from "react-icons/fa";

const ProcessRow = ({process, ...props}) => {
    const {call} = useAsync();

    const handleClick = async () => {
        await call(
            () => doGetProcessing(process.idlav),
            (res) => {
                console.log(res);
            }
        )
    }

    return (
        <TableRow onClick={handleClick} className='text-sm mb-0.5'>
            <td className="pl-2 ">
                {process.nomelav}
            </td>
            <td>
                <div className="w-full flex flex-row items-center justify-center gap-2">
                    <FaTrashAlt/>
                </div>
            </td>
        </TableRow>
    )
};

export default ProcessRow;