import React from 'react';
import useAuth from "../../utilities/customHooks/auth";
import SideMenu from "./SideMenu";
import {useApp} from "../../utilities/contexts/app-context";
import TopDateTime from "./TopDateTime";

const Layout = ({title, className, ...props}) => {

    const {userName} = useApp();
    const {authed} = useAuth();

    return (
        <div className="flex flex-row">
            <SideMenu />
            <div className="flex flex-col h-screen flex-1">
                <div className="sticky top-0 bg-secondary text-white w-full flex flex-row items-start justify-between p-1 pl-6">
                    <div>
                        <div className="text-sm">
                            {!authed
                                ? <div />
                                : <div>UTENTE: <span className="uppercase">{userName ?? "utente"}</span></div>
                            }
                        </div>
                        <h1 className="font-bold uppercase text-lg">{title ? title : "Impostare titolo pagina"}</h1>
                    </div>
                    <TopDateTime />
                </div>

                <div className={`${className ? className : ""} w-full flex-1 overflow-hidden`} style={{minHeight: "30px"}}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Layout;
