import React from 'react';
import {FaPrint} from "react-icons/fa";
import RowAligned from "./Structure/RowAligned";

const PrintButton = ({title="Stampa", ...props}) => {
    return (
        <RowAligned className="cursor-pointer bg-blue-100 hover:bg-blue-300 p-1 rounded gap-1" {...props}>
            <div className="w-6 h-6 bg-blue-100 flex flex-col items-center justify-center rounded-full  border border-black">
                <FaPrint />
            </div>
            <div>{title}</div>
        </RowAligned>
    );
};

export default PrintButton;