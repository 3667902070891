import types from "../types/types";

function updateNode(node) {
    return {
        node,
        type: types.TREE_UPDATE_NODE,
    }
}

function loadTree(nodes) {
    return {
        nodes,
        type: types.TREE_LOAD_TREE,
    }
}

function addNode(original, new_node) {
    return {
        original,
        new_node,
        type: types.TREE_ADD_NODE,
    }
}

function removeNode(idnodo) {
    return {
        idnodo,
        type: types.TREE_REMOVE_NODE,
    }
}
function setNodeOpen(id, open){
    return {
        id,
        open,
        type: types.TREE_SET_NODE_OPEN,
    }
}

const actions = {
    updateNode,
    loadTree,
    addNode,
    removeNode,
    setNodeOpen,
}

export default actions;
