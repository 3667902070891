import React, {useEffect} from 'react';
import {useRegistry} from "../../../utilities/contexts/registry-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {doGetChoices} from "../../../logics/registry";

const SelectChoice = ({onChange, value, ...props}) => {
    const {choices, loadChoices} = useRegistry();
    const {call} = useAsync();

    const handleChange = (e) => {
        onChange && onChange(e.target.value);
    }

    useEffect(() => {
        const load = async () => {
            await call(
                () => doGetChoices(),
                (res) => {
                    res && res.length > 0 && loadChoices(res);
                }
            )
        }

        if(!choices || choices.length === 0)
            load();
    }, []);
    return (
        <select onChange={handleChange} value={value} {...props}>
            <option value="" disabled={true} selected={true}>Seleziona scelta</option>
            {choices && choices.length > 0 &&
            choices.map((unit, i) => <option key={i} value={unit.idscelta}>{unit.nomescelta}</option>)
            }
        </select>
    );
};

export default SelectChoice;