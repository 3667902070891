import React from 'react';
import {useQueries} from "../../../utilities/contexts/queries-context";
import {formatInt, formatPrice, parseDate} from "../../../utilities/data/parser";
import Table from "../General/Printable/Table";
import TableHead from "../General/Printable/TableHead";
import Th from "../General/Printable/Th";
import Td from "../General/Printable/Td";
import DocumentInfo from "../../UI/DataDisplay/DocumentInfo";

const SubcontractorPrintable = ({subcontractor, displayCosts}) => {

    const {subcontractorRows, subcontractorTotals} = useQueries();

    return (
        <div>
            <div className="flex flex-col items-center">
                <div>{parseDate(new Date().toISOString())}</div>
                <div>{subcontractor ? `Terzista: ${subcontractor?.name}` : "Tutti i terzisti"}</div>
            </div>

            <Table>
                <TableHead>
                    <Th>Terzista</Th>
                    <Th>Lotto</Th>
                    <Th>Documento e Data</Th>
                    <Th>Articolo - Colore</Th>
                    <Th>Nr. pelli ferme</Th>
                    {displayCosts &&
                    <>
                        <Th>Costo al pezzo</Th>
                        <Th>Valore</Th>
                    </>
                    }
                </TableHead>
                <tbody>
                    {subcontractorRows && subcontractorRows.length > 0 &&
                    subcontractorRows.map((row,i) =>
                        <tr className="border border-black" key={i}>
                            <Td>{row?.subcontractor?.name}</Td>
                            <Td>{row.nrlotto}</Td>
                            <Td className="pl-1"><DocumentInfo document={row.document}/></Td>
                            <Td className="pl-1">{row.article.name} - {row.color.name}</Td>
                            <Td className="text-right">{row.available_leathers}</Td>
                            {displayCosts &&
                            <>
                                <Td className="text-right">{formatPrice(row.costs.per_leather)}€</Td>
                                <Td className="text-right">{formatPrice(row.costs.progressive)}€</Td>
                            </>
                            }
                        </tr>
                    )
                    }
                    {subcontractorTotals &&
                    <tr className="border border-black border-t-2 text-right">
                        <Td colSpan={4}>Totale:</Td>
                        <Td>{formatInt(subcontractorTotals.nrpz)}</Td>
                        {displayCosts &&
                        <>
                            <Td/>
                            <Td>{formatPrice(subcontractorTotals.costs)}€</Td>
                        </>
                        }
                    </tr>
                    }
                </tbody>
            </Table>
        </div>
    );
};

export default SubcontractorPrintable;