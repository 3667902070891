import React from 'react';
import Select from "../../../../UI/Select";
import VerticalLabelledField from "../../../../UI/Fields/VerticalLabelledField";
import {useLot} from "../../../../../utilities/contexts/lot-context";

const ArticleInput = ({value, setValue}) => {

    const {movingData} = useLot();

    return (
        <VerticalLabelledField label="Articolo" className="mb-3">
            <Select value={value}
                    onChange={(e) => setValue(e.target.value)}
                    required={!value}>
                <option value="" disabled selected>Seleziona un articolo</option>
                {movingData && movingData.articles && movingData.articles.length > 0 &&
                movingData.articles.map((art, i) => <option value={art.idart}
                                                            key={i}>{art.nomeart}</option>)
                }
            </Select>
        </VerticalLabelledField>
    );
};

export default ArticleInput;