import types from "../types/types";

export const defaultState = {
    authed: false,
    toastStatus: true,
    toastContent: null,
    menuOpen: false,
    userName: "",
}

function AppReducer(state, action) {
    switch (action.type) {
        case types.APP_SET_AUTHED_STATUS:
            return {
                ...state,
                authed: action.status,
            }
        case types.APP_SET_TOAST_CONTENT:
            return {
                ...state,
                toastContent: action.content,
            }
        case types.APP_SET_TOAST_STATUS:
            return {
                ...state,
                toastStatus: action.status,
            }
        case types.APP_SET_MENU_OPEN:
            return {
                ...state,
                menuOpen: action.status,
            }
        case types.APP_SET_USER_NAME:
            return {
                ...state,
                userName: action.name,
            }
        default:
            throw new Error("Unhandled action type");
    }

}

export default AppReducer;