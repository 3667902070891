import types from "../types/types";

function setAuthed(status) {
    return {
        type: types.APP_SET_AUTHED_STATUS,
        status,
    }
}

function setToastContent(content) {
    return {
        type: types.APP_SET_TOAST_CONTENT,
        content,
    }
}

function setToastStatus(status) {
    return {
        type: types.APP_SET_TOAST_STATUS,
        status,
    }
}

function setMenuOpen(status) {
    return {
        type: types.APP_SET_MENU_OPEN,
        status,
    }
}

function setUserName(name) {
    return {
        type: types.APP_SET_USER_NAME,
        name,
    }
}

const actions = {
    setAuthed,
    setToastContent,
    setToastStatus,
    setMenuOpen,
    setUserName,
}

export default actions;
