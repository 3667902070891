import React from 'react';

const Td = ({className, ...props}) => {
    return (
        <td className={`border border-black ${className}`} {...props}>
            {props.children}
        </td>
    );
};

export default Td;