import types from "../types/types";
import {get7daysAgo} from "../data/tools";

export const defaultState = {
    inHouseRows: [],
    inHouseTotals: null,
    inHouseSelected: [],

    subcontractorRows: [],
    subcontractorTotals: null,

    weeklyRows: [],
    weeklyTotals: null,
    weeklyDates: {
        start: get7daysAgo(),
        end: new Date().toISOString().split('T')[0],
    },

    salesByClient: [],
    salesByClientTotals: null,
    byClientDates: {
        start: get7daysAgo(),
        end: new Date().toISOString().split('T')[0],
    },

    subcontractorDocumentsRow: [],
    subcontractorDocumentsForm: {
        start: get7daysAgo(),
        end: new Date().toISOString().split('T')[0],
        subcontractor: null,
    }
}

function queriesReducer(state, action) {
    switch (action.type) {
        case types.QUERIES_IN_HOUSE_LOAD_ROWS: {
            return {
                ...state,
                inHouseRows: action.rows,
                inHouseSelected: [],
            }
        }
        case types.QUERIES_IN_HOUSE_LOAD_TOTALS: {
            return {
                ...state,
                inHouseTotals: action.totals,
            }
        }
        case types.QUERIES_IN_HOUSE_TOGGLE_SELECTED: {
            return {
                ...state,
                inHouseSelected: [...getUpdatedSelected(state.inHouseSelected, action.row)]
            }
        }

        case types.QUERIES_SUBCONTRACTOR_LOAD_ROWS: {
            return {
                ...state,
                subcontractorRows: action.rows,
                subcontractorSelected: [],
            }
        }
        case types.QUERIES_SUBCONTRACTOR_LOAD_TOTALS: {
            return {
                ...state,
                subcontractorTotals: action.totals,
            }
        }
        case types.QUERIES_IN_HOUSE_TOGGLE_ALL: {

            if(state.inHouseSelected.length === state.inHouseRows.length) {
                return {
                    ...state,
                    inHouseSelected: []
                }
            } else {
                return {
                    ...state,
                    inHouseSelected: [...state.inHouseRows]
                }
            }
        }

        case types.QUERIES_WEEKLY_LOAD_ROWS: {
            return {
                ...state,
                weeklyRows: [...action.rows],
            }
        }
        case types.QUERIES_WEEKLY_LOAD_TOTALS: {
            return {
                ...state,
                weeklyTotals: action.totals,
            }
        }
        case types.QUERIES_WEEKLY_SET_DATES: {
            return {
                ...state,
                weeklyDates: {
                    start: action.start,
                    end: action.end,
                }
            }
        }

        case types.QUERIES_LOAD_SALES_BY_CLIENT: {
            return {
                ...state,
                salesByClient: action.sales,
            }
        }

        case types.QUERIES_SET_BY_CLIENT_DATES: {
            return {
                ...state,
                byClientDates: {
                    start: action.start,
                    end: action.end,
                }
            }
        }

        case types.QUERIES_SET_BY_CLIENT_TOTALS: {
            return {
                ...state,
                salesByClientTotals: action.totals,
            }
        }

        case types.QUERIES_SUBCONTRACTOR_DOCUMENTS_LOAD_ROWS: {
            return {
                ...state,
                subcontractorDocumentsRow: action.rows,
            }
        }

        case types.QUERIES_SUBCONTRACTOR_DOCUMENTS_SET_FORM: {
            return {
                ...state,
                subcontractorDocumentsForm: {
                    start: action.start,
                    end: action.end,
                    subcontractor: action.subcontractor
                }
            }
        }


        default:
            throw new Error("Unhandled action type: " + action.type);
    }
}

function getUpdatedSelected(list, row) {
    if (list.some(r => r.id === row.id)) {
        //remove from selection
        let filtered = list.filter(r => r.id !== row.id);
        return [...filtered];
    } else {
        //add to selection
        return [...list, row];
    }
}

export default queriesReducer;
