import React from 'react';

const Table = ({...props}) => {
    return (
        <table className="w-full text-sm" {...props}>
            {props.children}
        </table>
    );
};

export default Table;