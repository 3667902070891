import React from 'react';
import ModalTitleWithCloseButton from "../Creation/UI/ModalTitleWithCloseButton";
import {useLot} from "../../../../utilities/contexts/lot-context";
import HorizontalLabelledField from "../../../UI/Fields/HorizontalLabelledField";
import {parseDate} from "../../../../utilities/data/parser";
import RowAligned from "../../../UI/Structure/RowAligned";

const TableRow = ({label, ...props}) => {
    return (
        <tr className="border-2 leading-none">
            <td className="px-1 border-2">{label}:</td>
            <td className="px-1 border-2">{props.children}</td>
        </tr>
    );
}

const TagView = () => {

    const {currentMovement, currentLot} = useLot();

    console.log(currentMovement);

    return (
        <div>
            <ModalTitleWithCloseButton title="Scheda"
                                       modal="tag"/>

            <div>

                <table>
                    <tbody>
                    <tr>
                        <td colSpan="2">
                            <RowAligned className="justify-between">
                                <div className="font-bold text-lg">ZETAPELLI S.R.L.</div>
                                <div>MAG-{currentMovement.idnodo}</div>
                            </RowAligned>
                        </td>
                    </tr>
                    <TableRow label="Data">{parseDate(currentMovement?.document?.date?.date)}</TableRow>
                    <TableRow label="Lotto">{currentLot.nrlotto}</TableRow>
                    <TableRow label="Rifinizione">{currentMovement?.subcontractor?.name ?? ""}</TableRow>
                    <TableRow
                        label="DDT">{currentMovement?.document?.type?.name} {currentMovement?.document?.nr} del {parseDate(currentMovement?.document?.date?.date)}</TableRow>
                    <TableRow label="Articolo">{currentMovement?.article?.name ?? ""}</TableRow>
                    <TableRow label="Colore">{currentMovement?.color?.name ?? ""}</TableRow>
                    <TableRow label="Pezzi">{currentMovement.nrpz}</TableRow>
                    <TableRow label="Pelli ferme">{currentMovement.available_leathers}</TableRow>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TagView;