import React from 'react';
import {Link, NavLink} from 'react-router-dom';

const CustomLink = ({to, ...props}) => {

    const activeLinkStyle = "bg-blue-400 font-bold text-white";

    return (
        <NavLink to={to} className={({ isActive }) =>
            `${isActive ? activeLinkStyle : "bg-blue-200 hover:bg-blue-300"} cursor-pointer text-xs rounded-t-lg`
        }>
            <div className="p-1">
                {props.children}
            </div>
        </NavLink>
    );
}


const Navigation = () => {

    return (
        <div className="flex flex-row items-center gap-1 mt-2 px-2">
            <CustomLink to="in_sede">Pelli in sede</CustomLink>
            <CustomLink to="terzisti">Pelli dai terzisti</CustomLink>
            {/*<CustomLink to="riepilogo">Riepilogo lotto</CustomLink>*/}
            <CustomLink to="vendite_settimanali">Vendite per intervallo date</CustomLink>
            <CustomLink to="vendite_cliente">Vendite per cliente</CustomLink>
            <CustomLink to="documenti_terzista">Documenti terzista</CustomLink>
        </div>
    );
};

export default Navigation;
