import React from 'react';
import AppReducer, {defaultState} from "../reducers/app-reducer";
import actions from "../actions/app";

const AppContext = React.createContext();

function useAppContextValue() {
    const [state, dispatch] = React.useReducer(AppReducer, defaultState);

    const {authed, toastContent, toastStatus, menuOpen, userName} = state;

    const setAuthed = React.useCallback((status) => dispatch(actions.setAuthed(status)), []);
    const setToastContent = React.useCallback((content) => dispatch(actions.setToastContent(content)), []);
    const setToastStatus = React.useCallback((status) => dispatch(actions.setToastStatus(status)), []);
    const setMenuOpen = React.useCallback((status) => dispatch(actions.setMenuOpen(status)), []);
    const setUserName = React.useCallback((name) => dispatch(actions.setUserName(name)), []);

    return React.useMemo(() => {
        return {
            state,
            authed, setAuthed,
            toastContent, setToastContent,
            toastStatus, setToastStatus,
            menuOpen, setMenuOpen,
            userName, setUserName,
        }
    }, [
        state,
        authed, setAuthed,
        toastContent, toastStatus, setToastContent, setToastStatus,
        menuOpen, setMenuOpen,
        userName, setUserName,
    ]);
}

function AppProvider(props) {
    const value = useAppContextValue();
    return <AppContext.Provider value={value} {...props} />;
}

function useApp() {
    const context = React.useContext(AppContext);
    if (!context) {
        throw new Error("useApp must be used within an AppProvider");
    }
    return context;
}

export {
    useApp,
    AppProvider,
}