import React from 'react';
import ProcessRow from "./ProcessRow";
import {BiPlus} from "react-icons/bi";

const ProcessesTable = ({processes, ...props}) => {
    const handleCreate = () => {

    }

    return (
        <table className="w-full text-left">
            <thead>
            <tr className="border-b border-gray-600">
                <th className="pl-2 ">Nome</th>
                <th>
                    <span className="flex flex-row justify-center items-center w-full">
                        <BiPlus onClick={handleCreate}
                                title={'Nuovo articolo'}
                                className="cursor-pointer hover:text-green-400"/>
                    </span>
                </th>
            </tr>
            </thead>
            <tbody className=" w-full ">
            {processes && processes.length > 0 &&
            processes.map((process, i) =>
                <ProcessRow process={process} key={i} />
            )
            }
            </tbody>
        </table>
    );
};

export default ProcessesTable;