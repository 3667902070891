import types from "../types/types";

function loadLots(lots) {
    return {
        lots,
        type: types.LOT_LOAD_LOTS,
    }
}

function setCurrentLot(lot) {
    return {
        lot,
        type: types.LOT_SET_CURRENT_LOT,
    }
}

function setCurrentMovement(movement) {
    return {
        movement,
        type: types.LOT_SET_CURRENT_MOVEMENT,
    }
}

function openModal(modal, status) {
    return {
        modal,
        status,
        type: types.LOT_OPEN_MODAL,
    }
}

function loadMovements(movements) {
    return {
        movements,
        type: types.LOT_LOAD_CURRENT_MOVEMENTS,
    }
}

function openDetails(status) {
    return {
        status,
        type: types.LOT_OPEN_DETAILS,
    }
}

function loadPagination(pagination) {
    return {
        pagination,
        type: types.LOT_LOAD_PAGINATION,
    }
}

function loadHeader(lotHeader) {
    return {
        lotHeader,
        type: types.LOT_LOAD_HEADER,
    }
}

function loadRows(rows) {
    return {
        rows,
        type: types.LOT_LOAD_ROWS,
    }
}

function loadBalance(balance) {
    return {
        balance,
        type: types.LOT_LOAD_BALANCE,
    }
}

function updateMovement(movement) {
    return {
        movement,
        type: types.LOT_UPDATE_MOVEMENT,
    }
}

function loadMovingData(data) {
    return{
        data,
        type: types.LOT_LOAD_MOVING_DATA,
    }
}

const actions = {
    loadLots,
    loadPagination,
    setCurrentLot,
    setCurrentMovement,
    openModal,
    loadMovements,
    openDetails,
    loadHeader,
    loadRows,
    loadBalance,
    updateMovement,
    loadMovingData,
}

export default actions;
