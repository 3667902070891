import React from 'react';
import './App.css';
import {Routes, Route, BrowserRouter as Router, Navigate} from 'react-router-dom';
import {TreeProvider} from "./utilities/contexts/tree-context";
import {LotProvider} from "./utilities/contexts/lot-context";
import Login from "./screens/Login";
import RequireAuth from "./components/wrappers/RequireAuth";
import Clients from "./screens/Clients";
import Subcontractors from "./screens/Subcontractors";
import ToastContainer from "./components/UI/ToastContainer";
import Suppliers from "./screens/Suppliers";
import Lots from "./screens/Lots";
import LotHeader from "./components/Lots/LotHeader/LotHeader";
import LotsList from "./components/Lots/LotsList";
import NoMatch from "./components/UI/NoMatch";
import Lot from "./components/Lots/Lot";
import LotMovementPage from "./components/Lots/Movements/LotMovementPage";
import Warehouses from "./screens/Warehouses";
import Processings from "./screens/Processings";
import Articles from "./screens/Articles";
import Colors from "./screens/Colors";
import Queries from "./screens/Queries";
import InHouseLeathers from "./components/Queries/InHouseLeathers/InHouseLeathers";
import SubcontractorsLeathers from "./components/Queries/SubcontractorsLeathers/SubcontractorsLeathers";
import WeeklySales from "./components/Queries/WeeklySales/WeeklySales";
import SoldLeathers from "./components/Queries/SoldLeathers/SoldLeathers";
import SalesByClient from "./components/Queries/SalesByClient/SalesByClient";
import SubcontractorDocuments from "./components/Queries/SubcontractorDocuments/SubcontractorDocuments";
import {QueriesProvider} from "./utilities/contexts/queries-context";

function App() {

    return (
        <div>
            <LotProvider>
                <TreeProvider>
                    <Router>
                        <Routes>
                            <Route index element={ <Login />} />
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/clienti" element={
                                <RequireAuth>
                                    <Clients/>
                                </RequireAuth>
                            }/>
                            <Route path="/terzisti" element={
                                <RequireAuth>
                                    <Subcontractors/>
                                </RequireAuth>
                            }/>
                            <Route path="/fornitori" element={
                                <RequireAuth>
                                    <Suppliers/>
                                </RequireAuth>
                            }/>

                            <Route path="/lotti" element={
                                <RequireAuth>
                                    <Lots/>
                                </RequireAuth>
                            }>
                                <Route index element={<Navigate to="lista" />} />
                                <Route path=":nr" element={<Lot />} >
                                    <Route path="" element={<LotHeader />} />
                                    <Route path="movimenti" element={<LotMovementPage />} />
                                </Route>
                                <Route path="lista" element={<LotsList />} />
                                <Route path="*" element={<NoMatch />} />
                            </Route>

                            <Route path="/magazzini" element={
                                <RequireAuth>
                                    <Warehouses />
                                </RequireAuth>
                            }/>
                            <Route path="/lavorazioni" element={
                                <RequireAuth>
                                    <Processings />
                                </RequireAuth>
                            }/>
                            <Route path="/articoli" element={
                                <RequireAuth>
                                    <Articles />
                                </RequireAuth>
                            }/>
                            <Route path="/colori" element={
                                <RequireAuth>
                                    <Colors />
                                </RequireAuth>
                            }/>

                            <Route path="/interrogazioni" element={
                                <RequireAuth>
                                    <QueriesProvider>
                                        <Queries />
                                    </QueriesProvider>
                                </RequireAuth>
                            }>
                                <Route index element={<Navigate to="in_sede" />}/>
                                <Route path="in_sede" element={<InHouseLeathers />} />
                                <Route path="terzisti" element={<SubcontractorsLeathers />} />
                                <Route path="vendite_settimanali" element={<WeeklySales />} />
                                <Route path="vendite_cliente" element={<SalesByClient />} />
                                <Route path="documenti_terzista" element={<SubcontractorDocuments />} />
                                <Route path="*" element={<NoMatch />} />
                            </Route>
                        </Routes>
                    </Router>
                </TreeProvider>
            </LotProvider>

            <ToastContainer/>
        </div>
    );
}

export default App;
