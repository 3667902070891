import React from 'react';
import {useLot} from "../../../utilities/contexts/lot-context";
import {formatInt, formatPrice, parseFloat5, priceFormat} from "../../../utilities/data/parser";
import Section from "../../UI/Structure/Section";
import HeaderSection from "../LotHeader/UI/HeaderSection";

const BalanceRecap = () => {

    const {balance} = useLot();

    return (
        <HeaderSection label="DATI DI VENDITA" className="w-full text-xs" collapsable={true} inverted={true}>

            <div className="flex flex-col md:flex-row gap-1">
                <table className="flex-1 text-right">
                    <thead>
                    <tr className="border-b border-gray-400">
                        <th className="text-left">PELLI</th>
                        <th>Nr</th>
                        <th>Performance</th>
                        <th>mq</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="text-left">acquistate</td>
                        <td>{formatInt(balance.purchased_leathers)}</td>
                        <td title={balance.purchased_leathers_performance}>{formatPrice(balance.purchased_leathers_performance)}</td>
                        <td>{formatPrice(balance.purchased_mt)}</td>
                    </tr>
                    <tr>
                        <td className="text-left">vendute</td>
                        <td>{formatInt(balance.sold_leathers)}</td>
                        <td>{formatPrice(balance.sold_leathers_performance)}</td>
                        <td>{formatPrice(balance.sold_mt)}</td>
                    </tr>
                    <tr>
                        <td className="text-left">in magazzino</td>
                        <td>{balance.in_house_leathers}</td>
                    </tr>
                    <tr>
                        <td className="text-left">resi</td>
                        <td>{balance.returned_leathers}</td>
                    </tr>
                    <tr>
                        <td className="text-left">percentage_sold_mt_difference</td>
                        <td>{formatPrice(balance.percentage_sold_mt_difference)}%</td>
                    </tr>
                    </tbody>
                </table>
                <table className="flex-1 text-right ">
                    <thead className="border-b border-gray-400">
                    <tr>
                        <th></th>
                        <th className="text-right">COSTI</th>
                        <th className="text-right">RICAVI</th>
                        <th className="text-right">PROFITTO</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="text-left">
                            AL PIEDE
                        </td>
                        <td>
                            {priceFormat(balance.cost_per_foot) + "€"}
                        </td>
                        <td>
                            {priceFormat(balance.profit_per_foot) + "€"}
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="text-left">
                            AL METRO
                        </td>
                        <td>
                            {priceFormat(balance.cost_per_mt) + "€"}
                        </td>
                        <td>
                            {priceFormat(balance.profit_per_mt) + "€"}
                        </td>
                        <td></td>
                    </tr>
                    </tbody>
                    <tfoot className="border-t">
                    <tr>
                        <td className="font-bold text-left">TOTALE</td>

                        <td className="text-right">{priceFormat(balance.total_costs) + "€"}</td>
                        <td className="text-right">{priceFormat(balance.total_profit) + "€"}</td>
                        <td className="text-right">{priceFormat(balance.total_revenue) + "€"}</td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </HeaderSection>
    );
};

export default BalanceRecap;