function parseColor(color) {
    return color ?
        {
            id: color.idcol ?? color.id,
            name: color.nomecol ?? color.name,
        }
        : null;
}

function parseArticle(article) {
    return article
        ? {
            id: article.idart ?? article.id,
            name: article.nomeart ?? article.nome ?? article.name ?? "",
        }
        : null;
}

function parseProcess(process) {
    return process
        ? {
            id: process.idlav,
            name: process.nomelav,
        }
        : null;
}

function parseDocumentType(document) {
    return document
        ? {
            id: document.idtipodoc ?? document.id,
            name: document.nome ?? document.name,
        }
        : null;
}

function parseSubcontractor(subcontractor) {

    return subcontractor
        ? {
            id: subcontractor.idterz ?? subcontractor.id,
            name: subcontractor.ragsoctz ?? subcontractor.name ?? subcontractor.nome ?? "",
        }
        : null;
}

function parseSupplier(supplier) {
    return supplier
        ? {
            id: supplier.idforn ?? supplier.id,
            name: supplier.ragsocf ?? supplier.name ?? ""
        }
        : null;
}

function parseClient(client) {
    return client
        ? {
            id: client.id ?? client.idcl,
            name: client.name ?? client.nome ?? client.ragsoccl ?? "",
        }
        :
        null;
}

const parseOptions = {
    parseArticle,
    parseColor,
    parseDocumentType,
    parseProcess,
    parseSubcontractor,
    parseSupplier,
    parseClient,
}

export default parseOptions;