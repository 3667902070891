import React, {useEffect} from 'react';
import {useRegistry} from "../../../utilities/contexts/registry-context";
import useAsync from "../../../utilities/customHooks/async-call";
import parseOptions from "../../../utilities/data/parseOptions";
import {doGetClients} from "../../../logics/registry";
import SearchSelect from "./SearchSelect";

const SelectClient = ({value, onChange, ...props}) => {
    const {clients, loadClients} = useRegistry();
    const {call} = useAsync();

    const getOptions = () => {

        return clients.length > 0
            ? clients.map(subcontractor => parseOptions.parseClient(subcontractor))
            : [];
    }

    const handleChange = (val) => {
        console.log(val);
        onChange && onChange(val);
    }

    useEffect(() => {
        const load = async () => {
            if (!clients || !clients.length > 0) {
                await call(
                    () => doGetClients(),
                    (res) => {
                        console.log(res);
                        loadClients(res);
                    }
                )
            }
        }
        load();
    }, []);

    return (
        <SearchSelect options={getOptions()}
                      value={parseOptions.parseClient(value)}
                      onChange={handleChange}/>
    );
};

export default SelectClient;