import React from 'react';

const IconBtn = ({className, type = "button",...props}) => {
    return(
        <button type={type} className={`${className} text-lg cursor-pointer `} {...props}>
            {props.children}
        </button>
    )
}

export default IconBtn;
