import React from 'react';
import EditMovementForm from "../../EditMovementForm";
import {useLot} from "../../../../../utilities/contexts/lot-context";
import useToast from "../../../../../utilities/customHooks/toast";
import useAsync from "../../../../../utilities/customHooks/async-call";
import {doGetLotBalance, doPutLotMovement} from "../../../../../logics/lots";
import ModalTitleWithCloseButton from "../UI/ModalTitleWithCloseButton";

const EditForm = () => {

    const {openModal, currentMovement, currentLot, updateMovement, loadBalance} = useLot();
    const {toastConfirm} = useToast();
    const {call} = useAsync();

    const handleEdit = async (fields) => {
        await call(
            () => doPutLotMovement(currentLot.nrlotto, currentMovement.idnodo, fields),
            async (res) => {
                if (res) {
                    updateMovement(res);
                    openModal('edit', false);
                    toastConfirm("Movimento modificato");
                }
                await call(
                    () => doGetLotBalance(currentLot.nrlotto),
                    (res) => {
                        loadBalance(res)
                    }
                );
            }
        );
    }

    return (
        <div>
            <ModalTitleWithCloseButton title="Modifica Movimento"
                                       modal="edit"/>
            <EditMovementForm movement={currentMovement}
                              onUndo={() => openModal('edit', false)}
                              onSubmit={handleEdit}
                              sold={currentMovement.sold ?? currentMovement.venduto}/>
        </div>

    );
};

export default EditForm;