import types from "../types/types";
import {act} from "@testing-library/react";

export const defaultState = {
    panelOpen: false,
    createPanelOpen: false,
    clients: [],
    currentClient: null,
    suppliers: [],
    currentSupplier: null,
    subcontractors: [],
    currentSubContractor: null,

    colors: [],
    articles: [],
    processes: [],
    documents: [],
    measure_units: [],
    choices: [],
    lotCategories: [],
}

function registryReducer(state, action) {
    switch (action.type) {
        case types.REGISTRY_SET_PANEL_OPEN: {
            return {
                ...state,
                panelOpen: action.status,
            }
        }
        case types.REGISTRY_SET_CREATE_PANEL_OPEN: {
            return {
                ...state,
                createPanelOpen: action.status,
            }
        }
        case types.REGISTRY_LOAD_CLIENTS: {
            return {
                ...state,
                clients: action.clients,
            }
        }
        case types.REGISTRY_SET_CURRENT_CLIENT: {
            return {
                ...state,
                currentClient: action.client,
            }
        }
        case types.REGISTRY_UPDATE_CLIENT: {

            let _clients = state.clients.map(client => {
                if (client.idcl === action.client.idcl) {
                    return {
                        ...action.client
                    }
                } else return {...client}
            });

            return {
                ...state,
                clients: [..._clients]
            }
        }
        case types.REGISTRY_LOAD_SUPPLIERS: {
            return {
                ...state,
                suppliers: action.suppliers,
            }
        }
        case types.REGISTRY_SET_CURRENT_SUPPLIER: {
            return {
                ...state,
                currentSupplier: action.supplier,
            }
        }
        case types.REGISTRY_UPDATE_SUPPLIER: {
            let _suppliers = state.suppliers.map(supplier => {
                if (supplier.idforn === action.supplier.idforn) {
                    return {...action.supplier}
                } else return {...supplier}
            });

            return {
                ...state,
                suppliers: _suppliers,
            }
        }
        case types.REGISTRY_LOAD_SUBCONTRACTORS: {
            return {
                ...state,
                subcontractors: action.subcontractors,
            }
        }
        case types.REGISTRY_SET_CURRENT_SUBCONTRACTOR: {
            return {
                ...state,
                currentSubcontractor: action.subcontractor
            }
        }
        case types.REGISTRY_UPDATE_SUBCONTRACTOR: {

            let _subcontractors = state.subcontractors.map(subcontractor => {
                if (subcontractor.idterz === action.subcontractor.idterz) {
                    return {...action.subcontractor}
                } else return {...subcontractor}
            })

            return {
                ...state,
                subcontractors: [..._subcontractors]
            }
        }
        case types.REGISTRY_LOAD_COLORS: {
            return {
                ...state,
                colors: [...action.colors]
            }
        }
        case types.REGISTRY_LOAD_ARTICLES: {
            return {
                ...state,
                articles: [...action.articles]
            }
        }
        case types.REGISTRY_LOAD_PROCESSES:
            return {
                ...state,
                processes: [...action.processes]
            }
        case types.REGISTRY_LOAD_DOCUMENTS:
            return {
                ...state,
                documents: [...action.documents]
            }
        case types.REGISTRY_LOAD_MEASURE_UNITS:
            return {
                ...state,
                measure_units: [...action.measure_units]
            }
        case types.REGISTRY_LOAD_CHOICES: {
            return {
                ...state,
                choices: [...action.choices]
            }
        }
        case types.REGISTRY_LOAD_LOT_CATEGORIES: {
            return {
                ...state,
                lotCategories: [...action.categories]
            }
        }
        default:
            throw new Error("Unhandled action type: " + action.type);
    }
}

export default registryReducer;
