import React from 'react';
import queriesReducer, {defaultState} from "../reducers/queries-reducer";
import {actions} from "../actions/queries";

const QueriesContext = React.createContext();

function useQueriesContextValue() {
    const [state, dispatch] = React.useReducer(queriesReducer, defaultState);

    const {
        inHouseRows, inHouseTotals, inHouseSelected,
        subcontractorRows, subcontractorTotals,
        weeklyRows, weeklyTotals, weeklyDates,
        salesByClient, byClientDates, salesByClientTotals,
        subcontractorDocumentsRow, subcontractorDocumentsForm
    } = state;

    const loadInHouseRows = React.useCallback((rows) => dispatch(actions.loadInHouseRows(rows)), []);
    const loadInHouseTotals = React.useCallback((totals) => dispatch(actions.loadInHouseTotals(totals)), []);
    const toggleInHouseSelected = React.useCallback((row) => dispatch(actions.toggleInHouseSelected(row)), []);
    const toggleAllInHouse = React.useCallback(() => dispatch(actions.toggleAllInHouse()), []);

    const loadWeeklyRows = React.useCallback((rows) => dispatch(actions.loadWeeklyRows(rows)), []);
    const loadWeeklyTotals = React.useCallback((totals) => dispatch(actions.loadWeeklyTotals(totals)), []);
    const setWeeklyDates = React.useCallback((start, end) => dispatch(actions.setWeeklyDates(start, end)), []);

    const loadSubcontractorRows = React.useCallback((rows) => dispatch(actions.loadSubcontractorRows(rows)), []);
    const loadSubcontractorTotals = React.useCallback((totals) => dispatch(actions.loadSubcontractorTotals(totals)), []);

    const loadSalesByClient = React.useCallback((sales) => dispatch(actions.loadSalesByClient(sales)), []);
    const setByClientDates = React.useCallback((start, end) => dispatch(actions.setByClientDates(start, end)), []);
    const setSalesByClientTotals = React.useCallback((totals) => dispatch(actions.setSalesByClientTotals(totals)), []);

    const loadSubcontractorDocumentsRows = React.useCallback((rows) => dispatch(actions.loadSubcontractorQueryRows(rows)), []);
    const setSubcontractorDocumentsForm = React.useCallback(
        (subcontractor, start, end) => dispatch(actions.setSubcontractorDocumentsForm(subcontractor, start, end)), []);


    return React.useMemo(() => {
        return {
            inHouseRows, loadInHouseRows,
            inHouseTotals, loadInHouseTotals,
            inHouseSelected, toggleInHouseSelected, toggleAllInHouse,
            subcontractorRows, loadSubcontractorRows,
            subcontractorTotals, loadSubcontractorTotals,
            weeklyRows, weeklyTotals, loadWeeklyRows, loadWeeklyTotals, weeklyDates, setWeeklyDates,
            salesByClient, loadSalesByClient, byClientDates, setByClientDates, salesByClientTotals, setSalesByClientTotals,
            subcontractorDocumentsRow, subcontractorDocumentsForm, loadSubcontractorDocumentsRows, setSubcontractorDocumentsForm,
        }
    }, [
        inHouseRows, loadInHouseRows,
        inHouseTotals, loadInHouseTotals,
        inHouseSelected, toggleInHouseSelected, toggleAllInHouse,
        subcontractorRows, loadSubcontractorRows,
        subcontractorTotals, loadSubcontractorTotals,
        weeklyRows, weeklyTotals, loadWeeklyRows, loadWeeklyTotals, weeklyDates, setWeeklyDates,
        salesByClient, loadSalesByClient, byClientDates, setByClientDates, salesByClientTotals, setSalesByClientTotals,
        subcontractorDocumentsRow, subcontractorDocumentsForm, loadSubcontractorDocumentsRows, setSubcontractorDocumentsForm,
    ]);
}

function QueriesProvider(props) {
    const value = useQueriesContextValue();
    return <QueriesContext.Provider value={value} {...props} />;
}

function useQueries() {
    const context = React.useContext(QueriesContext);
    if (!context) {
        throw new Error("useQuery must be used within a QueriesProvider");
    }

    return context;
}

export {
    QueriesProvider,
    useQueries,
}