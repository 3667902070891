import React from 'react';
import useAsync from "../../utilities/customHooks/async-call";
import {doGetColor} from "../../logics/registry";
import TableRow from "../UI/table/TableRow";
import {FaTrashAlt} from "react-icons/fa";

const ColorRow = ({color, ...props}) => {
    const {call} = useAsync();

    const handleClick = async () => {
        await call(
            () => doGetColor(color.idcol),
            (res) => {
                console.log(res);
            }
        )
    }

    return (
        <TableRow onClick={handleClick} className='text-sm mb-0.5'>
            <td className="pl-2 ">
                {color.nomecol}
            </td>
            <td>
                <div className="w-full flex flex-row items-center justify-center gap-2">
                    <FaTrashAlt/>
                </div>
            </td>
        </TableRow>
    )
};

export default ColorRow;