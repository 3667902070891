import React, {useState} from 'react';
import {useLot} from "../../../../../utilities/contexts/lot-context";
import useAsync from "../../../../../utilities/customHooks/async-call";
import {doPostUnsellMovement} from "../../../../../logics/movements";
import {doGetLotBalance, doGetLotMovements} from "../../../../../logics/lots";
import FatherMovementRecap from "../../FatherMovementRecap";
import ModalTitleWithCloseButton from "../UI/ModalTitleWithCloseButton";
import FormTitle from "../UI/FormTitle";
import AvailableLeathersInput from "../UI/AvailableLeathersInput";
import DocumentInput from "../UI/DocumentInput";
import VerticalLabelledField from "../../../../UI/Fields/VerticalLabelledField";
import Input from "../../../../UI/Input";
import Button from "../../../../UI/Button";
import Row from "../../../../UI/Structure/Row";

const UnsellForm = () => {

    const {currentLot, loadMovements, openModal, loadBalance} = useLot();
    const {call} = useAsync();

    const [nrpz, setNrpz] = useState();
    const [nrdocmov, setNrdocmov] = useState();
    const [datadocmov, setDatadocmov] = useState(new Date().toISOString().substring(0,10));
    const [tipodocmov, setTipodocmov] = useState(1);
    const [valorizzazione, setValorizzazione] = useState("");
    const [note, setNote] = useState("");

    const {currentMovement} = useLot();

    const formRef = React.createRef();

    const handleSubmit = async (e) => {
        e.preventDefault();

        let fields = {
            nrpz,
            note,
            valorizzazione,
        }

        console.log(tipodocmov);
        if (tipodocmov) fields['tipodocmov'] = tipodocmov;
        if (datadocmov) fields['datadocmov'] = datadocmov;
        if (nrdocmov) fields['nrdocmov'] = nrdocmov;
        if (note) fields['notemov'] = note;
        if (valorizzazione) fields['prezzomov'] = valorizzazione;

        await call(
            () => doPostUnsellMovement(currentLot.nrlotto, currentMovement.idnodo, fields),
            async () => {
                await call(
                    () => doGetLotMovements(currentLot.nrlotto),
                    async (res) => {
                        if (res) {
                            loadMovements(res[0]);
                            openModal('unsell', false);
                            await call(
                                () => doGetLotBalance(currentLot.nrlotto),
                                (res) => {
                                    loadBalance(res)
                                }
                            );
                        } else {
                            loadMovements(null);
                        }
                    },
                    (err) => {
                        console.warn(err);
                        loadMovements(null);
                    }
                );
            }
        )
    }

    return (
        <div>
            <ModalTitleWithCloseButton title="Reso vendita"
                                       modal="unsell"/>
            <FatherMovementRecap/>

            <form onSubmit={handleSubmit} ref={formRef}>
                <FormTitle>Inserimento dati documento e costi</FormTitle>
                <AvailableLeathersInput available={currentMovement?.available_leathers}
                                        value={nrpz}
                                        setValue={setNrpz}/>

                <div className="mb-2">
                    <FormTitle>Nr. Documento di arrivo</FormTitle>
                    <DocumentInput number={nrdocmov}
                                   setNumber={setNrdocmov}
                                   type={tipodocmov}
                                   date={datadocmov}
                                   setDate={setDatadocmov}
                                   setType={setTipodocmov}/>
                </div>

                <VerticalLabelledField label="Valorizzazione">
                    <Input value={valorizzazione}
                           placeholder="Valore"
                           onChange={(e) => setValorizzazione(e.target.value)}/>
                </VerticalLabelledField>

                <VerticalLabelledField label="Note">
                    <textarea
                        className="p-1 w-full max-h-64 bg-black bg-opacity-5 required:bg-yellow-500 required:bg-opacity-30 rounded shadow-inner"
                        placeholder="Inserisci note"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}/>
                </VerticalLabelledField>

                <Row className="justify-between items-center mt-6 border-t-2 border-gray-100 py-2">
                    <div className=" text-md text-gray-500 uppercase font-medium">Invio pelli a Zetapelli</div>
                    <Button>Movimenta pelli</Button>
                </Row>
            </form>

        </div>
    );
};

export default UnsellForm;
