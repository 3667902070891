import {useState, useEffect} from 'react';

function useFilter(data, filterFunction) {
    const [filterText, setFilterText] = useState("");

    const [filteredData, setFilteredData] = useState(data);

    function filterData() {
        if(filterText !== "") {
            let _filtered = data.filter(filterFunction);
            setFilteredData(_filtered);
        }
        else {
            setFilteredData(data);
        }
    }

    useEffect(() => {
        filterData();
    }, [filterText]);

    useEffect(() => {
        setFilteredData(data);
        filterData();
    }, [data]);

    return {filterText, setFilterText, filteredData};
}

export default useFilter;