import React from 'react';
import registryReducer, {defaultState} from "../reducers/registry-reducer";
import actions from "../actions/registry";

const RegistryContext = React.createContext();

function useRegistryContextValue() {

    const [state, dispatch] = React.useReducer(registryReducer, defaultState);

    const {
        clients,
        currentClient,
        panelOpen,
        createPanelOpen,
        suppliers,
        currentSupplier,
        subcontractors,
        currentSubcontractor,
        colors,
        documents,
        articles,
        processes,
        measure_units,
        choices,
        lotCategories,
    } = state;

    const setRegistryPanelOpen = React.useCallback((status) => dispatch(actions.setRegistryPanelOpen(status)), []);
    const setCreatePanelOpen = React.useCallback((status) => dispatch(actions.setCreatePanelOpen(status)), [])

    const loadClients = React.useCallback((clients) => dispatch(actions.loadClients(clients)), []);
    const setCurrentClient = React.useCallback((client) => dispatch(actions.setCurrentClient(client)), []);
    const updateClient = React.useCallback((client) => dispatch(actions.updateClient(client)), []);

    const loadSuppliers = React.useCallback((suppliers) => dispatch(actions.loadSuppliers(suppliers)), []);
    const setCurrentSupplier = React.useCallback((supplier) => dispatch(actions.setCurrentSupplier(supplier)), []);
    const updateSupplier = React.useCallback((supplier) => dispatch(actions.updateSupplier(supplier)), []);

    const loadSubcontractors = React.useCallback((subcontractors) => dispatch(actions.loadSubcontractors(subcontractors)), []);
    const setCurrentSubcontractor = React.useCallback((subcontractor) => dispatch(actions.setCurrentSubcontractor(subcontractor)), []);
    const updateSubcontractor = React.useCallback((subcontractor) => dispatch(actions.updateSubcontractor(subcontractor)), []);

    const loadDocuments = React.useCallback((documents) => dispatch(actions.loadDocuments(documents)), []);
    const loadArticles = React.useCallback((articles) => dispatch(actions.loadArticles(articles)), []);
    const loadColors = React.useCallback((colors) => dispatch(actions.loadColors(colors)), []);
    const loadProcesses = React.useCallback((processes) => dispatch(actions.loadProcesses(processes)), []);
    const loadMeasureUnits = React.useCallback((measure_units) => dispatch(actions.loadMeasureUnits(measure_units)), []);
    const loadChoices = React.useCallback((choices) => dispatch(actions.loadChoices(choices)), []);
    const loadLotCategories = React.useCallback((categories) => dispatch(actions.loadLotCategories(categories)), []);

    return React.useMemo(() => {
        return {
            state,
            clients, currentClient, loadClients, setCurrentClient, updateClient,
            panelOpen, setRegistryPanelOpen, createPanelOpen, setCreatePanelOpen,
            suppliers, currentSupplier, loadSuppliers, setCurrentSupplier, updateSupplier,
            subcontractors, currentSubcontractor, loadSubcontractors, setCurrentSubcontractor, updateSubcontractor,
            colors, documents, articles, processes,
            loadDocuments, loadArticles, loadColors, loadProcesses,
            measure_units, loadMeasureUnits,
            choices, loadChoices,
            lotCategories, loadLotCategories,
        }
    }, [
        state,
        clients, currentClient, loadClients, setCurrentClient, updateClient,
        panelOpen, setRegistryPanelOpen, createPanelOpen, setCreatePanelOpen,
        suppliers, currentSupplier, loadSuppliers, setCurrentSupplier, updateSupplier,
        subcontractors, currentSubcontractor, loadSubcontractors, setCurrentSubcontractor, updateSubcontractor,
        colors, documents, articles, processes,
        loadDocuments, loadArticles, loadColors, loadProcesses,
        measure_units, loadMeasureUnits,
        choices, loadChoices,
        lotCategories, loadLotCategories,
    ]);
}

function RegistryProvider(props) {
    const value = useRegistryContextValue();
    return <RegistryContext.Provider value={value} {...props} />;
}

function useRegistry() {
    const context = React.useContext(RegistryContext);
    if (!context) {
        throw new Error("useRegistry must be used within a RegistryProvider");
    }

    return context;
}

export {
    RegistryProvider,
    useRegistry,
}
