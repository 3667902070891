import React from 'react';
import useToast from "../../../utilities/customHooks/toast";
import Row from "../../UI/Structure/Row";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import BaseInput from "../../UI/BaseInput";
import Button from "../../UI/Button";
import Section from "../../UI/Structure/Section";
import {useQueries} from "../../../utilities/contexts/queries-context";


const Search = ({label, onSubmit}) => {

    const {weeklyDates, setWeeklyDates} = useQueries();

    const {toastError} = useToast();

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!weeklyDates.start || !weeklyDates.end) {
            toastError("Inserisci data d'inizio e fine");
            return;
        }
        onSubmit && onSubmit();
    }

    return (
        <Section collapsable={true} label={label}>
            <form onSubmit={handleSubmit} className="flex flex-row items-start justify-between w-full">
                <Row className="gap-2">
                    <VerticalLabelledField label="Dal">
                        <BaseInput type="date" required value={weeklyDates.start} onChange={(e) => setWeeklyDates(e.target.value, weeklyDates.end)}/>
                    </VerticalLabelledField>
                    <VerticalLabelledField label="Al">
                        <BaseInput type="date" required value={weeklyDates.end} onChange={(e) => setWeeklyDates(weeklyDates.start, e.target.value)}/>
                    </VerticalLabelledField>
                </Row>
                <Button className="mt-2">Ricerca</Button>
            </form>
        </Section>
    );
};

export default Search;