import API from "./API";

function doGetMoveData(nr, movement) {
    return API.get(`/lots/${nr}/movements/${movement}/create/move`)
}

function doPostMoveMovement(nr, idnodo, fields) {
    return API.post(`/lots/${nr}/movements/${idnodo}/moving`, new URLSearchParams({...fields}));
}

//ritorno in sede
function doGetBackloadData(nr, movement) {
    return API.get(`/lots/${nr}/movements/${movement}/create/backload`)
}

function doPostBackloadMovement(nr, idnodo, fields) {
    return API.post(`/lots/${nr}/movements/${idnodo}/backload`, new URLSearchParams({...fields}));
}

function doGetSellData(nr, movement) {
    return API.get(`/lots/${nr}/movements/${movement}/create/sell`);
}

function doPostSellMovement(nr, idnodo, fields) {
    return API.post(`/lots/${nr}/movements/${idnodo}/sell`, new URLSearchParams({...fields}));
}

//reso
function doPostUnsellMovement(nr, idnodo, fields) {
    return API.post(`/lots/${nr}/movements/${idnodo}/unsell`, new URLSearchParams({...fields}));
}
function doGetProcessingCost(nr, idterz) {
    return API.get(`/processings/${nr}/costs`, {params: {idterz: idterz}});
}

function doGetArticleColorByCLient(client) {
    return API.get(`/clients/${client}/articles_colors`);
}

function doGetPricesByArticleColor(article_color) {
    return API.get(`/articles_colors/${article_color}/prices`);
}

function doDeleteMovement(nr, idnodo) {
    return API.DELETE(`/lots/${nr}/movements/${idnodo}`);
}

export {
    doGetMoveData,
    doPostMoveMovement,
    doGetBackloadData,
    doPostBackloadMovement,
    doGetSellData,
    doPostSellMovement,
    doGetProcessingCost,
    doDeleteMovement,
    doGetArticleColorByCLient,
    doGetPricesByArticleColor,
    doPostUnsellMovement,
}