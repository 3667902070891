import React from 'react';
import Select from "../../UI/Select";

const LotCategorySelect = ({classname, ...props}) => {
    return (
        <Select className={classname} {...props}>
            <option value="">Seleziona gruppo</option>
            <option value="ZP22">ZP22</option>
            <option value="ZP21">ZP21</option>
            <option value="ZP20">ZP20</option>
            <option value="ZP19">ZP19</option>
            <option value="ZP18">ZP18</option>
            <option value="ZP17">ZP17</option>
            <option value="ZP16">ZP16</option>
            <option value="ZP15">ZP15</option>
            <option value="ZP14">ZP14</option>
            <option value="ZP13">ZP13</option>
            <option value="ZP12">ZP12</option>
            <option value="ZP11">ZP11</option>
            <option value="ZP10">ZP10</option>
            <option value="ZP09">ZP09</option>
            <option value="ZP08">ZP08</option>
            <option value="ZP07">ZP07</option>
            <option value="ZP06">ZP06</option>
            <option value="ZP05">ZP05</option>
            <option value="ZP04">ZP04</option>
        </Select>
    );
};

export default LotCategorySelect;