import React, {useState} from 'react';
import {useQueries} from "../../../utilities/contexts/queries-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {doGetDocumentQuery} from "../../../logics/queries";
import Search from "./Search";
import SubcontractorDocGroup from "./SubcontractorDocGroup";
import Loading from "../../UI/Loading";
import SalesByClientGroup from "../SalesByClient/SalesByClientGroup";
import HiddenPrintContainer from "../../UI/HiddenPrintContainer";
import PrintButton from "../../UI/PrintButton";
import {useReactToPrint} from "react-to-print";
import {printPageStyle} from "../../../utilities/data/tools";
import SubcontractorDocPrintable from "./SubcontractorDocPrintable";

const SubcontractorDocuments = () => {

    const [loading, setLoading] = useState(false);

    const {loadSubcontractorDocumentsRows, subcontractorDocumentsForm} = useQueries();
    const {call} = useAsync();
    const printContainer = React.useRef();

    const handleSubmit = async () => {
        setLoading(true);
        const {subcontractor, start, end} = subcontractorDocumentsForm;
        await call(
            () => doGetDocumentQuery(subcontractor?.id, start, end),
            (res) => {
                if(res && res.length > 0) loadSubcontractorDocumentsRows(res);
                else if(res.id) {
                    let _item = [];
                    _item.push(res);
                    loadSubcontractorDocumentsRows(_item);
                }
                else loadSubcontractorDocumentsRows([]);
            }
        );
        setLoading(false);
    }

    const handlePrint = useReactToPrint({
        pageStyle: printPageStyle,
        content: () => printContainer.current,
        documentTitle: `documenti_terzisti`
    });

    return (
        <div className="flex flex-col flex-1 " style={{minHeight: "100px"}}>

            <Search onSubmit={handleSubmit}/>

            <div className="flex flex-col flex-1 overflow-y-auto relative" style={{minHeight: "50px"}}>
                {loading ?
                    <Loading label="Interrogazione in corso"/>
                    :
                    <SubcontractorDocGroup />

                }
            </div>

            <div className="border-t border-black flex flex-row p-1 justify-between">
                <PrintButton title="Stampa" onClick={handlePrint}/>
            </div>

            <HiddenPrintContainer>
                <div ref={printContainer}>
                    <SubcontractorDocPrintable />
                </div>
            </HiddenPrintContainer>
        </div>
    );
};

export default SubcontractorDocuments;