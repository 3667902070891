import React from 'react';
import LotLink from "../../Lots/LotLink";
import DocumentInfo from "../../UI/DataDisplay/DocumentInfo";
import TableRow from "../General/TableRow";
import {formatPrice} from "../../../utilities/data/parser";

const SubcontractorRow = ({row, displayCosts}) => {
    return (
        <TableRow>
            <td className="text-left pl-2">{row?.subcontractor?.name}</td>
            <td className="text-center"><LotLink nrlotto={row.nrlotto} /></td>
            <td className="pl-2"><DocumentInfo document={row.document}/></td>
            <td>{row?.article?.name} - {row?.color?.name}</td>
            <td className="w-24 text-right pr-1">{row.available_leathers}</td>
            {displayCosts && <td className="w-32 text-right pr-1">{formatPrice(row?.costs?.per_leather)}€</td>}
            {displayCosts && <td className="w-24 text-right pr-1">{formatPrice(row?.costs?.progressive)}€</td>}
        </TableRow>
    );
};

export default SubcontractorRow;