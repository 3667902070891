import React, {useState, useEffect} from 'react';
import {useLot} from "../../utilities/contexts/lot-context";
import LotRow from "./LotRow";
import LotsPagination from "./LotsPagination";
import Loading from "../UI/Loading";
import LotsTableHeader from "./LotsTableHeader";
import CreateLotModal from "./CreateLotModal";
import useAsync from "../../utilities/customHooks/async-call";
import {doGetLots, doGetSuggestedLotNumber} from "../../logics/lots";
import {useNavigate} from "react-router-dom";

const LotsList = () => {

    const {lots, loadLots, loadPagination} = useLot();

    const {call} = useAsync();
    const navigate = useNavigate();

    const [loadingLots, setLoadingLots] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [suggestedLotNr, setSuggestedLotNr] = useState("");

    const [loading, setLoading] = useState(false);

    const handleCreate = async () => {
        await call(
            () => doGetSuggestedLotNumber(),
            async (res) => {
                await setSuggestedLotNr(res && res.lot_nr ? res.lot_nr : "");
                setCreateModalOpen(true);
            }
        )
    }

    useEffect(() => {
        const load = async () => {

            setLoading(true);

            await call(
                () => doGetLots(),
                (res, pagination) => {
                    if (res && res.length > 0) {
                        loadLots(res);
                    }
                    if (pagination) {
                        loadPagination(pagination)
                        console.log(pagination)
                    }

                },
                (err) => console.log("Error " + err),
            )

            setLoading(false);
        }
        load();
    }, [])

    return (
        <div className="flex flex-col h-full">
            <div className="h-full overflow-y-auto">

                <table className="w-full overflow-y-auto max-h-full" style={{minHeight: "30px"}}>
                    <LotsTableHeader setLoading={setLoadingLots}/>

                    <tbody className="">
                    {loadingLots
                        ? <tr>
                            <td colSpan="9"><Loading label={"Caricamento lotti"} className="text-lg mt-4 font-medium"/>
                            </td>
                        </tr>
                        : <>
                            {lots && lots.length > 0 ?
                                lots.map((lot, i) => <LotRow lot={lot} key={i}/>)
                                :
                                <tr>
                                    <td colSpan="9">Nessun lotto disponibile</td>
                                </tr>
                            }
                        </>
                    }
                    </tbody>
                </table>
            </div>
            <LotsPagination setLoading={setLoadingLots} onCreate={handleCreate}/>

            <CreateLotModal open={createModalOpen} onOverlayClick={() => setCreateModalOpen(false)}
                            suggested={suggestedLotNr}/>
        </div>
    );
};

export default LotsList;
