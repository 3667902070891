import React, {useEffect, useState} from 'react';
import {useLot} from "../../../../../utilities/contexts/lot-context";
import FatherMovementRecap from "../../FatherMovementRecap";
import Row from "../../../../UI/Structure/Row";
import VerticalLabelledField from "../../../../UI/Fields/VerticalLabelledField";
import Input from "../../../../UI/Input";
import AvailableLeathersInput from "../UI/AvailableLeathersInput";
import ModalTitleWithCloseButton from "../UI/ModalTitleWithCloseButton";
import ProcessingInput from "../UI/ProcessingInput";
import ArticleInput from "../UI/ArticleInput";
import ColorInput from "../UI/ColorInput";
import DocumentInput from "../UI/DocumentInput";
import FormTitle from "../UI/FormTitle";
import Button from "../../../../UI/Button";
import PriceRow from "../UI/PriceRow";
import {doGetProcessingCost, doPostBackloadMovement, doPostMoveMovement} from "../../../../../logics/movements";
import usePrices from "../../../../../utilities/customHooks/prices";
import useAsync from "../../../../../utilities/customHooks/async-call";
import {doGetLotBalance, doGetLotMovements} from "../../../../../logics/lots";

const BackloadForm = () => {

    const {modal, openModal, currentMovement, movingData, currentLot, loadMovements, loadBalance} = useLot();
    const {prices, setters, initializePrices, resetPrices} = usePrices();
    const {call} = useAsync();

    const [nrpz, setNrpz] = useState(currentMovement.available_leathers ?? 0);
    const [processing, setProcessing] = useState(movingData?.selected?.processing ?? null);
    const [article, setArticle] = useState();
    const [color, setColor] = useState();

    const [tipodocmov, setTipodocmov] = useState(1);
    const [datadocmov, setDatadocmov] = useState(new Date().toISOString().substring(0,10));
    const [nrdocmov, setNrdocmov] = useState(null);
    const [costo0, setCosto0] = useState("");

    const loadCosts = async (idlav, idterz) => {
        await call(
            () => doGetProcessingCost(idlav, idterz),
            (res) => {
                if (res.costs) {
                    initializePrices(res.costs);
                } else
                    resetPrices();
            },
            (err) => {
                resetPrices();
            }
        );
    }

    const handleProcessingChange = async (processing) => {
        let idterz = currentMovement.subcontractor.id;
        setProcessing(processing);

        if (processing) {
            await loadCosts(processing, idterz);
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        let fields = {
            nrpz,
            article,
            processing,
            color,
        }

        if (prices.prezzo1) fields['prezzo1'] = prices.prezzo1;
        if (prices.um_1?.id) fields['idum1'] = prices.um_1.id;
        if (prices.qta_1) fields['qta1'] = prices.qta_1;
        if (prices.prezzo2) fields['prezzo2'] = prices.prezzo2;
        if (prices.um_2?.id) fields['idum2'] = prices.um_2.id;
        if (prices.qta_2) fields['qta2'] = prices.qta_2;
        if (prices.prezzo3) fields['prezzo3'] = prices.prezzo3;
        if (prices.um_3?.id) fields['idum3'] = prices.um_3.id;
        if (prices.qta_3) fields['qta3'] = prices.qta_3;
        if (tipodocmov) fields['tipodocmov'] = tipodocmov;
        if (datadocmov) fields['datadocmov'] = datadocmov;
        if (nrdocmov) fields['nrdocmov'] = nrdocmov;
        if (costo0 !== "") fields['costo0'] = costo0;

        await call(
            () => doPostBackloadMovement(currentLot.nrlotto, currentMovement.idnodo, fields),
            async (res) => {
                console.log(res);
                await call(
                    () => doGetLotMovements(currentLot.nrlotto),
                    async (res) => {
                        if (res) {
                            console.log("loadingMovements")
                            loadMovements(res[0]);
                            openModal('backload', false);
                            await call(
                                () => doGetLotBalance(currentLot.nrlotto),
                                (res) => {
                                    loadBalance(res)
                                }
                            );
                        } else {
                            loadMovements(null);
                        }
                    },
                    (err) => {
                        console.warn(err);
                        loadMovements(null);
                    }
                );
            }
        )
    }

    useEffect(() => {
        const load = async () => {
            if (processing) {
                let idterz = currentMovement.subcontractor.id;
                await loadCosts(processing, idterz);
            }
        }

        load();
    }, [modal.backload]);

    return (
        <div>
            <ModalTitleWithCloseButton title="Ritorno in sede"
                                       modal="backload"/>

            <FatherMovementRecap/>

            <form onSubmit={handleSubmit}>
                <div className="text-md uppercase font-medium text-gray-500 mb-1">Inserimento dati documento e
                    costi
                </div>

                <AvailableLeathersInput available={currentMovement?.available_leathers}
                                        value={nrpz}
                                        setValue={setNrpz}/>

                <ProcessingInput value={processing}
                                 onChange={handleProcessingChange}/>

                <ArticleInput value={article}
                              setValue={setArticle}/>

                <ColorInput value={color}
                            setValue={setColor}/>

                <div className="mb-2">
                    <FormTitle>Nr. Documento di arrivo</FormTitle>
                    <DocumentInput number={nrdocmov}
                                   setNumber={setNrdocmov}
                                   date={datadocmov}
                                   type={tipodocmov}
                                   setDate={setDatadocmov}
                                   setType={setTipodocmov}/>
                </div>

                {(prices.prezzo1 || prices.prezzo2 || prices.prezzo3) &&
                <div className="mb-2">
                    <div className="text-md uppercase font-medium text-gray-500 leading-none">Costi movimento</div>
                    <PriceRow price={prices.prezzo1}
                              um={prices.um_1?.name}
                              qta={prices.qta_1}
                              setPrice={setters.setPrezzo1}
                              setQta={setters.setQta_1}/>
                    <PriceRow price={prices.prezzo2}
                              um={prices.um_2?.name}
                              qta={prices.qta_2}
                              setPrice={setters.setPrezzo2}
                              setQta={setters.setQta_2}/>
                    <PriceRow price={prices.prezzo3}
                              um={prices.um_3?.name}
                              qta={prices.qta_3}
                              setPrice={setters.setPrezzo3}
                              setQta={setters.setQta_3}/>
                </div>
                }

                <VerticalLabelledField label="Mq 0%">
                    <Input placeholder="Inserisci"
                           value={costo0}
                           onChange={(e) => setCosto0(e.target.value)}/>
                </VerticalLabelledField>

                <Row className="justify-between items-center mt-6 border-t-2 border-gray-100 py-2">
                    <div className=" text-lg text-gray-500 uppercase font-medium">Invio pelli a Zetapelli</div>
                    <Button>Conferma</Button>
                </Row>
            </form>
        </div>
    );
};

export default BackloadForm;