import React from 'react';

const FormTitle = ({className, ...props}) => {
    return (
        <div className={`text-md uppercase font-medium text-gray-500 mb-1 ${className}`} {...props}>
            {props.children}
        </div>
    );
};

export default FormTitle;