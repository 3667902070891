import React from 'react';
import TableRow from "../UI/table/TableRow";
import useAsync from "../../utilities/customHooks/async-call";
import {useRegistry} from "../../utilities/contexts/registry-context";
import {doGetSupplier} from "../../logics/registry";
import {FaTrashAlt} from "react-icons/fa";

const SupplierRow = ({supplier, ...props}) => {

    const {setCurrentSupplier, setRegistryPanelOpen} = useRegistry();
    const {call} = useAsync();

    const handleClick = async () => {
        await call(
            () => doGetSupplier(supplier.idforn),
            (res) => {
                if (res && res[0]) {
                    setCurrentSupplier(res[0]);
                    setRegistryPanelOpen(true);
                }
            }
        )
    }

    return (
        <TableRow onClick={handleClick} className='text-sm mb-0.5'>
            <td className="pl-2">
                {supplier.ragsocf}
            </td>
            <td>
                <div className="w-full flex flex-row items-center justify-center gap-2">
                    <FaTrashAlt/>
                </div>
            </td>
        </TableRow>
    )
}

export default SupplierRow;
