import React, {useEffect, useState} from 'react';
import {useLot} from "../../utilities/contexts/lot-context";
import {BiChevronLeft, BiChevronRight, BiFirstPage, BiLastPage, BiPlus} from "react-icons/bi";
import useAsync from "../../utilities/customHooks/async-call";
import {doGetPaginatedLots} from "../../logics/lots";

const ChangePageIcon = ({icon, url, onPageChange, title = ""}) => {

    const handleClick = async () => {
        if(url) {
            onPageChange && onPageChange(url)
        }
    }

    return (
        <div onClick={handleClick} className={`${url ? "cursor-pointer hover:text-blue-400" : "text-gray-200"}`} title={title}>
            {icon}
        </div>
    );
}


const LotsPagination = ({setLoading, onCreate}) => {

    const {pagination, loadPagination, loadLots} = useLot();
    const {call} = useAsync();

    const [currentPage, setCurrentPage] = useState(pagination && pagination.current_page ? pagination.current_page : 1);

    const onPageChange = async (e) => {
        await changePage(`http://api.zetapelli.local/lots/?current_page=${currentPage}`);
    }

    const changePage = async (url) => {
        if (url) {
            setLoading(true);
            await call(() => doGetPaginatedLots(url),
                (res, pagination) => {
                    loadLots(res);
                    loadPagination(pagination);
                }
            );
            setLoading(false);
        }
    }

    const createNewLot = () => {
        onCreate && onCreate();
    }

    useEffect(() => {
        setCurrentPage(pagination && pagination.current_page ? pagination.current_page : 1)
    }, [pagination]);

    return (
        <div className="p-1 bg-secondary text-white">
            {pagination !== [] && pagination !== null &&
            <div className="flex flex-row justify-between">
                <div className="flex flex-col justify-center">
                    <BiPlus className="text-lg hover:text-green-400 cursor-pointer" onClick={createNewLot}/>
                </div>

                <div className="flex flex-row gap-1 items-center justify-center">
                    <ChangePageIcon icon={<BiFirstPage/>}
                                    onPageChange={changePage}
                                    url={pagination.first_page_uri}
                                    title="Prima pagina"/>
                    <ChangePageIcon icon={<BiChevronLeft/>}
                                    onPageChange={changePage}
                                    url={pagination.previous_page_uri}
                                    title="Pagina precedente"/>

                    <div className="text-xs">Pagina</div>
                    <input type="number"
                           value={currentPage}
                           onChange={(e) => setCurrentPage(e.target.value)}
                           onBlur={onPageChange}
                           className="w-10 p-0 text-center border rounded text-black"/>
                    <div className="text-xs">di</div>
                    <div>{pagination.total_pages}</div>

                    <ChangePageIcon icon={<BiChevronRight/>}
                                    onPageChange={changePage}
                                    url={pagination.next_page_uri}
                                    title="Prossima pagina"/>
                    <ChangePageIcon icon={<BiLastPage/>}
                                    onPageChange={changePage}
                                    url={pagination.last_page_uri}
                                    title="Ultima pagina"/>
                </div>


                <div>{pagination.items_per_page} su {pagination.total_items}</div>
            </div>
            }

        </div>
    );
};

export default LotsPagination;