import React from 'react';
import {CgSpinner} from "react-icons/cg";

const Loading = ({label = "", className, ...props}) => {
    return (
        <div className={`${className} flex flex-row items-center justify-center gap-2`}>
            <div>{label}</div>
            <CgSpinner className="animate-spin"/>
        </div>
    );
};

export default Loading;