import React from 'react';
import TableRow from "../General/TableRow";
import DocumentInfo from "../../UI/DataDisplay/DocumentInfo";

const SalesByClientRow = ({row}) => {
    return (
        <TableRow>
            <td>{row?.nrlotto}</td>
            <td><DocumentInfo document={row?.document} hideType={true} hideCompany={true}/> </td>
            <td>{row?.article}</td>
            <td className="border-l border-black text-right pr-2">{row?.nrpz}</td>
            <td className="text-center border-l border-black">{row?.choices?.I}</td>
            <td className="text-center border-l border-black">{row?.choices?.II}</td>
            <td className="text-center border-l border-black">{row?.choices?.III}</td>
        </TableRow>
    );
};

export default SalesByClientRow;