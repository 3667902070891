import React from 'react';
import {useApp} from "../../utilities/contexts/app-context";

const ToastContainer = () => {

    const {toastContent, toastStatus} = useApp();

    return (
        <div className="absolute bottom-0 left-0 right-0 flex flex-row justify-center">
            {toastStatus &&
                <div >
                    {toastContent}
                </div>
            }
        </div>
    );
}

export default ToastContainer;
