import React from 'react';
import RowAligned from "../../../UI/Structure/RowAligned";

const LotRow = ({row, ...props}) => {
    return (
        <tr {...props}>
            <td className="pl-1 ">{row.article.name}</td>
            <td className="pl-1 border-l border-gray-300">{row.color.name}</td>
            <td className="pl-1 border-l border-gray-300">{row.choice.name}</td>
            <td className="pl-1 border-l border-gray-300">{row.qta}</td>
            <td className="pl-1 border-l border-gray-300">{row.nrpz}</td>
            <td className="pl-1 border-l border-gray-300">{row.prezzo}</td>
        </tr>
    );
}


const RowsDisplay = ({rows, className = ""}) => {
    return (
        <table className={`w-full text-xs text-left ${className}`}>
            <thead>
            <tr className="uppercase font-medium text-gray-500 leading-none border-b border-gray-300" style={{fontSize: "10px"}}>
                <th className="pl-1 pb-1 ">Articolo</th>
                <th className="pl-1 pb-1 border-l border-gray-300">Colore</th>
                <th className="pl-1 pb-1 border-l border-gray-300">Scelta</th>
                <th className="pl-1 pb-1 border-l border-gray-300">Superficie</th>
                <th className="pl-1 pb-1 border-l border-gray-300">Nr.Pz</th>
                <th className="pl-1 pb-1 border-l border-gray-300">Prezzo Un.</th>
            </tr>
            </thead>
            <tbody>
            {rows && rows.length > 0 &&
            rows.map(row => <LotRow key={row.idriga} row={row} />)
            }
            </tbody>
        </table>
    );
};

export default RowsDisplay;