import useToast from "./toast";

function useAsync() {

    const {toastError} = useToast();

    async function call(func, onSuccess, onFailure) {
        try {
            let response = await func();
            if (response) {
                if (response.data) {
                    response = response.data;
                    const status = response.status;
                    const data = response.data;
                    const pagination = response.pagination ? response.pagination : null;
                    if (status === "ok") {
                        if (onSuccess) {
                            return onSuccess(data, pagination);
                        }
                    }

                    if (status === "ko") {
                        //TODO: show error message
                        if (onFailure) {
                            if (response.error)
                                return onFailure(response.error);
                            else
                                return onFailure(data);
                        } else {
                            try {
                                console.log(response.error);
                                if (response.error.message) {
                                    toastError(response.error.message);
                                }
                            } catch (e) {
                                console.warn(e);
                            }
                        }

                    }
                }
            }

            onFailure && onFailure(response);
        } catch (error) {
            onFailure && onFailure(error);
        }
    }

    return {
        call,
    }
}

export default useAsync;