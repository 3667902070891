import React from 'react';
import {useLot} from "../../../utilities/contexts/lot-context";
import {parseDate} from "../../../utilities/data/parser";
import Row from "../../UI/Structure/Row";

const FirstMovementDetails = () => {

    const {lotRows, currentLot} = useLot();

    const getTotals = () => {

        console.log(currentLot);

        if(lotRows && lotRows.length > 0) {
            return lotRows.reduce((prev, row) => {
                return prev + row.nrpz;
            }, 0);
        } else {
            return (<span className="text-gray-400 italic">Righe non disponibili</span>);
        }
    }

    return (
        <div className="mb-2 border-b">
            <div className="uppercase font-medium text-xs text-gray-600">Dettagli</div>
            <div>Lotto: {currentLot.nrlotto}</div>
            <div>Numero Pezzi: {getTotals()}</div>
            <div className="text-xs leading-none mt-2 uppercase text-gray-500">Documento d'ingresso</div>
            <Row>
                <div>{currentLot.document.type.nome ?? currentLot.document.type.name} -</div>
                <div>{currentLot.nrdocfor} -</div>
                <div>{parseDate(currentLot.datadocfor)}</div>
            </Row>
        </div>
    );
};

export default FirstMovementDetails;