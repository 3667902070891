import React from 'react';

const Modal = ({open, onOverlayClick, className, style, ...props}) => {

    const handleOverlayClick = () => {
        console.log("CLICK");
        onOverlayClick && onOverlayClick()
    }

    return (

        <>
            {open &&
            <div
                className="bg-black bg-opacity-40 absolute inset-0 z-10"
                onClick={handleOverlayClick}>
                <div className="flex-1 flex flex-col items-center mt-12 z-20" >
                    <div className={`${className ? className : ""} bg-white p-2 rounded-lg`}
                         style={style ?? null}
                         onClick={(e) => e.stopPropagation()}>
                        {props.children}
                    </div>
                </div>

            </div>

            }
        </>
        
        
        
        // <div className="absolute top-0 right-0 left-0 z-10">
        //     {open &&
        //     <>
        //         <div onClick={handleOverlayClick}
        //             className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center pt-24"/>
        //         <div className="bg-white p-8 z-20" onClick={(e) => e.stopPropagation()}>
        //             {props.children}
        //         </div>
        //
        //
        //     </>
        //     }
        // </div>
    );

}

export default Modal;