import React, {useState, useRef} from 'react';
import {useReactToPrint} from "react-to-print";
import {useQueries} from "../../../utilities/contexts/queries-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {doGetWeeklySales} from "../../../logics/queries";
import {dateToFileName, formatInt, formatPrice, parseDate} from "../../../utilities/data/parser";
import {get7daysAgo, printPageStyle} from "../../../utilities/data/tools";
import Search from "../WeeklySales/Search";
import Loading from "../../UI/Loading";
import WeeklySalesTable from "./WeeklySalesTable";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import HorizontalLabelledField from "../../UI/Fields/HorizontalLabelledField";
import Section from "../../UI/Structure/Section";
import PrintButton from "../../UI/PrintButton";
import HiddenPrintContainer from "../../UI/HiddenPrintContainer";
import WeeklyPrintable from "./WeeklyPrintable";

const WeeklySales = () => {

    const {weeklyRows, loadWeeklyRows, weeklyTotals, loadWeeklyTotals, weeklyDates} = useQueries();
    const {call} = useAsync();
    const [loading, setLoading] = useState(false);
    const [unsold, setUnsold] = useState([]);

    const printContainer = useRef();

    const handleSubmit = async () => {
        setLoading(true);
        await call(
            () => doGetWeeklySales(weeklyDates.start, weeklyDates.end),
            (res) => {
                console.log(res);
                //load sales
                const {sold, unsold, ...totals} = res;
                loadWeeklyRows(sold);
                loadWeeklyTotals(totals);
                setUnsold(unsold);
            }
        )
        setLoading(false);
    }

    const handlePrint = useReactToPrint({
        pageStyle: printPageStyle,
        content: () => printContainer.current,
        documentTitle: `vendite_${dateToFileName(weeklyDates.start)}_${dateToFileName(weeklyDates.end)}`
    });

    return (
        <div className="flex flex-col flex-1 " style={{minHeight: "100px"}}>
            <Search onSubmit={handleSubmit}
                    label={"Ricerca vendite settimanali"}/>

            <div className="flex flex-col flex-1 overflow-y-auto relative" style={{minHeight: "50px"}}>
                {loading ?
                    <Loading label="Interrogazione in corso"/>
                    :
                    <>
                        {weeklyRows && weeklyRows.length > 0 &&
                        <Section label={`Vendite dal ${parseDate(weeklyDates.start)} al ${parseDate(weeklyDates.end)}`}
                                 collapsable={true}
                                 color={"primary"}
                                 contentColor={false}>
                            <WeeklySalesTable rows={weeklyRows}
                                              total_mq={weeklyTotals?.total_mq_sold}
                                              total_pq={weeklyTotals?.total_pq_sold}/>
                        </Section>
                        }
                        {unsold && unsold.length > 0 &&
                        <Section label={`Resi dal ${parseDate(weeklyDates.start)} al ${parseDate(weeklyDates.end)}`}
                                 collapsable={true}
                                 color={"primary"}
                                 contentColor={false}>
                            <WeeklySalesTable rows={unsold}
                                              unsold={true}
                                              total_leathers_unsold={weeklyTotals?.total_leathers_unsold}
                                              total_mq={weeklyTotals?.total_mq_unsold}
                                              total_pq={weeklyTotals?.total_pq_unsold}/>
                        </Section>
                        }

                    </>
                }
            </div>

            {weeklyTotals &&
            <div className="flex flex-row justify-between gap-2 border-t border-black p-1">
                <PrintButton title="Stampa" onClick={handlePrint}/>
                <VerticalLabelledField label="Vendite">
                    <div className="flex flex-row gap-2">
                        <HorizontalLabelledField label="Totali">
                            <div>{formatInt(weeklyTotals.total_leathers_sold)}</div>
                        </HorizontalLabelledField>
                        <HorizontalLabelledField label="MQ">
                            <div>{formatPrice(weeklyTotals.total_mq_sold)}</div>
                        </HorizontalLabelledField>
                        <HorizontalLabelledField label="PQ">
                            <div>{formatPrice(weeklyTotals.total_pq_sold)}</div>
                        </HorizontalLabelledField>
                    </div>
                </VerticalLabelledField>
                <VerticalLabelledField label="Resi">
                    <div className="flex flex-row gap-2">
                        <HorizontalLabelledField label="Totali">
                            <div>{formatInt(weeklyTotals.total_leathers_unsold)}</div>
                        </HorizontalLabelledField>
                        <HorizontalLabelledField label="MQ">
                            <div>{formatPrice(weeklyTotals.total_mq_unsold)}</div>
                        </HorizontalLabelledField>
                    </div>
                </VerticalLabelledField>
            </div>
            }

            <HiddenPrintContainer>
                <div ref={printContainer}>
                    <WeeklyPrintable startDate={weeklyDates.start}
                                     endDate={weeklyDates.end}
                                     sold={weeklyRows}
                                     unsold={unsold}
                                     totals={weeklyTotals}/>
                </div>
            </HiddenPrintContainer>

        </div>
    );
};

export default WeeklySales;