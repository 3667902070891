import React, {useState} from 'react';
import Section from "../../UI/Structure/Section";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import SelectSubcontractor from "../../UI/Selects/SelectSubcontractor";
import BaseInput from "../../UI/BaseInput";
import Row from "../../UI/Structure/Row";
import {get7daysAgo} from "../../../utilities/data/tools";
import Button from "../../UI/Button";
import {useQueries} from "../../../utilities/contexts/queries-context";

const Search = ({onSubmit}) => {

    const {subcontractorDocumentsForm, setSubcontractorDocumentsForm} = useQueries();

    const handleStartChange = (e) => {
        setSubcontractorDocumentsForm(
            subcontractorDocumentsForm.subcontractor,
            e.target.value,
            subcontractorDocumentsForm.end,
        );
    }

    const handleEndChange = (e) => {
        setSubcontractorDocumentsForm(
            subcontractorDocumentsForm.subcontractor,
            subcontractorDocumentsForm.start,
            e.target.value
        );
    }

    const handleSubcontractorChange = (sub) => {
        setSubcontractorDocumentsForm(
            sub,
            subcontractorDocumentsForm.start,
            subcontractorDocumentsForm.end
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit && onSubmit();
    }

    return (
        <Section collapsable={true} label={"Stampa documenti terzista"}>
            <form className="flex flex-row items-center w-full justify-between" onSubmit={handleSubmit}>
                <Row className="gap-2">
                    <VerticalLabelledField label="Terzista">
                        <SelectSubcontractor onChange={handleSubcontractorChange} value={subcontractorDocumentsForm.subcontractor}/>
                    </VerticalLabelledField>
                    <Row>
                        <VerticalLabelledField label="Dal">
                            <BaseInput type="date"
                                       value={subcontractorDocumentsForm.start}
                                       onChange={handleStartChange}/>
                        </VerticalLabelledField>
                        <VerticalLabelledField label="Al">
                            <BaseInput type="date" value={subcontractorDocumentsForm.end} onChange={handleEndChange}/>
                        </VerticalLabelledField>
                    </Row>
                </Row>
                <Button>Ricerca</Button>
            </form>
        </Section>
    );
};

export default Search;