import React from 'react';

const HiddenPrintContainer = ({...props}) => {
    return (
        <div className="hidden" {...props}>
            {props.children}
        </div>
    );
};

export default HiddenPrintContainer;