import React from 'react';

const Th = ({className, ...props}) => {
    return (
        <th className={`border border-black ${className}`} {...props}>
            {props.children}
        </th>
    );
};

export default Th;