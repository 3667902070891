import React from 'react';
import LoginForm from "../components/Login/LoginForm";
import Layout from "../components/UI/Layout";

const Login = () => {

    return (
        <Layout title="Login">
            <div className="flex flex-col items-center justify-center pt-12">
                <LoginForm />
            </div>
        </Layout>
    )
}

export default Login;
