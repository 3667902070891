import React, {useState} from 'react';
import {useLot} from "../../../../../utilities/contexts/lot-context";
import usePrices from "../../../../../utilities/customHooks/prices";
import useAsync from "../../../../../utilities/customHooks/async-call";
import {
    doGetArticleColorByCLient,
    doGetPricesByArticleColor,
    doPostSellMovement
} from "../../../../../logics/movements";
import {doGetLotBalance, doGetLotMovements} from "../../../../../logics/lots";
import FatherMovementRecap from "../../FatherMovementRecap";
import ModalTitleWithCloseButton from "../UI/ModalTitleWithCloseButton";
import AvailableLeathersInput from "../UI/AvailableLeathersInput";
import FormTitle from "../UI/FormTitle";
import DocumentInput from "../UI/DocumentInput";
import ProcessingInput from "../UI/ProcessingInput";
import ClientInput from "../UI/ClientInput";
import ArticleColorInput from "../UI/ArticleColorInput";
import PriceRow from "../UI/PriceRow";
import Button from "../../../../UI/Button";
import Row from "../../../../UI/Structure/Row";

const SellForm = () => {

    const {currentMovement, movingData, loadMovements, currentLot, loadBalance} = useLot();
    const {prices, setters, initializePrices, resetPrices} = usePrices();
    const {call} = useAsync();
    const {openModal} = useLot();

    const [nrpz, setNrpz] = useState(currentMovement.available_leathers ?? 0);
    const [processing, setProcessing] = useState(movingData?.selected?.processing ?? null);
    const [client, setClient] = useState(null);

    const [tipodocmov, setTipodocmov] = useState(1);
    const [datadocmov, setDatadocmov] = useState(new Date().toISOString().substring(0,10));
    const [nrdocmov, setNrdocmov] = useState(null);

    const [articlesColors, setArticlesColors] = useState([]);
    const [articleColor, setArticleColor] = useState(null);

    const handleProcessingChange = async (processing) => {
        setProcessing(processing);
    }

    const handleClientChange = async (client) => {
        await setArticlesColors([]);
        await setArticleColor(null);
        resetPrices();

        await call(
            () => doGetArticleColorByCLient(client),
            (res) => {
                console.log(res);
                setArticlesColors(res);
            }
        )

        setClient(client);
    }

    const handleArticleColorChange = async (value) => {
        resetPrices();

        await call(
            () => doGetPricesByArticleColor(value),
            (res) => {
                console.log(res);
                if (res.prices) {
                    initializePrices(res.prices);
                }
            }
        )
        setArticleColor(value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let fields = {
            nrpz,
            processing,
            client,
        }

        if (prices.prezzo1) fields['prezzo1'] = prices.prezzo1;
        if (prices.qta_1) fields['qta1'] = prices.qta_1;
        if (prices?.choice1?.id) fields['idum1'] = prices?.choice1?.id;
        if (prices.prezzo2) fields['prezzo2'] = prices.prezzo2;
        if (prices.qta_2) fields['qta2'] = prices.qta_2;
        if (prices?.choice2?.id) fields['idum2'] = prices?.choice2?.id;
        if (prices.prezzo3) fields['prezzo3'] = prices.prezzo3;
        if (prices.qta_3) fields['qta3'] = prices.qta_3;
        if (prices?.choice3?.id) fields['idum3'] = prices?.choice3?.id;
        if (tipodocmov) fields['tipodocmov'] = tipodocmov;
        if (datadocmov) fields['datadocmov'] = datadocmov;
        if (nrdocmov) fields['nrdocmov'] = nrdocmov;

        if (articleColor) {
            if (articleColor.includes('-')) {
                let split = articleColor.split('-');
                if (split.length === 2) {
                    fields['idart'] = split[0];
                    fields['idcol'] = split[1];
                }
            }
        }

        console.log(fields);

        await call(
            () => doPostSellMovement(currentLot.nrlotto, currentMovement.idnodo, fields),
            async (res) => {
                await call(
                    () => doGetLotMovements(currentLot.nrlotto),
                    async (res) => {
                        if (res) {
                            loadMovements(res[0]);
                            openModal('sell', false);
                            await call(
                                () => doGetLotBalance(currentLot.nrlotto),
                                (res) => {
                                    loadBalance(res)
                                }
                            );
                        } else {
                            loadMovements(null);
                        }
                    },
                    (err) => {
                        console.warn(err);
                        loadMovements(null);
                    }
                );
            }
        )
    }

    return (
        <div>
            <ModalTitleWithCloseButton title="Completamento movimentazione"
                                       modal="sell"/>

            <FatherMovementRecap/>

            <form onSubmit={handleSubmit}>
                <FormTitle>Inserimento dati documento e costi</FormTitle>
                <AvailableLeathersInput available={currentMovement?.available_leathers}
                                        value={nrpz}
                                        setValue={setNrpz}/>

                <div className="mb-2">
                    <FormTitle>Documento di vendita</FormTitle>
                    <DocumentInput number={nrdocmov}
                                   setNumber={setNrdocmov}
                                   date={datadocmov}
                                   type={tipodocmov}
                                   setDate={setDatadocmov}
                                   setType={setTipodocmov}/>
                </div>

                <ProcessingInput value={processing}
                                 onChange={handleProcessingChange}/>

                <ClientInput value={client}
                             onChange={handleClientChange}/>

                {articlesColors && articlesColors.length > 0 &&
                <ArticleColorInput articlesColors={articlesColors}
                                   value={articleColor}
                                   setValue={handleArticleColorChange}/>

                }

                {(prices.prezzo1 !== null || prices.prezzo2 !== null || prices.prezzo3 !== null) &&
                <div className="mb-2">
                    <div className="text-md uppercase font-medium text-gray-500 leading-none">Costi movimento</div>
                    <PriceRow price={prices.prezzo1}
                              qta={prices.qta_1}
                              setPrice={setters.setPrezzo1}
                              setQta={setters.setQta_1}
                              choice={prices.choice1}/>
                    <PriceRow price={prices.prezzo2}
                              qta={prices.qta_2}
                              setPrice={setters.setPrezzo2}
                              setQta={setters.setQta_2}
                              choice={prices.choice2}/>
                    <PriceRow price={prices.prezzo3}
                              qta={prices.qta_3}
                              setPrice={setters.setPrezzo3}
                              setQta={setters.setQta_3}
                              choice={prices.choice3}/>
                </div>}

                {articleColor &&
                <Row className="justify-end items-center mt-6 border-t-2 border-gray-100 py-2">
                    <Button>Conferma</Button>
                </Row>
                }
            </form>
        </div>
    );
};

export default SellForm;