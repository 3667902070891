import React, {useRef} from 'react';
import {CgChevronDownR, CgChevronRightR} from "react-icons/cg";
import {BsDot} from "react-icons/bs";
import MovementRow from "./MovementRow";
import {useTree} from "../../../utilities/contexts/tree-context";
import treeData from "../../../utilities/data/tree";
import {useLot} from "../../../utilities/contexts/lot-context";
import {doGetMovement} from "../../../logics/lots";
import useAsync from "../../../utilities/customHooks/async-call";

const NODE_HEIGHT = treeData.NODE_HEIGHT;

const Node = ({node, ...props}) => {
    const {updateNode} = useTree();
    const {currentMovement, setCurrentMovement, currentLot} = useLot();
    const {call} = useAsync();

    const componentRef = useRef();

    const toggleOpen = async () => {
        let newNode = {
            ...node,
            open: !node.open
        }
        await updateNode(newNode);
    }

    const returnChildren = () => {
        if (node.children && node.children.length > 0) {
            return node.children.map((_node, i) =>
                <Node node={_node}
                      key={`${node.idnodo}_${i}`}
                />);
        }
    }

    const handleRowClick = async () => {
        await call(
            () => doGetMovement(currentLot.nrlotto, node.idnodo),
            (res) => {
                if (res)
                    setCurrentMovement(res);
                // node && setCurrentMovement(node);
            }
        )

    }

    return (
        <div ref={componentRef}>
            <div
                className={`flex flex-row items-center gap-1 text-sm ${node && currentMovement && node.idnodo === currentMovement.idnodo ? "border border-black rounded font-bold" : "hover:bg-blue-200 border-transparent"}`}>
                <div style={{width: `${NODE_HEIGHT}px`, height: `${NODE_HEIGHT}px`}}
                     className="flex flex-row items-center justify-center">
                    {node && node.children && node.children.length > 0 ?
                        <div className="cursor-pointer hover:bg-secondary hover:bg-opacity-10" onClick={toggleOpen}>
                            {node && node.open
                                ? <CgChevronDownR className="text-gray-300" title="Nascondi"/>
                                : <CgChevronRightR className="text-secondary" title="Mostra"/>
                            }
                        </div>
                        :
                        <BsDot/>
                    }
                </div>
                <div className={`flex-1`}
                     style={{height: `${NODE_HEIGHT}px`}}
                     onClick={handleRowClick}>
                    <MovementRow node={node}/>
                </div>
            </div>

            {node && node.open &&
            <>
                <div className={`${node && node.open ? "block" : "hidden"}`}>
                    <div style={{paddingLeft: `${NODE_HEIGHT}px`}}>
                        {returnChildren()}
                    </div>
                </div>

            </>
            }


        </div>
    );
};

export default Node;
