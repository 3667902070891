import React from 'react';
import {parseDate} from "../../../utilities/data/parser";
import HeaderSection from "./UI/HeaderSection";
import Row from "../../UI/Structure/Row";
import HorizontalLabelledField from "../../UI/Fields/HorizontalLabelledField";
import RowsDisplay from "./Rows/RowsDisplay";

const LotHeaderDisplay = ({header, rows, showPrices = true,showComplete = true, showNotes = true, showRows = true, collapsable = false}) => {
    return (
        <div className="">
            {header.daticompleti === "0" && showComplete &&
            <div className="font-bold text-red-600 text-center text-lg">ATTENZIONE: DATI NON COMPLETI</div>
            }
            <HeaderSection label="Dettagli lotto" collapsable={collapsable} className="mb-1">
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                    <HorizontalLabelledField label="Nr Lotto" className="flex-1">{header.nrlotto}</HorizontalLabelledField>
                    <HorizontalLabelledField label="Eseguito da" className="flex-1">Utente</HorizontalLabelledField>
                    <HorizontalLabelledField label="Fornitore" className="flex-1">{header.supplier.name}</HorizontalLabelledField>
                    <HorizontalLabelledField label="Valuta" className="flex-1">{header.valuta}</HorizontalLabelledField>
                    <HorizontalLabelledField label="Documento in ingresso" className="col-span-2">
                        <Row>
                            <div>{header.document.type.nome ?? header.document.type.name} {header.nrdocfor} del {parseDate(header.datadocfor)}</div>
                        </Row>
                    </HorizontalLabelledField>
                    <HorizontalLabelledField label="Unità di misura superficie" className="flex-1">{header.um.name}</HorizontalLabelledField>
                    <HorizontalLabelledField label="Valore di cambio EUR-USD" className="flex-2">
                        <div>{header.eur_usd}</div>
                    </HorizontalLabelledField>
                </div>
            </HeaderSection>

            {showRows && rows &&
            <HeaderSection label="Righe" collapsable={collapsable} className="mb-1">
                <RowsDisplay rows={rows} />
            </HeaderSection>}

            {showNotes &&
            <HeaderSection label="Note">
                <div>{header.note ? header.note : <div className="text-gray-400">Nessuna nota inserita</div>}</div>
            </HeaderSection>
            }


            {showPrices &&
            <>
                <Row>
                    <HeaderSection label="Spese Dogana" className="flex-1">
                        <Row>
                            <HorizontalLabelledField label="Importo">{header.spesedogana}</HorizontalLabelledField>
                            <HorizontalLabelledField label="Riferimenti">{header.docspesedogana}</HorizontalLabelledField>
                        </Row>
                    </HeaderSection>

                    <HeaderSection label="Spese Dazio" className="flex-1">
                        <Row>
                            <HorizontalLabelledField label="Importo">{header.spesedazio}</HorizontalLabelledField>
                            <HorizontalLabelledField label="Riferimenti">{header.docspesedazio}</HorizontalLabelledField>
                        </Row>
                    </HeaderSection>
                </Row>

                <HeaderSection label="Vendita Croste">
                    <Row>
                        <HorizontalLabelledField label="Importo">{header.venditacroste}</HorizontalLabelledField>
                        <HorizontalLabelledField label="Data">{parseDate(header.datavenditacroste)}</HorizontalLabelledField>
                        <HorizontalLabelledField label="Riferimenti">{header.docvenditacroste}</HorizontalLabelledField>
                    </Row>
                </HeaderSection>
            </>
            }
        </div>
    );
};

export default LotHeaderDisplay;