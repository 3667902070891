import React from 'react';
import {useQueries} from "../../../utilities/contexts/queries-context";
import SalesByClientTable from "./SalesByClientTable";
import EmptyQuery from "../General/EmptyQuery";
import SalesByClientCrusts from "./SalesByClientCrusts";

const SalesByClientGroup = ({onApproval, crusts}) => {

    const {salesByClient} = useQueries();

    return (
        <div>
            {salesByClient && salesByClient.length > 0 ?
                <>
                    {
                        salesByClient.map((sales, i) =>
                            <SalesByClientTable sales={sales} key={i} onApproval={onApproval}/>
                        )
                    }
                    {crusts &&
                        <SalesByClientCrusts crusts={crusts} />

                    }
                </>
                :
                <EmptyQuery />
            }
        </div>
    );
};

export default SalesByClientGroup;