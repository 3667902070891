import React from 'react';
import TableRow from "../UI/table/TableRow";
import {doGetClient, doGetSubcontractor} from "../../logics/registry";
import {useRegistry} from "../../utilities/contexts/registry-context";
import useAsync from "../../utilities/customHooks/async-call";
import {FaCubes, FaTrash, FaTrashAlt} from "react-icons/fa";

const SubcontractorRow = ({subcontractor, ...props}) => {

    const {setCurrentSubcontractor, setRegistryPanelOpen} = useRegistry();
    const {call} = useAsync();

    const handleClick = async () => {
        await call(
            () => doGetSubcontractor(subcontractor.idterz),
            (res) => {
                if (res && res[0]) {
                    setCurrentSubcontractor(res[0]);
                    setRegistryPanelOpen(true);
                }
            }
        )
    }

    const handleOpenSubcontractorProcesses = () => {

    }

    return (
        <TableRow onClick={handleClick} className='text-sm mb-0.5'>
            <td className="pl-2 ">
                {subcontractor.ragsoctz}
            </td>
            <td>
                <div className="w-full flex flex-row items-center justify-center gap-2">
                    <FaTrashAlt/>
                    <FaCubes title="Lavorazioni fornitore"
                             onClick={handleOpenSubcontractorProcesses}
                             className="cursor-pointer hover:text-green-400" />
                </div>
            </td>
        </TableRow>
    )
}

export default SubcontractorRow;
