import React from 'react';

const TopDateTime = () => {

    function getTime(date) {

        let hours = date.getHours();
        if(hours < 10) hours = '0' + hours;
        let minutes = date.getMinutes();
        if(minutes < 10) minutes = '0' + minutes;

        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${hours}:${minutes}`;
    }

    return (
        <div className="text-sm">
            {getTime(new Date())}
        </div>
    );
};

export default TopDateTime;
