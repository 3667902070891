import React from 'react';
import treeData from "../../../utilities/data/tree";
import {useTree} from "../../../utilities/contexts/tree-context";
import {useLot} from "../../../utilities/contexts/lot-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {canContinue} from "../../../utilities/data/movement-util";
import {doGetBackloadData, doGetMoveData, doGetSellData} from "../../../logics/movements";
import Images from "../../../assets/images/Images";
import {
    FaArrowRight,
    FaCaretSquareLeft,
    FaCaretSquareRight,
    FaEdit,
    FaSearch,
    FaTag,
    FaWarehouse
} from "react-icons/fa";
import {ImCancelCircle} from "react-icons/im";
import {CgListTree, CgClipboard} from "react-icons/cg";
import {MdColorLens} from "react-icons/md";
import {doGetMovement} from "../../../logics/lots";

const IconBtn = ({icon, active = true, onClick, hoverClass = "hover:opacity-60", className, ...props}) => {

    const handleClick = () => active && onClick && onClick();

    return (
        <div className={`w-4 cursor-pointer ${active ? hoverClass : "opacity-20 "} ${className}`} {...props}
             onClick={handleClick}>
            {icon}
        </div>
    );
}

const MovementRow = ({node, ...props}) => {

    const {currentMovement, setCurrentMovement, openModal, currentLot, loadMovingData} = useLot();
    const {removeNode} = useTree();

    const {call} = useAsync();

    const handleAdd = async () => {
        await setCurrentMovement(node);

        await call(
            () => doGetMoveData(currentLot.nrlotto, node.idnodo),
            (res) => {
                loadMovingData(res);
                openModal('add', true);
            }
        );
    }

    const handleDelete = async () => {
        setCurrentMovement(node);
        openModal('delete', true);
    }

    const handleBackload = async () => {
        await setCurrentMovement(node);

        await call(
            () => doGetBackloadData(currentLot.nrlotto, node.idnodo),
            (res) => {
                loadMovingData(res);
                openModal('backload', true);
            }
        )


    }

    const handleSell = async () => {
        await setCurrentMovement(node);

        await call(
            () => doGetSellData(currentLot.nrlotto, node.idnodo),
            (res) => {
                loadMovingData(res);
                openModal('sell', true);
            }
        )
    }

    const handleUnsell = async () => {
        await setCurrentMovement(node);
        openModal('unsell', true);
    }

    const handleTag = async () => {
        await setCurrentMovement(node);
        openModal('tag', true);
    }

    const handleView = async () => {
        await call(
            () => doGetMovement(currentLot.nrlotto, node.idnodo),
            (res) => {
                if (res)
                    setCurrentMovement(res);
            }
        )
        openModal('view', true);
    }

    const handleEdit = async () => {
        await call(
            () => doGetMovement(currentLot.nrlotto, node.idnodo),
            (res) => {
                if (res)
                    setCurrentMovement(res);
                // node && setCurrentMovement(node);
            }
        )
        openModal('edit', true);
    }

    // determinazione span style per la rappresentazione del testo
    // nero: movimento concluso
    // blu: movimento parzialmente concluso
    // rosso: le pelli sono in questa sede
    // verde: pelli vendute
    // // bold magenta: vendita
    //     $font_span= numero_figli($IdNodo)==0 ? "color: red;" : "color: black;";
    //     if (numero_figli($ArrayPadre[IdNodo])>0 && $pelli_ordinabili!=0) {
    //         $font_span="color: blue;";
    //     }
    //     if ($Ritorno==1 && numero_figli($IdNodo)==0) $font_span="color: green; ";
    //     if ($Vendita==1) $font_span.="font-weight: bold; ";
    //     if ($Venduto==1) $font_span.="color: magenta; font-weight: bold; ";

    const getTextDetails = () => {
        let style = "";

        if (node && currentMovement && node.idnodo === currentMovement.idnodo)
            style += " "

        if (node.sale ?? node.vendita)
            style += "font-bold ";

        if(!node.children.length > 0 && node.subcontractor?.id === 28)
            return style + " text-green-500 ";
        // if (node.ritorno && !node.children.length > 0)
        //     return style + " text-green-500 ";
        if (node.sold ?? node.venduto)
            return style + " text-fuchsia-600 font-bold ";
        if (node.reso)
            return style + " text-red-500 "
        if (node.children.length > 0 && node.available_leathers > 0)
            return style + " text-blue-500 ";

        return style;
    }

    const cutLongName = (name) => {
        if(name) {
            let _name = name;
            if(_name.length > 30) return _name.substring(0,30) + "...";
            else return _name;
        } else {
            return "";
        }
    }

    return (
        <div className="flex flex-row justify-between items-center cursor-pointer ">
            <div className={`flex flex-row flex-wrap items-center gap-2 overflow-hidden flex-1 ${getTextDetails()}`}
                 style={{height: treeData.NODE_HEIGHT}}>

                <span className="font-medium">
                    {node.chiuso ? "*" : ""}
                    {node.nrpz}{node.children.length > 0 && node.available_leathers > 0 ? ` (${node.available_leathers})` : ""}
                    <span className="text-gray-400 font-normal">pz</span>
                </span>

                {node?.processing?.name &&
                <span>{cutLongName(node?.processing?.name)}</span>
                }
                {node?.article?.name &&
                <div className="flex flex-row items-center">
                    <FaTag className="text-gray-500 font-normal mr-0.5"/>
                    <span>{cutLongName(node.article.name)}</span>
                </div>
                }
                {node?.color?.name &&
                <div className="flex flex-row items-center">
                    <MdColorLens className="text-gray-500 font-normal"/>
                    <span>{cutLongName(node.color.name)}</span>
                </div>
                }
                <div
                    className={`flex flex-row items-center gap-1 px-1 ${(!node.children || node.children.length === 0) || (node.children.length > 0 && node.available_leathers > 0) ? "bg-yellow-400 bg-opacity-50" : ""}`}>
                    {node.subcontractor && node.subcontractor.id === 28 ?
                        <FaWarehouse className="text-secondary font-normal" />
                        :
                        <FaArrowRight className="text-gray-500 font-normal"/>
                    }

                    <span>{node?.subcontractor?.name ?? node?.client?.name}</span>
                </div>


            </div>

            <div onClick={(e) => e.stopPropagation()} className="flex flex-row gap-0.5">
                <IconBtn icon={<FaSearch/>}
                         onClick={handleView}
                         title="Visualizza"/>
                <IconBtn icon={<CgClipboard/>}
                         onClick={handleTag}
                         title={`Cartellino M-${node.idnodo}`}/>
                <IconBtn onClick={handleAdd}
                         title="Prosegui movimento"
                         icon={<CgListTree/>}
                         active={!node.venduto && canContinue(node) > 0}
                         hoverClass="hover:text-green-400"/>
                <IconBtn title="Modifica"
                         onClick={handleEdit}
                         icon={<FaEdit/>}/>
                <IconBtn onClick={handleDelete}
                         title="Cancella"
                         icon={<ImCancelCircle/>}
                         active={!node.children || node.children.length === 0}
                         hoverClass="hover:text-red-400"
                         className="text-red-600"/>
                <IconBtn title="Ritorno a Zetapelli"
                         icon={<img src={Images.logo} alt="logo" style={{width: "12px", height: "12px"}}/>}
                         onClick={handleBackload}
                         active={!node.venduto && canContinue(node) > 0 && !node.ritorno}/>
                <IconBtn icon={<FaCaretSquareRight/>}
                         onClick={handleSell}
                         className="text-green-500"
                         active={(!node.children || node.children.length === 0) && !node.venduto}
                         title="Vendita"/>
                <IconBtn title="Effettua reso"
                         icon={<FaCaretSquareLeft/>}
                         onClick={handleUnsell}
                         className="text-green-500"
                         active={node.venduto}
                         hoverClass=" hover:text-secondary"/>
            </div>
        </div>
    );
};

export default MovementRow;