import React from 'react';
import {useTree} from "../../utilities/contexts/tree-context";
import treeData from "../../utilities/data/tree";

const NODE_HEIGHT = treeData.NODE_HEIGHT;

const TreeNode = ({node, father, fatherEnd = 0,...props}) => {

    const buildLine = () => {
        let end = getEnd(father, node.index);

        let start = end - 10;
        let depth = (node.depth - 1) * NODE_HEIGHT + 8;

        if(node.index > 0) {
            let sibling = father.children[node.index - 1];
            start -= sibling.height - NODE_HEIGHT;
        }

        let points = `${depth},${start} ${depth},${end} ${depth + 8},${end}`;

        return <polyline points={points}
                         style={{fill: "transparent", stroke: "#d1d5db", strokeWidth: 1.5}}/>
    }

    const getEnd = (father, index) => {
        if(father) {
            let height = NODE_HEIGHT + fatherEnd;
            if(index > 0) {
                for(let i = index - 1; i >= 0; i--) {
                    if(father.children[i]) {
                        height += father.children[i].height;
                    }
                }
            }
            return height;
        } else return 10;
    }

    return (
        <>
            {father && father.open &&
                buildLine()
            }
            {node.open && node.children && node.children.length > 0 &&
                node.children.map((child) =>
                    <TreeNode node={child}
                              father={node}
                              fatherEnd={getEnd(father, node.index)}
                              key={`node_${node.idnodo}_${child.index}_${child.depth}`} />)
            }
        </>
    )
}

const Arrows = () => {

    const {tree} = useTree();

    return (
        <>
            {tree &&
                <svg height={tree.height + NODE_HEIGHT} width={"100%"} className={`absolute top-0 left-0 -z-10 ${tree.open ? "block" : "hidden"}`}>
                    <TreeNode node={tree} />
                </svg>
            }
        </>
    );
};

export default Arrows;