import React from 'react';
import {useLot} from "../../../utilities/contexts/lot-context";
import Modal from "../../UI/Modal";
import Button from "../../UI/Button";
import {useTree} from "../../../utilities/contexts/tree-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {doDeleteMovement} from "../../../logics/movements";
import {doGetLotMovements} from "../../../logics/lots";
import useToast from "../../../utilities/customHooks/toast";
import HorizontalLabelledField from "../../UI/Fields/HorizontalLabelledField";
import FormTitle from "./Creation/UI/FormTitle";

const MovementDeletion = () => {

    const {currentMovement, loadMovements, modal, openModal, currentLot} = useLot();
    const {removeNode} = useTree();
    const {call} = useAsync();
    const {toastError} = useToast();

    const handleDelete = async () => {
        await call(() => doDeleteMovement(currentLot.nrlotto, currentMovement.idnodo),
            async (res) => {
                if (res) {
                    await call(
                        () => doGetLotMovements(currentLot.nrlotto),
                        (res) => {
                            if (res) {
                                loadMovements(res[0]);
                                openModal('add', false);
                            } else {
                                loadMovements(null);
                                toastError("Errore caricamento lotti");
                            }
                        },
                        (err) => {
                            loadMovements(null);
                            toastError("Errore caricamento lotti")
                        }
                    );
                    openModal('delete', false);
                }

            },
            (err) => {
                toastError("Errore cancellazione lotto");
            }
        )
    }

    return (
        <Modal open={modal.delete}
               onOverlayClick={() => {
                   openModal('delete', false)
               }}>
            <div>
                <FormTitle title={currentMovement?.idnodo}>Cancella movimento</FormTitle>

                <HorizontalLabelledField
                    label="Lavorazione">{currentMovement && currentMovement?.processing?.name}</HorizontalLabelledField>
                <HorizontalLabelledField
                    label="Pezzi">{currentMovement && currentMovement?.nrpz}</HorizontalLabelledField>
                <HorizontalLabelledField
                    label="Articolo">{currentMovement && currentMovement?.article?.name}</HorizontalLabelledField>
                <HorizontalLabelledField
                    label="Colore">{currentMovement && currentMovement?.color?.name}</HorizontalLabelledField>

                <div className="flex flex-row items-center justify-between gap-2 mt-4">
                    <Button onClick={handleDelete}>Conferma cancellazione movimento</Button>
                    <Button onClick={() => openModal('delete', false)}>Annulla</Button>
                </div>
            </div>

        </Modal>
    );
};

export default MovementDeletion;