import React from 'react';
import Layout from "../components/UI/Layout";
import {Outlet} from 'react-router-dom';

const Lots = () => {
    return (
        <Layout title={'Lotti'}>
            <Outlet/>
        </Layout>
    );
};

export default Lots;
