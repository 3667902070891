import React, {useState} from 'react';
import {useLot} from "../../../utilities/contexts/lot-context";
import {AiFillCloseCircle} from "react-icons/ai";
import {MdOutlineDoneOutline} from "react-icons/md";
import {FaEdit} from "react-icons/fa";
import {BiFoodTag} from "react-icons/bi";
import Modal from "../../UI/Modal";
import EditMovementForm from "./EditMovementForm";
import Row from "../../UI/Structure/Row";
import {formatPrice, parseDate, parseFloat5} from "../../../utilities/data/parser";
import Section from "../../UI/Structure/Section";
import {doPutLotMovement} from "../../../logics/lots";
import useAsync from "../../../utilities/customHooks/async-call";
import useToast from "../../../utilities/customHooks/toast";
import Toggle from "../../UI/Toggle";
import HorizontalLabelledField from "../../UI/Fields/HorizontalLabelledField";
import DocumentInfo from "../../UI/DataDisplay/DocumentInfo";

const LabelledDone = ({label, value}) => {
    return (
        <div className="flex flex-col items-center text-center leading-none">
            <div className="">{label}</div>
            <MdOutlineDoneOutline className={value ? "text-green-400" : "text-red-400"}/>
        </div>
    )
}

const MovementPanel = () => {

    const {currentMovement, setCurrentMovement, currentLot, openModal} = useLot();

    const [showModal, setShowModal] = useState(false);
    const [displayTags, setDisplayTags] = useState(false);
    const [openDetails, setOpenDetails] = useState(true);

    const {call} = useAsync();
    const {toastConfirm} = useToast();

    return (
        <div>
            <Row className={`justify-between bg-primary bg-opacity-30 items-center px-1 cursor-pointer border-primary ${!openDetails && ''}`} onClick={() => setOpenDetails(!openDetails)}>
                <div className="uppercase text-black font-medium text-sm leading-2">Dettagli movimento</div>
                <Row className="justify-end gap-1" onClick={(e) => e.stopPropagation()}>
                    <FaEdit className="cursor-pointer hover:text-secondary"
                            title="Modifica movimento"
                            onClick={() => openModal('edit',true)}/>
                    <BiFoodTag className="cursor-pointer hover:text-secondary" title="Mostra tags"
                               onClick={() => setDisplayTags(!displayTags)}/>
                    <AiFillCloseCircle
                        className="cursor-pointer hover:text-red-400"
                        title="Chiudi dettaglio"
                        onClick={() => setCurrentMovement(null)}/>
                    <Toggle open={openDetails} onClick={() => setOpenDetails(!openDetails)} />
                </Row>
            </Row>

            {openDetails &&
            <>
                <div className="p-1 bg-primary bg-opacity-10 border-primary border-t-0 mb-1 relative">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 items-start gap-x-2 gap-y-2 mb-2">
                        <HorizontalLabelledField className="flex-1"
                               label="Articolo">
                            {currentMovement.article.name}
                        </HorizontalLabelledField>
                        <HorizontalLabelledField className="flex-1"
                               label="Pezzi">
                            {currentMovement.nrpz}
                        </HorizontalLabelledField>
                        <HorizontalLabelledField className="flex-1"
                               label="Colore">
                            {currentMovement.color.name}
                        </HorizontalLabelledField>
                        <div className="flex-1">
                            {currentMovement.sold ?? currentMovement.venduto ?
                                <HorizontalLabelledField className="flex-1"
                                       label="Cliente">
                                    {currentMovement?.client?.name}
                                </HorizontalLabelledField>
                                : <HorizontalLabelledField className="flex-1"
                                         label="Terzista">
                                    {currentMovement?.subcontractor?.name}
                                </HorizontalLabelledField>
                            }
                        </div>
                        <HorizontalLabelledField className="flex-1 col-span-2"
                               label="Documento in ingresso">
                            <DocumentInfo document={currentMovement?.document} />
                        </HorizontalLabelledField>

                    </div>
                    <HorizontalLabelledField label="Lavorazione" className="mb-2">
                        {currentMovement?.processing?.name}
                    </HorizontalLabelledField>
                    <HorizontalLabelledField label="Note">{currentMovement.document.note}</HorizontalLabelledField>
                    <div className="">
                        {/* Document */}
                        {currentMovement.document &&
                        <div>
                            {/*<HorizontalLabelledField label="Tipo">{currentMovement.document.type.load ? "Carico" : "Scarico"}</HorizontalLabelledField>*/}
                            {/*<HorizontalLabelledField label="Registrazione">{currentMovement.document.registered_at}</HorizontalLabelledField>*/}
                            {displayTags &&
                            <div className="flex flex-row flex-wrap gap-1">
                                <LabelledDone label={'open'} value={currentMovement.open ?? currentMovement.aperto}/>
                                <LabelledDone label={'back'} value={currentMovement.back ?? currentMovement.uscito}/>
                                <LabelledDone label={'closed'}
                                              value={currentMovement.closed ?? currentMovement.chiuso}/>
                                <LabelledDone label={'printed'}
                                              value={currentMovement.printed ?? currentMovement.stampato}/>
                                <LabelledDone label={'return'}
                                              value={currentMovement.return ?? currentMovement.ritorno}/>
                                <LabelledDone label={'sale'} value={currentMovement.sale ?? currentMovement.vendita}/>
                                <LabelledDone label={'sold'} value={currentMovement.sold ?? currentMovement.venduto}/>
                            </div>
                            }
                        </div>
                        }

                        {currentMovement.closed || currentMovement.chiuso &&
                        <HorizontalLabelledField label="Note chiusura">{currentMovement.closed_notes}</HorizontalLabelledField>
                        }
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-start gap-x-2">
                    <Section label="Costi" className="flex-1 w-full">
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                            <HorizontalLabelledField
                                title={currentMovement?.costs?.movement}
                                className="w-full justify-between items-start "
                                label="Totale">{formatPrice(currentMovement?.costs?.movement)}{currentLot.valuta === "EUR" ? "€" : "$"}</HorizontalLabelledField>
                            <HorizontalLabelledField
                                title={currentMovement?.costs?.per_leather}
                                className="w-full justify-between items-start "
                                label="Per pelle">{formatPrice(currentMovement?.costs?.per_leather)}{currentLot.valuta === "EUR" ? "€" : "$"}</HorizontalLabelledField>
                            <HorizontalLabelledField
                                title={currentMovement?.costs?.progressive_per_leather}
                                className="w-full justify-between items-start "
                                label="Parziale al pezzo">{formatPrice(currentMovement?.costs?.progressive_per_leather)}{currentLot.valuta === "EUR" ? "€" : "$"}</HorizontalLabelledField>
                        </div>
                    </Section>
                    <Section label="Ricavi" className="flex-1 w-full ">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-2 gap-y-1">
                            <HorizontalLabelledField label="Ricavo totale" className="w-full justify-between items-start ">
                                {formatPrice(currentMovement?.revenues?.profit)}{currentLot.valuta === "EUR" ? "€" : "$"}
                            </HorizontalLabelledField>
                            <HorizontalLabelledField label="Costo totale di vendita" className="w-full justify-between items-start ">
                                {formatPrice(currentMovement?.revenues?.total_costs)}{currentLot.valuta === "EUR" ? "€" : "$"}
                            </HorizontalLabelledField>
                            <HorizontalLabelledField label="Margine" className="w-full justify-between items-start ">
                                {currentMovement?.revenues?.margin_percentage}%
                            </HorizontalLabelledField>

                            <HorizontalLabelledField label="Al piede" className="w-full justify-between items-start ">
                                {formatPrice(currentMovement?.revenues?.cost_per_foot)}{currentLot.valuta === "EUR" ? "€" : "$"}
                            </HorizontalLabelledField>
                            <HorizontalLabelledField label="Al metro" className="w-full justify-between items-start ">
                                {formatPrice(currentMovement?.revenues?.cost_per_mt)}{currentLot.valuta === "EUR" ? "€" : "$"}
                            </HorizontalLabelledField>
                            <HorizontalLabelledField label="Profitto al piede" className="w-full justify-between items-start ">
                                {formatPrice(currentMovement?.revenues?.profit_per_foot)}{currentLot.valuta === "EUR" ? "€" : "$"}
                            </HorizontalLabelledField>
                            <HorizontalLabelledField label="Profitto per metro" className="w-full justify-between items-start ">
                                {formatPrice(currentMovement?.revenues?.profit_per_mt)}{currentLot.valuta === "EUR" ? "€" : "$"}
                            </HorizontalLabelledField>
                        </div>

                    </Section>
                </div>
            </>
            }

        </div>

    );
};

export default MovementPanel;