import React from 'react';
import VerticalLabelledField from "../../../../UI/Fields/VerticalLabelledField";
import Input from "../../../../UI/Input";
import Row from "../../../../UI/Structure/Row";

const AvailableLeathersInput = ({available, value, setValue}) => {
    return (
        <Row className="items-start gap-2 mb-3">
            <VerticalLabelledField label="Nr. Pelli disponibili">
                <div>{available}</div>
            </VerticalLabelledField>
            <VerticalLabelledField label="Nr. pelli da movimentare">
                <Input type="number"
                       value={value}
                       onChange={(e) => setValue(e.target.value)}
                       placeholder="Numero" required={value <= 0}/>
            </VerticalLabelledField>
        </Row>
    );
};

export default AvailableLeathersInput;