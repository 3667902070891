import axios from 'axios';

const ENDPOINT = process.env.REACT_APP_ENDPOINT;

function gettingToken() {
    return sessionStorage.getItem('session_token');
}

function getInstance() {
    let token = gettingToken();
    let instance = axios.create({
        headers: {
            'X-AUTH-TOKEN': token,
        }
    });
    return instance;
}

async function get(path, params) {
    return await getInstance().get(ENDPOINT + path, {params});
}

async function getUrl(fullpath) {
    return await getInstance().get(fullpath);
}

async function post(path, params) {
    return await getInstance().post(ENDPOINT + path, params);
}

async function put(path, params) {
    return await getInstance().put(ENDPOINT + path, params);
}

async function DELETE(path) {
    return await getInstance().delete(ENDPOINT + path);
}

const API = {
    get,
    getUrl,
    post,
    put,
    DELETE,
}

export default API;