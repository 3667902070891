import React, {useState} from 'react';
import Button from "../../UI/Button";
import FirstMovementCreation from "./Creation/FirstMovementCreation";

const FirstMovement = () => {

    const [open, setOpen] = useState(false);

    return (
        <div>
            <div>Nessun movimento disponibile</div>
            <Button onClick={() => setOpen(true)}>Crea il primo movimento</Button>


            <FirstMovementCreation open={open} onOverlayClick={() => setOpen(false)} />
        </div>
    );
};

export default FirstMovement;