import React from 'react';
import TableRow from "../UI/table/TableRow";
import useAsync from "../../utilities/customHooks/async-call";
import {doGetClient} from "../../logics/registry";
import {FaEdit, FaTag, FaTrashAlt} from "react-icons/fa";
import {useRegistry} from "../../utilities/contexts/registry-context";

const ClientRow = ({client, ...props}) => {

    const {call} = useAsync();
    const {setCurrentClient, setRegistryPanelOpen} = useRegistry();

    const handleClick = async () => {
        await call(
            () => doGetClient(client.idcl),
            (res) => {
                if (res && res[0]) {
                    setCurrentClient(res[0]);
                    setRegistryPanelOpen(true);
                }
            }
        )
    }

    const openClientArticles = () => {}

    return (
        <TableRow onClick={handleClick} className='text-sm mb-0.5'>
            <td className="pl-2 ">
                <div>{client && client.ragsoccl ? client.ragsoccl : ""}</div>
            </td>
            <td className="pl-2 ">{client && client.email ? client.email : ""}</td>
            <td>
                <div className="w-full flex flex-row items-center justify-center gap-2">
                    <FaTrashAlt/>
                    <FaTag title="Listino Articoli"
                           onClick={openClientArticles}
                           className="cursor-pointer hover:text-green-400"/>
                </div>
            </td>
        </TableRow>
    )
}

export default ClientRow;
