import React from 'react';
import {useRegistry} from "../../utilities/contexts/registry-context";
import useAsync from "../../utilities/customHooks/async-call";
import {doPostSupplier} from "../../logics/registry";
import BaseInput from "../UI/BaseInput";
import Button from "../UI/Button";

const NewSupplierForm = ({...props}) => {
    const {suppliers, loadSuppliers, setCreatePanelOpen} = useRegistry();
    const {call} = useAsync();

    const handleSubmit = async (e) => {
        e.preventDefault();

        let params = new URLSearchParams();
        params.append('ragsocf', e.target.name.value);
        params.append('contatto', e.target.contatto.value);
        params.append('email', e.target.email.value);
        params.append('indf', e.target.ind.value);
        params.append('pivaf', e.target.piva.value);
        params.append('note', e.target.note.value);
        params.append('valuta', e.target.valuta.value);

        await call(
            () => doPostSupplier(params),
            (res) => {
                if(res) {
                    loadSuppliers([res, ...suppliers])
                }
                setCreatePanelOpen(false);
            },
        )

    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col" style={{minWidth: "400px"}}>
            <BaseInput name={'name'}
                       className="mb-1"
                       label={'Ragione sociale'}
                       required
                       placeholder={'Ragione Sociale'}/>

            <BaseInput name={'contatto'}
                       className="mb-1"
                       label="contatto"
                       placeholder={'Contatto'}/>
            <BaseInput name={'email'}
                       className="mb-1"
                       label="email"
                       placeholder={'Email'}/>

            <BaseInput name={'ind'}
                       className="mb-1"
                       label="indirizzo"
                       placeholder={'Indirizzo'}/>
            <BaseInput name={'piva'}
                       className="mb-1"
                       label="PIVA"
                       placeholder={'PIVA'}/>

            <BaseInput name={'note'}
                       className="mb-1"
                       label="note"
                       placeholder={'Note'}/>

            <BaseInput name={'valuta'}
                       className="mb-8"
                       label="valuta"
                       placeholder={'Valuta'}/>

            <Button>Conferma</Button>
        </form>
    );
}

export default NewSupplierForm;
