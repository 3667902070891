import React from 'react';
import TableHeader from "../General/TableHeader";
import {formatPrice, parseDate} from "../../../utilities/data/parser";
import Table from "../General/Table";

const SalesByClientCrusts = ({crusts}) => {
    return (
        <Table>
            <TableHeader tr={
                <tr className="border-t border-b border-black bg-secondary bg-opacity-40">
                    <th colSpan={7} className="text-center">VENDITA CROSTE</th>
                </tr>
            }>
                <th>Lotto</th>
                <th>Documento</th>
                <th>Fornitore</th>
                <th>Importo</th>
                <th>Data</th>
            </TableHeader>
            <tbody>
            {crusts.sold && crusts.sold.length > 0 &&
            crusts.sold.map((row, i) =>
                <tr key={i}>
                    <td>{row.nrlotto}</td>
                    <td>{row.doc}</td>
                    <td>{row?.supplier?.name}</td>
                    <td>{formatPrice(row.amount)}</td>
                    <td>{parseDate(row.date)}</td>
                </tr>
            )
            }
            </tbody>
            <tfoot>
            <tr className="border-t border-b border-black">
                <td colSpan={5} className="text-center">
                    Totali: {formatPrice(crusts?.totals?.amounts)}
                </td>
            </tr>
            </tfoot>
        </Table>
    );
};

export default SalesByClientCrusts;