import PriceRow from "../../components/Lots/Movements/Creation/UI/PriceRow";
import React, {useState} from "react";


function usePrices() {
    const [prezzo1, setPrezzo1] = useState(null);
    const [um_1, setUm_1] = useState(null);
    const [qta_1, setQta_1] = useState(null);
    const [choice1, setChoice1] = useState(null);
    const [prezzo2, setPrezzo2] = useState(null);
    const [um_2, setUm_2] = useState(null);
    const [qta_2, setQta_2] = useState(null);
    const [choice2, setChoice2] = useState(null);
    const [prezzo3, setPrezzo3] = useState(null);
    const [um_3, setUm_3] = useState(null);
    const [qta_3, setQta_3] = useState(null);
    const [choice3, setChoice3] = useState(null);

    const prices = {
        prezzo1, um_1, qta_1, choice1,
        prezzo2, um_2, qta_2, choice2,
        prezzo3, um_3, qta_3, choice3,
    }

    const setters = {
        setPrezzo1, setUm_1, setQta_1, setChoice1,
        setPrezzo2, setUm_2, setQta_2, setChoice2,
        setPrezzo3, setUm_3, setQta_3, setChoice3,
    }

    const resetPrices = () => {
        setPrezzo1(null);
        setUm_1(null);
        setQta_1(null);
        setChoice1(null);
        setPrezzo2(null);
        setUm_2(null);
        setQta_2(null);
        setChoice2(null);
        setPrezzo3(null);
        setUm_3(null);
        setQta_3(null);
        setChoice3(null);
    }

    const initializePrice = (price, setPrice, um, setUm, setQta, choice, setChoice) => {
        if (price !== null) {
            setPrice(price);
            if (um) setUm(um);
            if(choice) setChoice(choice);
            setQta(0);
        } else {
            setPrice(null);
            setUm(null);
            setQta(null);
        }
    }

    const initializePrices = (costs) => {
        resetPrices();

        initializePrice(costs.prezzo1, setters.setPrezzo1, costs?.um_1, setters.setUm_1, setters.setQta_1, costs?.scelta1, setters.setChoice1);
        initializePrice(costs.prezzo2, setters.setPrezzo2, costs?.um_2, setters.setUm_2, setters.setQta_2, costs?.scelta2, setters.setChoice2);
        initializePrice(costs.prezzo3, setters.setPrezzo3, costs?.um_3, setters.setUm_3, setters.setQta_3, costs?.scelta3, setters.setChoice3);
    }

    const PriceRows = () => {
        return (
            <div className="mb-2">
                <div className="text-md uppercase font-medium text-gray-500 leading-none">Costi movimento</div>
                <PriceRow price={prezzo1} um={um_1?.name} qta={qta_1} setPrice={setPrezzo1} setQta={setQta_1}/>
                <PriceRow price={prezzo2} um={um_2?.name} qta={qta_2} setPrice={setPrezzo2} setQta={setQta_2}/>
                <PriceRow price={prezzo3} um={um_3?.name} qta={qta_3} setPrice={setPrezzo3} setQta={setQta_3}/>
            </div>
        );
    }

    return {
        prices,
        initializePrices,
        PriceRows,
        setters,
        resetPrices,
    }
}

export default usePrices;