import React, {useEffect, useState} from 'react';
import {useRegistry} from "../../../utilities/contexts/registry-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {doGetSubcontractors} from "../../../logics/registry";
import parseOptions from "../../../utilities/data/parseOptions";
import SearchSelect from "./SearchSelect";

const SelectSubcontractor = ({value, onChange, ...props}) => {
    const {subcontractors, loadSubcontractors} = useRegistry();
    const {call} = useAsync();

    const [formattedValue, setFormattedValue] = useState();

    const getOptions = () => {

        return subcontractors.length > 0
            ? subcontractors.map(subcontractor => parseOptions.parseSubcontractor(subcontractor))
            :[];
    }

    const handleChange = (val) => {
        onChange && onChange(val);
    }

    useEffect(() => {
        const load = async () => {
            if(!subcontractors || !subcontractors.length > 0) {
                await call(
                    () => doGetSubcontractors(),
                    (res) => {
                        loadSubcontractors(res);
                    }
                )
            }
        }
        load();
    }, []);

    useEffect(() => {
        const loadObject = async () => {
            if(value && !value.id) {
                let filteredItem = subcontractors.filter(sub => sub.idterz === value);
                if(filteredItem && filteredItem.length > 0) {
                    let item = {id: value, name: filteredItem[0].ragsoctz}
                    setFormattedValue(item);
                }
            } else {
                setFormattedValue(parseOptions.parseSubcontractor(value));
            }
        }
        loadObject();
    }, [value]);

    return (
        <SearchSelect options={getOptions()} value={formattedValue} onChange={handleChange} {...props}/>
    );
};

export default SelectSubcontractor;