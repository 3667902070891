import React from 'react';
import {Link} from 'react-router-dom';

const LotLink = ({nrlotto, movements = true}) => {
    return (
        <Link to={`/lotti/${nrlotto}${movements ? "/movimenti" : ""}`} title={`Vai al lotto ${nrlotto}`}>
            <span className="cursor-pointer hover:underline hover:text-blue-400">
            {nrlotto}
            </span>
        </Link>
    );
};

export default LotLink;
