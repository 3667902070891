import React from 'react';
import {FaTrashAlt} from "react-icons/fa";

const Row = ({row, index, onClick, onDelete,...props}) => {

    const handleClick = () => {
        onClick && onClick(row);
    }

    const handleDelete = (e) => {
        e.stopPropagation();
        onDelete && onDelete(row);
    }

    return (
        <tr {...props} onClick={handleClick} className="odd:bg-primary odd:bg-opacity-10 hover:bg-primary hover:bg-opacity-50 cursor-pointer">
            <td className="pl-2">{row.article.name}</td>
            <td>{row.color.name}</td>
            <td>{row.choice.name}</td>
            <td className="text-right">{row.qta}</td>
            <td className="text-right">{row.nrpz}</td>
            <td className="text-right">{row.prezzo}</td>
            <td><div title="Cancella riga"
                className="flex flex-row justify-center hover:text-red-600 cursor-pointer"
                     onClick={handleDelete}><FaTrashAlt /></div></td>
        </tr>
    );
};

export default Row;