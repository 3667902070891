import React from 'react';
import {BiPlus} from "react-icons/bi";
import SupplierRow from "./SupplierRow";
import {useRegistry} from "../../utilities/contexts/registry-context";

const SuppliersTable = ({...props}) => {

    const {suppliers} = props;
    const {setCreatePanelOpen} = useRegistry();

    const handleCreate = () => {
        setCreatePanelOpen(true);
    }

    return (
        <table className="w-full max-h-screen text-left">
            <thead>
            <tr className="border-b border-gray-600">
                <th className="pl-2 ">Ragione sociale</th>
                <th>
                    <div className="flex flex-row justify-center items-center w-full">
                        <BiPlus onClick={handleCreate}
                                title={'Nuovo fornitore'}
                                className="cursor-pointer hover:text-green-400"/>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody className="max-h-screen-50 overflow-y-auto w-full ">
            {suppliers && suppliers.length > 0 &&
            suppliers.map((supplier, i) =>
                <SupplierRow supplier={supplier} key={i}/>
            )

            }
            </tbody>
        </table>
    )
}

export default SuppliersTable;
