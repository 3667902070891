import React from 'react';
import LotLink from "../../Lots/LotLink";
import DocumentInfo from "../../UI/DataDisplay/DocumentInfo";
import {formatInt, formatPrice, parseDate} from "../../../utilities/data/parser";
import {useQueries} from "../../../utilities/contexts/queries-context";
import Table from "../General/Printable/Table";
import TableHead from "../General/Printable/TableHead";
import Th from "../General/Printable/Th";
import Td from "../General/Printable/Td";

const InHousePrintable = ({displayCosts}) => {

    const {inHouseRows, inHouseTotals} = useQueries();

    return (
        <div>
            <div className="flex flex-col items-center">
                <div>{parseDate(new Date().toISOString())}</div>
                <div>Pelli in sede</div>
            </div>

            <Table>
                <TableHead>
                    <Th className="text-center">Cartellino</Th>
                    <Th className="text-center">Lotto</Th>
                    <Th className="text-left pl-2">Documento e Data</Th>
                    <Th className="text-left pl-2">Articolo - Colore</Th>
                    <Th>Nr.pelli ferme</Th>
                    {displayCosts &&
                    <>
                        <Th>Costo al pezzo</Th>
                        <Th>Valore</Th>
                    </>
                    }
                </TableHead>
                <tbody>
                {inHouseRows && inHouseRows.map((row, i) =>
                    <tr key={i} className="border border-black">
                        <Td className="text-center">MAG-{row.id}</Td>
                        <Td className="text-center"><LotLink nrlotto={row.nrlotto}/></Td>
                        <Td className="pl-2"><DocumentInfo document={row.document}/></Td>
                        <Td className="pl-2">{row.article.name} - {row.color.name}</Td>
                        <Td className="text-right">{row.available_leathers}</Td>
                        {displayCosts &&
                        <>
                            <Td className="text-right">{formatPrice(row.costs.per_leather)}€</Td>
                            <Td className="text-right">{formatPrice(row.costs.progressive)}€</Td>
                        </>
                        }
                    </tr>
                )}
                {inHouseTotals &&
                <tr className="border border-black border-t-2 text-right">
                    <Td colSpan={4}>Totale:</Td>
                    <Td>{formatInt(inHouseTotals.nrpz)}</Td>
                    {displayCosts &&
                    <>
                        <Td/>
                        <Td>{formatPrice(inHouseTotals.costs)}€</Td>
                    </>
                    }
                </tr>
                }

                </tbody>

            </Table>
        </div>
    );
};

export default InHousePrintable;