import React from 'react';

const RowAligned = ({className = "gap-1",...props}) => {
    return (
        <div className={`flex flex-row items-center ${className}`} {...props}>
            {props.children}
        </div>
    );
}

export default RowAligned;
