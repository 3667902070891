import React, {useState} from 'react';
import Section from "../../UI/Structure/Section";
import Row from "../../UI/Structure/Row";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import BaseInput from "../../UI/BaseInput";
import Button from "../../UI/Button";
import LotCategorySelect from "../General/LotCategorySelect";
import SelectSubcontractor from "../../UI/Selects/SelectSubcontractor";

const Search = ({onSubmit}) => {

    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [nrlotto, setNrLotto] = useState("");
    const [showCosts, setShowCosts] = useState(false);
    const [lotCategory, setLotCategory] = useState("");
    const [subcontractor, setSubcontractor] = useState(null);

    const handleLotCategoryChange = (e) => {
        setLotCategory(e.target.value);
        setNrLotto(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(subcontractor);
        onSubmit && onSubmit({nrlotto, date, showCosts, subcontractor});
    }

    return (
        <Section collapsable={true} label="Ricerca pelli in sede terzisti">
            <form onSubmit={handleSubmit} className="flex flex-row items-center justify-between">
                <Row className="gap-4">
                    <VerticalLabelledField label="Data">
                        <BaseInput type="date" value={date} onChange={(e) => setDate(e.target.value)}/>
                    </VerticalLabelledField>
                    <VerticalLabelledField label="Terzista">
                        <SelectSubcontractor value={subcontractor} onChange={setSubcontractor} placeholder="Tutti"/>
                    </VerticalLabelledField>
                    <VerticalLabelledField label="Ricerca per gruppo di lotti">
                        <LotCategorySelect classname="w-full" value={lotCategory} onChange={handleLotCategoryChange} />
                    </VerticalLabelledField>
                    <VerticalLabelledField label="Ricerca per nr. lotto">
                        <BaseInput type="text" placeholder="Nr.Lotto" value={nrlotto}
                                   onChange={(e) => setNrLotto(e.target.value)}/>
                    </VerticalLabelledField>
                    <VerticalLabelledField label="Visualizza i costi">
                        <Row className="items-center gap-1">
                            <input type="checkbox"
                                   checked={showCosts}
                                   value={showCosts}
                                   onChange={(e) => setShowCosts(e.target.checked)}/>
                            <span>Visualizza</span>
                        </Row>
                    </VerticalLabelledField>
                </Row>
                <Button className="mt-2">Ricerca</Button>
            </form>
        </Section>
    );
};

export default Search;