export const get7daysAgo = () => {
    let d = new Date();
    d.setDate(d.getDate() - 7);
    return d.toISOString().split('T')[0];
}

export const printPageStyle = `
        @page {
            margin: 10mm!important;
        }
        .printable_tag {
            page-break-before: auto;
            page-break-inside: avoid;
            break-inside: avoid;
        }
        
        table tr {
            page-break-inside: avoid;
        }
        
        .unsold_table{
            page-break-before: always;
        }
    `;