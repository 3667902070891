import React from 'react';
import {useLot} from "../../../../../utilities/contexts/lot-context";
import VerticalLabelledField from "../../../../UI/Fields/VerticalLabelledField";
import Select from "../../../../UI/Select";

const ClientInput = ({value, onChange}) => {
    const {movingData} = useLot();

    return (
        <VerticalLabelledField label="Cliente" className="mb-3">
            <Select value={value}
                    onChange={(e) => onChange(e.target.value)}
                    required={!value}>
                <option value="" selected disabled>Seleziona un cliente</option>
                {movingData && movingData.clients && movingData.clients.length > 0 &&
                movingData.clients.map((cl, i) => <option value={cl.idcl}
                                                            key={i}>{cl.ragsoccl}</option>)
                }
            </Select>
        </VerticalLabelledField>
    );
};

export default ClientInput;