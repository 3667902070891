import React, {useEffect} from 'react';
import {useLot} from "../../../utilities/contexts/lot-context";
import {useMatch, useParams} from "react-router-dom";
import useAsync from "../../../utilities/customHooks/async-call";
import {doGetLotBalance, doGetLotMovements} from "../../../logics/lots";
import LotMovements from "./LotMovements";
import LotHeaderDisplay from "../LotHeader/LotHeaderDisplay";
import BalanceRecap from "./BalanceRecap";

const LotMovementPage = () => {

    const {setCurrentMovement, loadMovements, currentLot, loadBalance, balance, lotRows} = useLot();
    const {call} = useAsync();
    const params = useParams();

    useEffect(() => {
        const load = async () => {
            await setCurrentMovement(null);
            await loadBalance(null);
            await call(
                () => doGetLotMovements(params.nr),
                (res) => {
                    if (res) {
                        loadMovements(res[0]);
                    } else {
                        loadMovements(null);
                    }
                },
                (err) => {
                    console.warn(err);
                    loadMovements(null);
                }
            );

            await call(
                () => doGetLotBalance(params.nr),
                (res) => {
                    loadBalance(res)
                }, (err) => {
                    loadBalance(null);
                }
            );
        }

        load();
    }, [])

    return (
        <div className="px-2 pt-2 h-full flex flex-col overflow-y-auto text-sm" style={{minHeight: "300px"}}>
            {currentLot &&
            <LotHeaderDisplay header={currentLot}
                              rows={lotRows}
                              showPrices={false}
                              collapsable={true}
                              showComplete={false} showNotes={false}/>
            }
            <LotMovements/>
            {balance &&
            <BalanceRecap/>
            }
        </div>
    );
};

export default LotMovementPage;