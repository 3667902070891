// APP
const APP_SET_AUTHED_STATUS = "APP_SET_AUTHED_STATUS";
const APP_SET_TOAST_STATUS = "APP_SET_TOAST_STATUS";
const APP_SET_TOAST_CONTENT = "APP_SET_TOAST_CONTENT";
const APP_SET_MENU_OPEN = "APP_SET_MENU_OPEN";
const APP_SET_USER_NAME = "APP_SET_USER_NAME";

// TREE
const TREE_LOAD_TREE = "TREE_LOAD_TREE";
const TREE_ADD_NODE = "TREE_ADD_NODE";
const TREE_REMOVE_NODE = "TREE_REMOVE_NODE";
const TREE_UPDATE_NODE = "TREE_UPDATE_NODE";
const TREE_SET_NODE_OPEN = "TREE_SET_NODE_OPEN";

//REGISTRY
const REGISTRY_SET_PANEL_OPEN = "REGISTRY_SET_PANEL_OPEN";
const REGISTRY_SET_CREATE_PANEL_OPEN = "REGISTRY_SET_CREATE_PANEL_OPEN";

const REGISTRY_LOAD_CLIENTS = "REGISTRY_LOAD_CLIENTS";
const REGISTRY_SET_CURRENT_CLIENT = "REGISTRY_SET_CURRENT_CLIENT";
const REGISTRY_UPDATE_CLIENT = "REGISTRY_UPDATE_CLIENT";

const REGISTRY_LOAD_SUPPLIERS = "REGISTRY_LOAD_SUPPLIERS";
const REGISTRY_SET_CURRENT_SUPPLIER = "REGISTRY_SET_CURRENT_SUPPLIER";
const REGISTRY_UPDATE_SUPPLIER = "REGISTRY_UPDATE_SUPPLIER";

const REGISTRY_LOAD_SUBCONTRACTORS = "REGISTRY_LOAD_SUBCONTRACTORS";
const REGISTRY_SET_CURRENT_SUBCONTRACTOR = "REGISTRY_SET_CURRENT_SUBCONTRACTOR";
const REGISTRY_UPDATE_SUBCONTRACTOR = "REGISTRY_UPDATE_SUBCONTRACTOR";

const REGISTRY_LOAD_COLORS = "REGISTRY_LOAD_COLORS";
const REGISTRY_LOAD_ARTICLES = "REGISTRY_LOAD_ARTICLES";
const REGISTRY_LOAD_PROCESSES = "REGISTRY_LOAD_PROCESSES";
const REGISTRY_LOAD_DOCUMENTS = "REGISTRY_LOAD_DOCUMENTS";
const REGISTRY_LOAD_MEASURE_UNITS = "REGISTRY_LOAD_MEASURE_UNITS";
const REGISTRY_LOAD_CHOICES = "REGISTRY_LOAD_CHOICES";
const REGISTRY_LOAD_LOT_CATEGORIES = "REGISTRY_LOAD_LOT_CATEGORIES";

//LOT
const LOT_LOAD_LOTS = "LOT_LOAD_LOTS";
const LOT_LOAD_PAGINATION = "LOT_LOAD_PAGINATION";
const LOT_SET_CURRENT_LOT = "LOT_SET_CURRENT_LOT";
const LOT_SET_CURRENT_MOVEMENT = "LOT_SET_CURRENT_MOVEMENT";
const LOT_OPEN_MODAL = "LOT_OPEN_MODAL";
const LOT_LOAD_CURRENT_MOVEMENTS = "LOT_LOAD_CURRENT_MOVEMENTS";
const LOT_OPEN_DETAILS = "LOT_OPEN_DETAILS";
const LOT_LOAD_HEADER = "LOT_LOAD_HEADER";
const LOT_LOAD_ROWS = "LOT_LOAD_ROWS";
const LOT_LOAD_BALANCE = "LOT_LOAD_BALANCE";
const LOT_UPDATE_MOVEMENT = "LOT_UPDATE_MOVEMENT";
const LOT_LOAD_MOVING_DATA = "LOT_LOAD_MOVING_DATA";

//QUERIES
const QUERIES_IN_HOUSE_LOAD_ROWS = "QUERIES_IN_HOUSE_LOAD_ROWS";
const QUERIES_IN_HOUSE_LOAD_TOTALS = "QUERIES_IN_HOUSE_LOAD_TOTALS";
const QUERIES_IN_HOUSE_TOGGLE_SELECTED = "QUERIES_IN_HOUSE_TOGGLE_SELECTED";
const QUERIES_IN_HOUSE_TOGGLE_ALL = "QUERIES_IN_HOUSE_TOGGLE_ALL";

const QUERIES_SUBCONTRACTOR_LOAD_ROWS = "QUERIES_SUBCONTRACTOR_LOAD_ROWS";
const QUERIES_SUBCONTRACTOR_LOAD_TOTALS = "QUERIES_SUBCONTRACTOR_LOAD_TOTALS";
const QUERIES_SUBCONTRACTOR_TOGGLE_SELECTED = "QUERIES_SUBCONTRACTOR_TOGGLE_SELECTED";

const QUERIES_WEEKLY_LOAD_ROWS = "QUERIES_WEEKLY_LOAD_ROWS";
const QUERIES_WEEKLY_LOAD_TOTALS = "QUERIES_WEEKLY_LOAD_TOTALS";
const QUERIES_WEEKLY_SET_DATES = "QUERIES_WEEKLY_SET_DATES";

const QUERIES_LOAD_SALES_BY_CLIENT = "QUERIES_LOAD_SALES_BY_CLIENT";
const QUERIES_SET_BY_CLIENT_DATES = "QUERIES_SET_BY_CLIENT_DATES";
const QUERIES_SET_BY_CLIENT_TOTALS = "QUERIES_SET_BY_CLIENT_TOTALS";

const QUERIES_SUBCONTRACTOR_DOCUMENTS_LOAD_ROWS = "QUERIES_SUBCONTRACTOR_DOCUMENTS_LOAD_ROWS";
const QUERIES_SUBCONTRACTOR_DOCUMENTS_SET_FORM = "QUERIES_SUBCONTRACTOR_DOCUMENTS_SET_FORM";

const APP = {
    APP_SET_AUTHED_STATUS,
    APP_SET_TOAST_STATUS,
    APP_SET_TOAST_CONTENT,
    APP_SET_MENU_OPEN,
    APP_SET_USER_NAME,
}

const TREE = {
    TREE_LOAD_TREE,
    TREE_ADD_NODE,
    TREE_REMOVE_NODE,
    TREE_UPDATE_NODE,
    TREE_SET_NODE_OPEN,
}

const REGISTRY = {
    REGISTRY_SET_PANEL_OPEN,
    REGISTRY_SET_CREATE_PANEL_OPEN,

    REGISTRY_LOAD_CLIENTS,
    REGISTRY_SET_CURRENT_CLIENT,
    REGISTRY_UPDATE_CLIENT,

    REGISTRY_LOAD_SUPPLIERS,
    REGISTRY_SET_CURRENT_SUPPLIER,
    REGISTRY_UPDATE_SUPPLIER,

    REGISTRY_LOAD_SUBCONTRACTORS,
    REGISTRY_SET_CURRENT_SUBCONTRACTOR,
    REGISTRY_UPDATE_SUBCONTRACTOR,

    REGISTRY_LOAD_COLORS,
    REGISTRY_LOAD_ARTICLES,
    REGISTRY_LOAD_PROCESSES,
    REGISTRY_LOAD_DOCUMENTS,
    REGISTRY_LOAD_MEASURE_UNITS,
    REGISTRY_LOAD_CHOICES,
    REGISTRY_LOAD_LOT_CATEGORIES,
}

const LOT = {
    LOT_LOAD_LOTS,
    LOT_SET_CURRENT_LOT,
    LOT_SET_CURRENT_MOVEMENT,
    LOT_OPEN_MODAL,
    LOT_LOAD_CURRENT_MOVEMENTS,
    LOT_OPEN_DETAILS,
    LOT_LOAD_PAGINATION,
    LOT_LOAD_HEADER,
    LOT_LOAD_ROWS,
    LOT_LOAD_BALANCE,
    LOT_UPDATE_MOVEMENT,
    LOT_LOAD_MOVING_DATA,
}

const QUERIES = {
    QUERIES_IN_HOUSE_LOAD_ROWS,
    QUERIES_IN_HOUSE_LOAD_TOTALS,
    QUERIES_IN_HOUSE_TOGGLE_SELECTED,
    QUERIES_IN_HOUSE_TOGGLE_ALL,
    QUERIES_SUBCONTRACTOR_LOAD_ROWS,
    QUERIES_SUBCONTRACTOR_LOAD_TOTALS,
    QUERIES_SUBCONTRACTOR_TOGGLE_SELECTED,

    QUERIES_WEEKLY_LOAD_ROWS,
    QUERIES_WEEKLY_LOAD_TOTALS,
    QUERIES_WEEKLY_SET_DATES,

    QUERIES_LOAD_SALES_BY_CLIENT,
    QUERIES_SET_BY_CLIENT_DATES,
    QUERIES_SET_BY_CLIENT_TOTALS,

    QUERIES_SUBCONTRACTOR_DOCUMENTS_LOAD_ROWS,
    QUERIES_SUBCONTRACTOR_DOCUMENTS_SET_FORM,
}

const types = {
    ...APP,
    ...TREE,
    ...REGISTRY,
    ...LOT,
    ...QUERIES,
}

export default types;
