import React from 'react';
import DocumentInfo from "../../UI/DataDisplay/DocumentInfo";
import TableRow from "../General/TableRow";

const SalesByClientRowUnsold = ({row}) => {
    return (
        <TableRow>
            <td>{row?.nrlotto}</td>
            <td><DocumentInfo document={row?.document} hideType={true} hideCompany={true}/> </td>
            <td>{row?.article}</td>
            <td className="border-l border-black text-right pr-2">{row?.nrpz}</td>
            <td className="border-l border-black text-center">{row?.note ?? "..."}</td>
        </TableRow>
    );
};

export default SalesByClientRowUnsold;