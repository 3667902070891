import React from 'react';
import Select from "../../../../UI/Select";
import VerticalLabelledField from "../../../../UI/Fields/VerticalLabelledField";
import {useLot} from "../../../../../utilities/contexts/lot-context";

const SubcontractorInput = ({value, setValue}) => {

    const {movingData} = useLot();

    return (
        <VerticalLabelledField label="Terzista" className="mb-2">
            <Select value={value}
                    onChange={(e) => setValue(e.target.value)}
                    required={!value}>
                <option value="" disabled selected>Seleziona un terzista</option>
                {movingData && movingData.subcontractors && movingData.subcontractors.length > 0 &&
                movingData.subcontractors.map((sub, i) => <option value={sub.idterz}
                                                                  key={i}>{sub.ragsoctz}</option>)
                }
            </Select>
        </VerticalLabelledField>
    );
};

export default SubcontractorInput;