import React, {useState} from 'react';
import {useReactToPrint} from "react-to-print";
import {useQueries} from "../../../utilities/contexts/queries-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {doGetSalesByClient} from "../../../logics/queries";
import {get7daysAgo, printPageStyle} from "../../../utilities/data/tools";
import Search from "./Search";
import Loading from "../../UI/Loading";
import SalesByClientGroup from "./SalesByClientGroup";
import PrintButton from "../../UI/PrintButton";
import HiddenPrintContainer from "../../UI/HiddenPrintContainer";
import SalesByClientPrintable from "./SalesByClientPrintable";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import HorizontalLabelledField from "../../UI/Fields/HorizontalLabelledField";
import {formatInt, formatPrice} from "../../../utilities/data/parser";

const SalesByClient = () => {

    const {loadSalesByClient, salesByClient, byClientDates, setSalesByClientTotals, salesByClientTotals} = useQueries();

    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState(null);
    const [showTotals, setShowTotals] = useState(false);
    const [displayCrusts, setDisplayCrusts] = useState(false);
    const [nrLotto, setNrLotto] = useState("");
    const [displayOnApprovalOnly, setDisplayOnApprovalOnly] = useState(false);

    const [crusts, setCrusts] = useState(null);

    const {call} = useAsync();

    const printContainer = React.useRef();

    const handleSubmit = async () => {
        setLoading(true);
        console.log(client);
        await call(
            () => doGetSalesByClient(client?.id, byClientDates.start, byClientDates.end, nrLotto, showTotals, displayOnApprovalOnly, displayCrusts),
            (res) => {
                if (res.clients) loadSalesByClient(res.clients);
                else loadSalesByClient([]);

                if (res.totals) setSalesByClientTotals(res.totals);
                else setSalesByClientTotals(null);

                if(res.crusts) setCrusts(res.crusts);
                else setCrusts(null);
            }
        );

        setLoading(false);
    }

    const handlePrint = useReactToPrint({
        pageStyle: printPageStyle,
        content: () => printContainer.current,
        documentTitle: `vendite_per_cliente`
    });

    return (
        <div className="flex flex-col flex-1 " style={{minHeight: "100px"}}>
            <Search client={client}
                    setClient={setClient}
                    onSubmit={handleSubmit}
                    showTotals={showTotals}
                    setShowTotals={setShowTotals}
                    displayCrusts={displayCrusts}
                    setDisplayCrusts={setDisplayCrusts}
                    nrLotto={nrLotto}
                    setNrLotto={setNrLotto}
                    displayOnApprovalOnly={displayOnApprovalOnly}
                    setDisplayOnApprovalOnly={setDisplayOnApprovalOnly}
            />

            <div className="flex flex-col flex-1 overflow-y-auto relative" style={{minHeight: "50px"}}>
                {loading ?
                    <Loading label="Interrogazione in corso"/>
                    :
                    <SalesByClientGroup onApproval={displayOnApprovalOnly} crusts={crusts}/>

                }
            </div>

            {!loading && salesByClient.length > 0 &&
            <div className="border-t border-black flex flex-row p-1 justify-between">
                <PrintButton title="Stampa" onClick={handlePrint}/>
                {salesByClientTotals &&
                <>
                    <VerticalLabelledField label="Totali Vendite">
                        <div className="flex flex-row items-start gap-2">
                            <HorizontalLabelledField
                                label="Nr.pezzi">{formatInt(salesByClientTotals?.sold_leathers?.nrpz)}</HorizontalLabelledField>
                            <HorizontalLabelledField
                                label="Mq">{formatPrice(salesByClientTotals?.sold_leathers?.mq)}</HorizontalLabelledField>
                            <HorizontalLabelledField
                                label="Importo">{formatPrice(salesByClientTotals?.sold_leathers?.amount)}€</HorizontalLabelledField>
                        </div>
                    </VerticalLabelledField>
                    <VerticalLabelledField label="Totali Resi">
                        <div className="flex flex-row items-start gap-2">
                            <HorizontalLabelledField
                                label="Nr.pezzi">{formatInt(salesByClientTotals?.unsold_leathers?.nrpz)}</HorizontalLabelledField>
                            <HorizontalLabelledField
                                label="Mq">{formatPrice(salesByClientTotals?.unsold_leathers?.mq)}</HorizontalLabelledField>
                            <HorizontalLabelledField
                                label="Importo">{formatPrice(salesByClientTotals?.unsold_leathers?.amount)}€</HorizontalLabelledField>
                        </div>
                    </VerticalLabelledField>
                    <VerticalLabelledField label="Totali Generali">
                        <div className="flex flex-row items-start gap-2">
                            <HorizontalLabelledField
                                label="Nr.pezzi">{formatInt(salesByClientTotals?.sold_leathers?.nrpz - salesByClientTotals?.unsold_leathers?.nrpz)}</HorizontalLabelledField>
                            <HorizontalLabelledField
                                label="Importo">{formatPrice(salesByClientTotals?.sold_leathers?.amount - salesByClientTotals?.unsold_leathers?.amount)}€</HorizontalLabelledField>
                        </div>
                    </VerticalLabelledField>
                </>
                }

            </div>
            }

            <HiddenPrintContainer>
                <div ref={printContainer}>
                    <SalesByClientPrintable onApproval={displayOnApprovalOnly} crusts={crusts} showTotals={showTotals}/>
                </div>
            </HiddenPrintContainer>

        </div>
    );
};

export default SalesByClient;