import React from 'react';

const TableRow = ({className, ...props}) => {
    return (
        <tr className={`odd:bg-gray-100 hover:bg-blue-100 ${className}`} {...props}>
            {props.children}
        </tr>
    );
};

export default TableRow;