import React from 'react';
import Table from "../General/Table";
import TableHeader from "../General/TableHeader";
import SalesByClientRow from "./SalesByClientRow";
import {formatInt, formatPrice} from "../../../utilities/data/parser";
import SalesByClientRowUnsold from "./SalesByClientRowUnsold";

const SalesByClientTable = ({sales, onApproval}) => {
    return (
        <div className="mb-4">
            <Table>
                <TableHeader tr={
                    <tr className="border-t border-b border-black bg-secondary bg-opacity-40">
                        <th colSpan={7} className="text-center">{onApproval ? "C/TO VISIONE" :"VENDITE"} {sales.name}</th>
                    </tr>
                }>
                    <th>Lotto</th>
                    <th>Documento</th>
                    <th>Articolo</th>
                    <th className="border-l border-black text-center">Nr.Pz</th>
                    <th className="border-l border-black">
                        <div className="flex flex-col items-center text-center">
                            <div>Scelta I</div>
                            <div>(Mq/€)</div>
                        </div>
                    </th>
                    <th className="border-l border-black">
                        <div className="flex flex-col items-center text-center">
                            <div>Scelta II</div>
                            <div>(Mq/€)</div>
                        </div>
                    </th>
                    <th className="border-l border-black">
                        <div className="flex flex-col items-center text-center">
                            <div>Scelta II</div>
                            <div>(Mq/€)</div>
                        </div>
                    </th>
                </TableHeader>
                <tbody>
                {sales.sold_leathers && sales.sold_leathers.length > 0 &&
                sales.sold_leathers.map((row, i) =>
                    <SalesByClientRow row={row} key={i}/>
                )
                }
                </tbody>
                <tfoot>
                <tr className="border-t border-b border-black">
                    <td colSpan={7} className="text-center">
                        Metri quadri totali: {formatPrice(sales?.totals?.sold_leathers?.mq)} - vendite
                        totali: {formatInt(sales?.totals?.sold_leathers?.nrpz)} Pz -
                        Importo: {formatPrice(sales?.totals?.sold_leathers?.amount)} €
                    </td>
                </tr>
                </tfoot>
            </Table>

            {sales.unsold_leathers &&
            <Table>
                <TableHeader tr={
                    <tr className="border-t border-b border-black bg-red-500 bg-opacity-40">
                        <th colSpan={5} className="text-center">RESI {sales.name}</th>
                    </tr>
                }>
                    <th>Lotto</th>
                    <th>Documento</th>
                    <th>Articolo</th>
                    <th className="border-l border-black text-center">Nr.Pz</th>
                    <th className="border-l border-black text-center">Note</th>
                </TableHeader>
                <tbody>
                {sales.unsold_leathers && sales.unsold_leathers.length > 0 &&
                sales.unsold_leathers.map((row, i) =>
                    <SalesByClientRowUnsold row={row} key={i}/>
                )
                }
                </tbody>
                <tfoot>
                <tr className="border-t border-b border-black">
                    <td colSpan={5} className="text-center">
                        Resi totali: {formatInt(sales?.totals?.unsold_leathers?.nrpz)} Pz -
                        Importo: {formatPrice(sales?.totals?.unsold_leathers?.amount)} €
                    </td>
                </tr>
                </tfoot>
            </Table>
            }

        </div>
    );
};

export default SalesByClientTable;