import React from 'react';
import Table from "../General/Table";
import TableHeader from "../General/TableHeader";
import EmptyQuery from "../General/EmptyQuery";
import SubcontractorRow from "./SubcontractorRow";

const SubcontractorTable = ({rows, displayCosts}) => {
    return (
        <div>
            {rows && rows.length > 0 ?
                <Table>
                    <TableHeader>
                        <th className="text-center">Terzista</th>
                        <th className="text-center">Lotto</th>
                        <th className="pl-2">Documento e Data</th>
                        <th>Articolo - Colore</th>
                        <th className="text-right">Nr.pelli ferme</th>
                        {displayCosts && <th className="text-right">Costo al pezzo</th>}
                        {displayCosts && <th className="text-right">Valore</th>}
                    </TableHeader>
                    <tbody>
                    {rows.map((row,i) =>
                        <SubcontractorRow row={row} key={i} displayCosts={displayCosts}/>
                    )}
                    </tbody>
                </Table>
                :
                <EmptyQuery />
            }

        </div>
    );
};

export default SubcontractorTable;