import React, {useEffect} from 'react';
import {useRegistry} from "../../../utilities/contexts/registry-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {doGetDocumentTypes} from "../../../logics/registry";
import SearchSelect from "./SearchSelect";
import parseOptions from "../../../utilities/data/parseOptions";

const SearchSelectDocuments = ({value, onChange, ...props}) => {

    const {documents, loadDocuments} = useRegistry();
    const {call} = useAsync();

    const handleChange = (value) => {
        console.log(value);
        onChange && onChange(value);
    }

    const parseDocumentOptions = () => {
        return documents.map((doc) => parseOptions.parseDocumentType(doc));
    }

    useEffect(() => {
        const load = async () => {
            if(!documents || !documents.length > 0) {
                console.log("Getting docs");
                await call(
                    () => doGetDocumentTypes(),
                    (res) => {
                        if(res)
                            loadDocuments(res);
                    },
                    (err) => console.log(err)
                )
            }
        }

        load();
    }, []);

    return (
        <SearchSelect options={parseDocumentOptions(documents)} onChange={handleChange} value={parseOptions.parseDocumentType(value)} {...props}/>
    );
};

export default SearchSelectDocuments;
