import types from "../types/types";
import treeData from "../data/tree";

const NODE_HEIGHT = treeData.NODE_HEIGHT;

export const defaultState = {
    tree: null,
}

function treeReducer(state, action) {
    switch (action.type) {
        case types.TREE_LOAD_TREE: {
            let tree = null;
            if(action.nodes) {
                tree = buildTree(action.nodes);
            }

            return {
                ...state,
                tree: tree,
            }
        }
        case types.TREE_SET_NODE_OPEN: {
            let _tree = updateNodeOpen(state.tree, action.id, action.open);
            return {
                ...state,
                tree: {..._tree},
            }
        }
        case types.TREE_UPDATE_NODE: {
            let _tree = rebuildTree(state.tree, action.node);

            return {
                ...state,
                tree: {..._tree}
            }
        }
        case types.TREE_ADD_NODE: {
            console.log("TREE_ADD_NODE reducer")
            let _tree = addNode(state.tree, action.original, action.new_node);

            return {
                ...state,
                tree: {..._tree}
            }
        }
        case types.TREE_REMOVE_NODE: {
            console.log("TREE_REMOVE_NODE");
            let _tree = removeNode(action.idnodo, state.tree);
            _tree = buildTree(_tree);

            return {
                ...state,
                tree: {..._tree}
            }
        }
        default:
            throw new Error("Unhandled action type: " + action.type);
    }
}

function buildTree(node, index = 0, depth = 0, father=null) {
    let _children = [];
    let height = NODE_HEIGHT;
    if (node && node.children && node.children.length > 0) {
        _children = node.children.map((child, i) => buildTree(child, i, depth + 1, node));
        _children.forEach((child) => {
            height += child.open ? child.height : NODE_HEIGHT;
        });
    }

    return {
        ...node,
        idnodo: node.idnodo,
        index: index,
        depth: depth,
        height: height,
        father: {...father, children: []},
        open: node.open !== null && node.open !== undefined ? node.open : true,
        children: [..._children],
    }
}

function updateNodeOpen(node, id, open) {
    if (node.idnodo === id) {
        return {
            ...node,
            open: open,
            height: NODE_HEIGHT,
        }
    } else {
        return {
            ...node,
            children: [...node.children.map((child) => updateNodeOpen(child, id, open))]
        }
    }
}

function rebuildTree(tree, newNode) {
    let _children = [];
    let height = NODE_HEIGHT;

    let childrenToIter = tree.idnodo === newNode.idnodo ? newNode.children : tree.children;

    if (childrenToIter && childrenToIter.length > 0) {
        _children = childrenToIter.map((child) => rebuildTree(child, newNode));
        if(tree.open || (newNode.idnodo === tree.idnodo && newNode.open)) {
            _children.forEach((child) => {
                height += child.height;
            });
        }
    }

    if(tree.idnodo === newNode.idnodo) {
        let auxNode = {
            ...tree,
            ...newNode,
            open: newNode.open,
            height: newNode.open ? height : NODE_HEIGHT,
            children: [..._children]
        }
        return {
            ...auxNode
        }
    } else {
        return {
            ...tree,
            height: height,
            children: [..._children],
        }
    }
}

function addNode(tree, original, new_node) {

    let nodeWithNewChild = {
        ...original,
        children: [...original.children, new_node]
    }

    return rebuildTree(tree, nodeWithNewChild);
}

function removeNode(idnodo, tree) {
    if(tree.idnodo === idnodo) {
        return null;
    } else {
        let _children = tree.children.map((child) => {
            let _child = removeNode(idnodo, child);
            if(_child)
                return {..._child}
            else return null;
        });

        _children = _children.filter((child) => child !== null);
        if(_children.length !== tree.children.length) {
            _children = _children.map((child, i) => {
                return {...child, index: i}
            });
        }

        return {
            ...tree,
            children: [..._children]
        }
    }
}

export default treeReducer;