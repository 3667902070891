import React, {useState} from 'react';
import RowAligned from "./RowAligned";
import Toggle from "../Toggle";

const Section = ({label, color = "primary", collapsable = false, inverted = false, contentColor = true, ...props}) => {

    const [open, setOpen] = useState(true);

    return (
        <div {...props}>
            <RowAligned className={`justify-between bg-${color} bg-opacity-30 p-1 border-${color} ${!open && `pb-1`}`}>
                <div className={`uppercase text-black font-medium text-sm leading-none`}>{label}</div>
                {collapsable &&
                <Toggle open={open}
                        onClick={() => setOpen(!open)}
                        inverted={inverted}/>
                }
            </RowAligned>

            {open &&
            <div className={`p-1 ${contentColor ? `bg-${color} bg-opacity-10` : ""}`}>{props.children}</div>
            }

        </div>
    );
};

export default Section;
