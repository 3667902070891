import React from 'react';
import Select from "../../../../UI/Select";
import VerticalLabelledField from "../../../../UI/Fields/VerticalLabelledField";
import {useLot} from "../../../../../utilities/contexts/lot-context";

const ProcessingInput = ({value, onChange}) => {

    const {movingData} = useLot();

    return (
        <VerticalLabelledField label="Lavorazione eseguita" className="mb-3">
            <Select value={value}
                    onChange={(e) => onChange(e.target.value)}
                    required={!value}>
                {movingData && movingData.processings && movingData.processings.length > 0 &&
                movingData.processings.map((lav, i) => <option value={lav.idlav}
                                                               key={i}>{lav.nomelav}</option>)
                }
            </Select>
        </VerticalLabelledField>
    );
};

export default ProcessingInput;