import React from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import Layout from "../components/UI/Layout";
import Navigation from "../components/Queries/Navigation";

const Queries = () => {

    return (
        <Layout title="Interrogazioni">
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
                <Navigation />
                <Outlet/>
            </div>
        </Layout>
    );
};

export default Queries;