import React from 'react';
import Table from "../General/Printable/Table";
import TableHead from "../General/Printable/TableHead";
import {useQueries} from "../../../utilities/contexts/queries-context";
import Td from "../General/Printable/Td";
import Th from "../General/Printable/Th";
import DocumentInfo from "../../UI/DataDisplay/DocumentInfo";
import {formatPrice, parseDate} from "../../../utilities/data/parser";
import TableRow from "../General/TableRow";

const SubcontractorDocPrintable = () => {

    const {subcontractorDocumentsRow, subcontractorDocumentsForm} = useQueries();

    return (
        <div className="w-full">
            <div className="text-center mb-4">Stampa documenti terzisti
                dal {parseDate(subcontractorDocumentsForm.start)} al {parseDate(subcontractorDocumentsForm.end)}</div>
            {subcontractorDocumentsRow && subcontractorDocumentsRow.length > 0 &&
            subcontractorDocumentsRow.map((row, i) =>
                <Table key={i} className="w-full mb-4">
                    <TableHead tr={
                        <tr className="text-center">
                            <Th colSpan={7}>{row?.name}</Th>
                        </tr>
                    }>
                        <Th className="text-left">Lotto</Th>
                        <Th className="text-left">Documento</Th>
                        <Th className="text-left">Tipo Lav.</Th>
                        <Th className="text-right pr-1">Nr.Pz</Th>
                        <Th className="text-right pr-1">Quantità</Th>
                        <Th className="text-right pr-1">Prezzo</Th>
                        <Th className="text-right pr-1">Importo</Th>
                    </TableHead>
                    <tbody>
                    {row.documents && row.documents.length > 0 &&
                    row.documents.map((doc, i) =>
                        <tr key={i}>
                            <Td className="text-left">{doc.nrlotto}</Td>
                            <Td className="text-left"><DocumentInfo document={doc.doc} hideCompany={true}
                                                                    hideType={true}/> </Td>
                            <Td className="text-left">{doc?.processing?.name}</Td>
                            <Td className="pr-1 text-right">{doc?.nrpz}</Td>
                            <Td className="pr-1 text-right">
                                {doc?.costs?.row1?.qta1 > 0 && <div>{formatPrice(doc?.costs?.row1?.qta1)} {doc?.costs?.row1?.um1?.name}</div>}
                                {doc?.costs?.row2?.qta2 > 0 && <div>{formatPrice(doc?.costs?.row2?.qta2)} {doc?.costs?.row2?.um2?.name}</div>}
                                {doc?.costs?.row3?.qta3 > 0 && <div>{formatPrice(doc?.costs?.row3?.qta3)} {doc?.costs?.row3?.um3?.name}</div>}
                            </Td>
                            <Td className="pr-1 text-right">
                                {doc?.costs?.row1?.qta1 > 0 && <div>{formatPrice(doc?.costs?.row1?.prezzo1)}</div>}
                                {doc?.costs?.row2?.qta2 > 0 && <div>{formatPrice(doc?.costs?.row2?.prezzo2)}</div>}
                                {doc?.costs?.row3?.qta3 > 0 && <div>{formatPrice(doc?.costs?.row3?.prezzo3)}</div>}
                            </Td>
                            <Td className="pr-1 text-right">
                                {doc?.costs?.row1?.qta1 > 0 && <div>{formatPrice(doc?.costs?.row1?.qta1)}</div>}
                                {doc?.costs?.row2?.qta2 > 0 && <div>{formatPrice(doc?.costs?.row2?.qta2)}</div>}
                                {doc?.costs?.row3?.qta3 > 0 && <div>{formatPrice(doc?.costs?.row3?.qta3)}</div>}
                            </Td>
                        </tr>
                    )
                    }
                    <tr className="text-right">
                        <Td colSpan={7}>{formatPrice(row?.totals?.amount)}</Td>
                    </tr>
                    </tbody>
                </Table>
            )
            }

        </div>
    );
};

export default SubcontractorDocPrintable;