import React from 'react';
import {useQueries} from "../../../utilities/contexts/queries-context";
import SubcontractorDocTable from "./SubcontractorDocTable";
import EmptyQuery from "../General/EmptyQuery";

const SubcontractorDocGroup = () => {

    const {subcontractorDocumentsRow} = useQueries();

    console.log(subcontractorDocumentsRow);

    return (
        <div>
            {subcontractorDocumentsRow && subcontractorDocumentsRow.length > 0 ?
                subcontractorDocumentsRow.map((subcontractor, i) =>
                    <SubcontractorDocTable subcontractor={subcontractor} key={i} />
                )
                :
                <EmptyQuery />
            }
        </div>
    );
};

export default SubcontractorDocGroup;