import React from 'react';

const TableHeader = ({tr, ...props}) => {
    return (
        <thead className="text-left">
        {tr && tr}
        <tr className="border-b border-black">
            {props.children}
        </tr>
        </thead>
    );
};

export default TableHeader;