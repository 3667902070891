import React, {useState, useRef} from 'react';
import {useQueries} from "../../../utilities/contexts/queries-context";
import useAsync from "../../../utilities/customHooks/async-call";
import { doGetSubcontractorLeathers} from "../../../logics/queries";
import Search from "./Search";
import SubcontractorTable from "./SubcontractorTable";
import Loading from "../../UI/Loading";
import RowAligned from "../../UI/Structure/RowAligned";
import PrintButton from "../../UI/PrintButton";
import {formatInt, formatPrice} from "../../../utilities/data/parser";
import InHousePrintable from "../InHouseLeathers/InHousePrintable";
import HiddenPrintContainer from "../../UI/HiddenPrintContainer";
import SubcontractorPrintable from "./SubcontractorPrintable";
import {useReactToPrint} from "react-to-print";
import {printPageStyle} from "../../../utilities/data/tools";

const SubcontractorsLeathers = () => {

    const {loadSubcontractorRows, loadSubcontractorTotals, subcontractorRows, subcontractorTotals} = useQueries();
    const {call} = useAsync();

    const [loading, setLoading] = useState(false);
    const [displayCosts, setDisplayCosts] = useState(false);
    const [selectedSubcontractor, setSelectedSubcontractor] = useState(null);

    const printableList = useRef();

    const handleSubmit = async (data) => {
        setLoading(true);
        setDisplayCosts(data.showCosts);
        setSelectedSubcontractor(data.subcontractor ?? null);
        await call(
            () => doGetSubcontractorLeathers(data.nrlotto, data.date, data.showCosts, data.subcontractor?.id),
            (res) => {
                if (res?.movements) {
                    loadSubcontractorRows(res.movements);
                } else {
                    loadSubcontractorRows([]);
                }

                loadSubcontractorTotals(res.totals ?? null);
            }
        );
        setLoading(false);
    }

    const handlePrint = useReactToPrint({
        pageStyle: printPageStyle,
        content: () => printableList.current,
        documentTitle: selectedSubcontractor ? `pelli_terzista_${selectedSubcontractor?.name}` : "pelli_dai_terzisti"
    });

    return (
        <div className="flex flex-col flex-1 " style={{minHeight: "100px"}}>
            <Search onSubmit={handleSubmit}/>

            <div className="flex flex-col flex-1 overflow-y-auto relative" style={{minHeight: "50px"}}>
                {loading ?
                    <Loading label="Interrogazione in corso"/>
                    :
                    <SubcontractorTable rows={subcontractorRows} displayCosts={displayCosts}/>
                }
            </div>

            {!loading && subcontractorRows.length > 0 &&
            <div className="flex flex-row justify-between font-medium border-t border-black">
                <RowAligned className="p-1 gap-2">
                    <PrintButton title="Stampa lista" onClick={handlePrint}/>
                </RowAligned>
                <RowAligned>
                    <div className="pr-1">Totali:</div>
                    <div className="font-bold">{formatInt(subcontractorTotals?.nrpz)}</div>
                    {displayCosts &&
                    <>
                        <div className="w-32"></div>
                        <div className="w-24">{formatPrice(subcontractorTotals?.costs)}€</div>
                    </>
                    }

                </RowAligned>
            </div>
            }

            <HiddenPrintContainer>
                <div ref={printableList}>
                    <SubcontractorPrintable subcontractor={selectedSubcontractor} displayCosts={displayCosts}/>
                </div>
            </HiddenPrintContainer>


        </div>
    );
};

export default SubcontractorsLeathers;
