import React from 'react';
import Modal from "../UI/Modal";
import HeaderEditForm from "./LotHeader/HeaderEditForm";
import {doPostLotHeader} from "../../logics/lots";
import useAsync from "../../utilities/customHooks/async-call";

const CreateLotModal = ({open, onOverlayClick, suggested, ...props}) => {

    const {call} = useAsync();

    const handleSubmit = async (fields) => {
        let params = new URLSearchParams();

        fields.datadocfor && params.append('datadocfor', fields.datadocfor);
        fields.datavenditacroste && params.append('datavenditacroste', fields.datavenditacroste);
        fields.daticompleti && params.append('daticompleti', fields.daticompleti);
        fields.docspesedazio && params.append('docspesedazio', fields.docspesedazio);
        fields.docspesedogana && params.append('docspesedogana', fields.docspesedogana);
        fields.docvenditacroste && params.append('docvenditacroste', fields.docvenditacroste);
        fields.eur_usd && params.append('eur_usd', fields.eur_usd);
        fields.idforn && params.append('idforn', fields.idforn);
        fields.idtipodoc && params.append('idtipodoc', fields.idtipodoc);
        fields.note && params.append('note', fields.note);
        fields.nrdocfor && params.append('nrdocfor', fields.nrdocfor);
        fields.nrlotto && params.append('nrlotto', fields.nrlotto);
        fields.spesedazio && params.append('spesedazio', fields.spesedazio);
        fields.spesedogana && params.append('spesedogana', fields.spesedogana);
        fields.valuta && params.append('valuta', fields.valuta);
        fields.venditacroste && params.append('venditacroste', fields.venditacroste);
        fields.um && params.append('um', fields.um);

        await call(() => doPostLotHeader(params),
            (res) => console.log(res)
        )

        onOverlayClick && onOverlayClick();
    }

    return (
        <Modal open={open} onOverlayClick={onOverlayClick}>
            <div>Crea un nuovo lotto</div>
            <HeaderEditForm header={null} onSubmit={handleSubmit} suggested={suggested}/>
        </Modal>
    );
};

export default CreateLotModal;