import React from 'react';
import {doPostLogin} from "../../logics/auth";
import {useApp} from "../contexts/app-context";
import useAsync from "./async-call";

function useAuth() {
    const {authed, setAuthed, setUserName} = useApp();
    const {call} = useAsync();

    async function login(user, password) {
        return await call(
            () => doPostLogin(user, password),
            (res) => {
                console.log(res);
                setUserName(user);
                sessionStorage.setItem('session_token', res.token);
                setAuthed(true);
                return true;
            },
            () => {
                setAuthed(false);
                return false;
            }
        );
    }

    async function logout() {
        await setAuthed(false);
    }

    return {
        authed,
        login,
        logout
    }
}

export default useAuth;
