import React from 'react';
import {useLot} from "../../../../../utilities/contexts/lot-context";

const ModalTitleWithCloseButton = ({title, modal, ...props}) => {

    const {openModal} = useLot();

    const handleClose = () => {
        openModal(modal, false);
    }

    return (
        <div className="flex flex-row justify-between mb-2">
            <div className="text-lg uppercase font-medium text-gray-600 ">{title}</div>
            <div className="text-red-800 font-medium uppercase cursor-pointer hover:text-black hover:underline" onClick={handleClose}>CHIUDI</div>
        </div>
    );
};

export default ModalTitleWithCloseButton;