import React from 'react';
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import {useLot} from "../../../utilities/contexts/lot-context";

const FatherMovementRecap = () => {

    const {currentMovement} = useLot();

    return (
        <div className="bg-gray-100 p-2 mb-2 rounded">
            <div className="text-md uppercase font-medium text-gray-500 mb-1">
                Dettagli movimento di partenza
            </div>
            <div
                className="flex flex-row flex-wrap items-start gap-x-2 gap-y-1 justify-between">
                <VerticalLabelledField label="Pezzi">
                    {currentMovement?.nrpz}
                </VerticalLabelledField>
                <VerticalLabelledField label="Articolo">
                    {currentMovement?.article?.name}
                </VerticalLabelledField>
                <VerticalLabelledField label="Colore">
                    {currentMovement?.color?.name}
                </VerticalLabelledField>
                <VerticalLabelledField label="Processo">
                    {currentMovement?.processing?.name}
                </VerticalLabelledField>
                {currentMovement?.subcontractor?.name &&
                <VerticalLabelledField label="Terzista">
                    {currentMovement?.subcontractor?.name}
                </VerticalLabelledField>
                }
                {currentMovement?.client?.name &&
                <VerticalLabelledField label="Cliente">
                    {currentMovement?.client?.name}
                </VerticalLabelledField>
                }

            </div>
        </div>
    );
};

export default FatherMovementRecap;