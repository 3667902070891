import React from 'react';
import {useApp} from "../../utilities/contexts/app-context";
import useAuth from "../../utilities/customHooks/auth";
import {NavLink, useNavigate} from "react-router-dom";
import {BsChevronLeft, BsChevronRight, BsPeople} from "react-icons/bs";
import {GiExitDoor} from "react-icons/gi";
import {MdExitToApp} from "react-icons/md";
import {FaCubes, FaTags, FaThList, FaWarehouse} from "react-icons/fa";
import {MdColorLens} from "react-icons/md";
import {HiSearchCircle} from "react-icons/hi";

const MenuItem = ({icon, label, to = '/', letter = "", ...props}) => {

    const {menuOpen} = useApp();

    const activeClass = "font-bold text-secondary border-secondary ";
    const defaultClass = " flex flex-row pl-2 border-l-4 h-10 hover:bg-white hover:bg-opacity-10";

    return (
        <NavLink to={to} {...props}
                 className={({isActive}) => (isActive ? activeClass + defaultClass : defaultClass + " border-transparent")}>
            <div className="flex flex-row items-center p-1 ">
                {icon &&
                <div className="pr-3 relative" title={label}>
                    {icon}
                    {letter &&
                    <div className="absolute top-2 right-2 uppercase text-sm ">{letter}</div>
                    }
                </div>}
                {menuOpen && <div className="pr-2">{label}</div>}
            </div>
        </NavLink>
    )
}

const SideMenu = ({...props}) => {

    const {menuOpen, setMenuOpen} = useApp();
    const navigate = useNavigate();
    const {authed, logout} = useAuth();

    const handleLogout = () => {
        logout();
        navigate("/login");
    }

    return (
        <div
            className="bg-dark text-white flex flex-col items-center justify-start relative z-10 sticky top-0 h-screen">
            <div
                className="bg-white h-8 w-8 rounded-full flex items-center justify-center text-black absolute -right-4 cursor-pointer shadow hover:bg-gray-200"
                onClick={() => setMenuOpen(!menuOpen)}>
                {menuOpen
                    ? <BsChevronLeft/>
                    : <BsChevronRight/>
                }

            </div>
            <div id='nav_column' className="text-xl flex flex-col items-start justify-between h-full">
                <div className="overflow-y-auto flex-1 h-full">
                    <div className="mt-10"/>

                    {authed &&
                    <>
                        <MenuItem icon={<FaThList/>}
                                  label={'Lotti'}
                                  to={'/lotti'}/>
                        <MenuItem icon={<BsPeople/>}
                                  label={'Clienti'}
                                  letter="C"
                                  to={'/clienti'}/>
                        <MenuItem icon={<BsPeople/>}
                                  label={'Fornitori'}
                                  letter="F"
                                  to={'/fornitori'}/>
                        <MenuItem icon={<BsPeople/>}
                                  label={'Terzisti'}
                                  letter="T"
                                  to={'/terzisti'}/>
                        <MenuItem icon={<FaWarehouse/>}
                                  label={'Magazzini'}
                                  to={'/magazzini'}/>
                        <MenuItem icon={<FaCubes/>}
                                  label={'Lavorazioni'}
                                  to={'/lavorazioni'}/>
                        <MenuItem icon={<FaTags/>}
                                  label={'Articoli'}
                                  to={'/articoli'}/>
                        <MenuItem icon={<MdColorLens/>}
                                  label={'Colori'}
                                  to={'/colori'}/>
                        <MenuItem icon={<HiSearchCircle />}
                                  label={'Interrogazioni'}
                                  to={'/interrogazioni'}/>
                    </>
                    }

                </div>

                <div className="w-full">
                    {authed
                        ? <div onClick={handleLogout}
                               className="w-full  flex flex-row pl-2 border-l-4 border-transparent h-10 hover:bg-white hover:bg-opacity-10">
                            <div className="flex flex-row items-center p-1 cursor-pointer">
                                <div className="pr-3"><MdExitToApp title='Esci'/></div>
                                {menuOpen && <div className="pr-2">Esci</div>}
                            </div>
                        </div>
                        : <MenuItem icon={<GiExitDoor/>}
                                    label={"Accedi"}
                                    to={'/login'} replace={true}/>
                    }

                </div>
            </div>
        </div>
    )
}

export default SideMenu;
