import React, {useEffect, useState} from 'react';
import RowAligned from "../../UI/Structure/RowAligned";
import FormField from "../../UI/Form/FormField";
import Button from "../../UI/Button";
import BaseInput from "../../UI/BaseInput";
import SelectProcess from "../../UI/Selects/SelectProcess";
import SearchSelectArticle from "../../UI/Selects/SearchSelectArticle";
import SelectColors from "../../UI/Selects/SelectColors";
import SearchSelectDocuments from "../../UI/Selects/SearchSelectDocuments";
import SelectSupplier from "../../UI/Selects/SelectSupplier";
import SelectSubcontractor from "../../UI/Selects/SelectSubcontractor";
import SelectClient from "../../UI/Selects/SelectClient";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import Select from "../../UI/Select";

const EditMovementForm = ({movement, onSubmit, onUndo, sold = false}) => {

    const formRef = React.useRef();

    const [process, setProcess] = useState(null);
    const [article, setArticle] = useState(null);
    const [color, setColor] = useState(null);
    const [document, setDocument] = useState(null);
    const [subcontractor, setSubcontractor] = useState(null);
    const [client, setClient] = useState(null);

    const [displayClose, setDisplayClose] = useState(movement.chiuso ?? false);

    const fieldsLocked = () => {
        if (movement) {
            if (movement.has_children || (movement.children && movement.children.length > 0)) {
                return true;
            }
        }

        return false;

    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let nrpz = formRef.current.nrpz.value;
        let qta1 = formRef.current.qta1.value;
        let prezzo1 = formRef.current.prezzo1.value;
        let datadocmov = formRef.current.datadocmov.value;
        let nrdocmov = formRef.current.nrdocmov.value;

        let closed_checkbox = formRef.current.closed_checkbox.checked;
        console.log(closed_checkbox);


        let fields = {};
        if (process) fields['processing'] = process.id;
        if (nrpz) fields['nrpz'] = nrpz;
        if (qta1) fields['qta1'] = qta1;
        if (prezzo1) fields['prezzo1'] = prezzo1;
        if (datadocmov) fields['datadocmov'] = datadocmov;
        if (nrdocmov) fields['nrdocmov'] = nrdocmov;

        console.log(document);
        if (document && (document.idtipodoc || document.id)) fields['tipodocmov'] = document?.idtipodoc ?? document.id;
        if (color && color.id) fields['color'] = color.id;
        if (article && article.id) fields['article'] = article.id;
        if (subcontractor && subcontractor.id) fields['subcontractor'] = subcontractor.id;
        if (client && client.id) fields['client'] = client.id;

        if(closed_checkbox) {

            let closure_notes = formRef.current.closure_notes.value;
            let variation_date = formRef.current.variation_date.value;
            let variation_qty = formRef.current.variation_qty.value;
            let variation_reason = formRef.current.variation_reason.value;

            //TODO Closure PUT Fields

        }

        onSubmit && onSubmit(fields);
    }

    const canBeClosed = () => {
        //has to have children

        return true;
    }

    const handleUndo = (e) => {
        e.preventDefault();

        onUndo && onUndo();
    }

    useEffect(() => {
        if (movement && formRef.current) {
            movement.article?.id && movement.article?.name && setArticle({
                id: movement.article.id,
                name: movement.article.name
            });
            movement.color?.id && movement.color?.name && setColor({id: movement.color.id, name: movement.color.name});
            movement.document?.type?.id && movement.document?.type?.name && setDocument({
                id: movement.document.type.id,
                name: movement.document.type.name
            });
            movement.processing?.id && movement.processing?.name && setProcess({
                id: movement.processing.id,
                name: movement.processing.name
            });
            movement.subcontractor && setSubcontractor(movement.subcontractor);
            movement.client && setClient(movement.client);
            formRef.current.nrpz.value = movement.nrpz;
            formRef.current.nrdocmov.value = movement?.document?.nrdoc ?? movement?.document?.nrdocfor ?? "";
            formRef.current.datadocmov.value = movement?.document?.datadoc ?? movement?.document?.datadocfor ?? "";
            formRef.current.qta1.value = movement?.qta1;
            formRef.current.prezzo1.value = movement?.prezzo1;
            formRef.current.qta2.value = movement?.qta2;
            formRef.current.prezzo2.value = movement?.prezzo2;
            formRef.current.qta3.value = movement?.qta3;
            formRef.current.prezzo3.value = movement?.prezzo3;
            formRef.current.notemov.value = movement?.notemov;

            if(movement.closure) {
                if(movement.closure.closed) formRef.current.closed_checkbox.checked = true;
                if(movement.closure.closure_note) formRef.current.closure_notes.value = movement.closure.closure_note;

                if(movement.closure.variation_date) formRef.current.variation_date.value = movement.closure.variation_date;
                if(movement.closure.variation_qty) formRef.current.variation_qty.value = movement.closure.variation_qty;
                if(movement.closure.variation_reason) formRef.current.variation_reason.value = movement.closure.variation_reason;
            }
        }
    }, [movement]);

    return (
        <form onSubmit={handleSubmit} ref={formRef}>
            {fieldsLocked() &&
            <p>Alcuni valori non sono modificabili.
                Per modificarli ugualmente, eliminare prima tutti i movimenti concatenati a questo.</p>
            }

            {canBeClosed() &&
            <div>
                <RowAligned>
                    <div className="mb-2 text-xs font-medium hover:text-blue-400 hover:underline cursor-pointer"
                          onClick={() => setDisplayClose(!displayClose)}>Opzioni di chiusura movimento</div>
                </RowAligned>


                <div className={`flex flex-col gap-2 p-2 border rounded mb-4 ${displayClose ? "" : "hidden"}`}>
                    <VerticalLabelledField label="Chiusura movimento" >
                        <div className="flex flex-row items-center gap-2">
                            <input type="checkbox" name="closed_checkbox"/>
                            <div>Chiuso</div>
                            <div className="text-xs">( Ritiene il movimento chiuso, anche se il conto delle pelli non corrisponde)</div>
                        </div>

                    </VerticalLabelledField>
                    <div className="flex flex-row items-start gap-2">

                    <VerticalLabelledField label="Quantità">
                        <BaseInput type="number" name="variation_qty" placeholder="nr" className="w-12"/>
                    </VerticalLabelledField>
                    <VerticalLabelledField label="Variazione inventariale per:">
                        <Select>
                            <option value="" disabled selected>Seleziona variazione</option>
                            <option value="CAR">Carico</option>
                            <option value="SCA">Scarico</option>
                        </Select>
                    </VerticalLabelledField>
                    <VerticalLabelledField label="In data">
                        <BaseInput type="date" name="variation_date" />
                    </VerticalLabelledField>
                    <VerticalLabelledField label="Motivazione">
                        <Select name="variation_reason">
                        <option value="" disabled selected>Seleziona motivazione</option>
                        <option value="Riscontrate pelli in meno">Riscontrate pelli in meno</option>
                        <option value="Riscontrate pelli in più">Riscontrate pelli in più</option>
                        <option value="Pelli rottamate">Pelli rottamate</option>
                        </Select>
                    </VerticalLabelledField>
                    </div>
                    <VerticalLabelledField label="Note">
                        <textarea className="w-full p-1 bg-black bg-opacity-5 rounded shadow-inner"
                                  placeholder="Note chiusura movimento"
                                  name="closure_notes"/>
                    </VerticalLabelledField>
                </div>
            </div>

            }


            <VerticalLabelledField label="Articolo">
                <SearchSelectArticle value={article} onChange={setArticle}/>
            </VerticalLabelledField>

            <VerticalLabelledField label="Numero Pelli movimentate">
                {fieldsLocked() && <div>{movement.nrpz}</div>}
                <BaseInput type="text" name="nrpz" placeholder="Pezzi"
                           className={fieldsLocked() ? "hidden" : ""}/>
            </VerticalLabelledField>

            <VerticalLabelledField label="Lavorazione eseguita">
                <SelectProcess value={process} onChange={setProcess}/>
            </VerticalLabelledField>

            <VerticalLabelledField label="Colore">
                <SelectColors value={color} onChange={setColor}/>
            </VerticalLabelledField>

            <div>DOCUMENTO</div>
            <RowAligned>
                <VerticalLabelledField label="Tipo documento">
                    <SearchSelectDocuments value={document} onChange={setDocument}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Nr">
                    <BaseInput type="text" placeholder="Nr. Documento" name="nrdocmov"/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Data">
                    <BaseInput type="date" name="datadocmov"/>
                </VerticalLabelledField>
            </RowAligned>

            <div>COSTI MOVIMENTO</div>
            <RowAligned>
                <VerticalLabelledField label="Quantità">
                    <BaseInput type="number" step={'any'} placeholder='0.00000' name="qta1"/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Prezzo">
                    <BaseInput type="number" step={'any'} placeholder='0.00000' name="prezzo1"/>
                </VerticalLabelledField>
            </RowAligned>

            <RowAligned className={movement && (movement?.qta2 || movement?.prezzo2) ? "gap-1" : "hidden"}>
                <VerticalLabelledField label="Quantità">
                    <BaseInput type="number" step={'any'} placeholder='0.00000' name="qta2"/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Prezzo">
                    <BaseInput type="number" step={'any'} placeholder='0.00000' name="prezzo2"/>
                </VerticalLabelledField>
            </RowAligned>


            <RowAligned className={movement && (movement?.qta3 || movement?.prezzo3) ? "" : "hidden"}>
                <VerticalLabelledField label="Quantità">
                    <BaseInput type="number" step={'any'} placeholder='0.00000' name="qta3"/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Prezzo">
                    <BaseInput type="number" step={'any'} placeholder='0.00000' name="prezzo3"/>
                </VerticalLabelledField>
            </RowAligned>

            {sold ?
                <VerticalLabelledField label="Cliente">
                    <SelectClient value={client} onChange={setClient}/>
                </VerticalLabelledField>
                :
                <div>
                    <div className="uppercase text-gray-600 mt-4">INVIO PELLI A:</div>
                    <VerticalLabelledField label="Terzista">
                        <SelectSubcontractor value={subcontractor} onChange={setSubcontractor}/>
                    </VerticalLabelledField>
                </div>

            }

            <VerticalLabelledField label="Note">
                <textarea className="bg-black bg-opacity-5 rounded shadow-inner w-full py-1 px-2" placeholder="Note" name="notemov"/>
            </VerticalLabelledField>

            <RowAligned className="justify-between mt-2">
                <Button type="button" onClick={handleUndo}>Annulla</Button>
                <Button type="submit">Conferma</Button>
            </RowAligned>
        </form>
    );
};

export default EditMovementForm;