import React from 'react';

const Select = ({className, ...props}) => {
    return (
        <select  className={`p-1 bg-black bg-opacity-5 required:bg-yellow-500 required:bg-opacity-30 focus:outline-2 outline-offset-0 focus:outline-gray-600 max-w-full rounded outline-none shadow-md ${className}`} {...props}>
            {props.children}
        </select>
    );
};

export default Select;