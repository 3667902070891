import React, {useEffect} from 'react';
import {doGetDocumentTypes} from "../../../logics/registry";
import {useRegistry} from "../../../utilities/contexts/registry-context";
import Select from "../Select";
import useAsync from "../../../utilities/customHooks/async-call";

const SelectDocument = ({value, onChange, required = false, className, ...props}) => {

    const {documents, loadDocuments} = useRegistry();
    const {call} = useAsync();

    useEffect(() => {
        const load = async () => {
            if(!documents || !documents.length > 0) {
                console.log("Getting docs");
                await call(
                    () => doGetDocumentTypes(),
                    (res) => {
                        if(res)
                            loadDocuments(res);
                    },
                    (err) => console.log(err)
                )
            }
        }

        load();
    }, []);

    return (
        <Select value={value}
                onChange={onChange}
                required={required}
                className={className}
                {...props}>
            <option value="" disabled>Seleziona un documento</option>
            {documents && documents.length > 0 &&
            documents.map((doc, i) => <option value={doc.idtipodoc}
                                                           key={i}>{doc.nome}</option>)
            }
        </Select>
    );
};

export default SelectDocument;