import React from 'react';

const TableHead = ({tr,...props}) => {
    return (
        <thead {...props}>
        {tr && tr}
        <tr className="border border-black">
            {props.children}
        </tr>
        </thead>
    );
};

export default TableHead;