import React from 'react';
import {formatInt, formatPrice, parseDate} from "../../../utilities/data/parser";
import {useQueries} from "../../../utilities/contexts/queries-context";
import Table from "../General/Printable/Table";
import TableHead from "../General/Printable/TableHead";
import Th from "../General/Printable/Th";
import Td from "../General/Printable/Td";
import DocumentInfo from "../../UI/DataDisplay/DocumentInfo";

const SalesByClientPrintable = ({onApproval, crusts}) => {

    const {salesByClient, byClientDates, salesByClientTotals} = useQueries();

    return (
        <div>
            <div className="mb-2 font-bold text-center">Vendite
                dal {parseDate(byClientDates.start)} al {parseDate(byClientDates.end)}</div>
            {salesByClient && salesByClient.length > 0 &&
            salesByClient.map((sales, i) =>
                <div className="mb-4" key={"table_" + i}>
                    <Table className="w-full ">
                        <TableHead tr={
                            <tr className="border border-black">
                                <th colSpan={7}>{onApproval ? "C/TO VISIONE" : "VENDITE"} {sales?.name}</th>
                            </tr>
                        }>
                            <Th>Lotto</Th>
                            <Th>Documento</Th>
                            <Th>Articolo</Th>
                            <Th className="border-l border-black text-center">Nr.Pz</Th>
                            <Th className="border-l border-black">
                                <div className="flex flex-col items-center text-center">
                                    <div>Scelta I</div>
                                    <div>(Mq/€)</div>
                                </div>
                            </Th>
                            <Th className="border-l border-black">
                                <div className="flex flex-col items-center text-center">
                                    <div>Scelta II</div>
                                    <div>(Mq/€)</div>
                                </div>
                            </Th>
                            <Th className="border-l border-black">
                                <div className="flex flex-col items-center text-center">
                                    <div>Scelta II</div>
                                    <div>(Mq/€)</div>
                                </div>
                            </Th>
                        </TableHead>
                        <tbody>
                        {sales.sold_leathers && sales.sold_leathers.length > 0 &&
                        sales.sold_leathers.map((row, i) =>
                            <tr key={"sold_" + i}>
                                <Td>{row?.nrlotto}</Td>
                                <Td><DocumentInfo document={row?.document} hideType={true} hideCompany={true}/> </Td>
                                <Td>{row?.article}</Td>
                                <Td className="border-l border-black text-right pr-2">{row?.nrpz}</Td>
                                <Td className="text-center border-l border-black">{row?.choices?.I}</Td>
                                <Td className="text-center border-l border-black">{row?.choices?.II}</Td>
                                <Td className="text-center border-l border-black">{row?.choices?.III}</Td>
                            </tr>
                        )}
                        </tbody>
                        <tr className="border border-black">
                            <td colSpan={7} className="text-center">
                                Metri quadri totali: {formatPrice(sales?.totals?.sold_leathers?.mq)} - vendite
                                totali: {formatInt(sales?.totals?.sold_leathers?.nrpz)} Pz -
                                Importo: {formatPrice(sales?.totals?.sold_leathers?.amount)} €
                            </td>
                        </tr>
                    </Table>

                    {sales.unsold_leathers &&
                    <Table key={i} className="w-full mb-2">
                        <TableHead tr={
                            <tr className="border border-black">
                                <th colSpan={5}>RESI {sales?.name}</th>
                            </tr>
                        }>
                            <Th>Lotto</Th>
                            <Th>Documento</Th>
                            <Th>Articolo</Th>
                            <Th className="border-l border-black text-center">Nr.Pelli</Th>
                            <Th className="border-l border-black text-center">Note</Th>
                        </TableHead>
                        <tbody>
                        {sales.unsold_leathers && sales.unsold_leathers.length > 0 &&
                        sales.unsold_leathers.map((row, i) =>
                            <tr key={"unsold_" + i}>
                                <Td>{row?.nrlotto}</Td>
                                <Td><DocumentInfo document={row?.document} hideType={true} hideCompany={true}/> </Td>
                                <Td>{row?.article}</Td>
                                <Td className="border-l border-black text-right pr-2">{row?.nrpz}</Td>
                                <Td className="border-l border-black text-right pr-2">{row?.notes}</Td>
                            </tr>
                        )}
                        </tbody>
                        <tr className="border border-black">
                            <td colSpan={7} className="text-center">
                                Metri quadri totali: {formatPrice(sales?.totals?.unsold_leathers?.mq)} - vendite
                                totali: {formatInt(sales?.totals?.unsold_leathers?.nrpz)} Pz -
                                Importo: {formatPrice(sales?.totals?.unsold_leathers?.amount)} €
                            </td>
                        </tr>
                    </Table>
                    }

                </div>
            )
            }

            {crusts &&
            <Table className="w-full">
                <TableHead tr={
                    <tr className="w-full">
                        <Th colSpan={7} className="text-center">VENDITA CROSTE</Th>
                    </tr>
                }>
                    <Th>Lotto</Th>
                    <Th>Documento</Th>
                    <Th>Fornitore</Th>
                    <Th>Importo</Th>
                    <Th>Data</Th>
                </TableHead>
                <tbody>
                {crusts.sold && crusts.sold.length > 0 &&
                crusts.sold.map((row, i) =>
                    <tr key={i}>
                        <Td>{row.nrlotto}</Td>
                        <Td>{row.doc}</Td>
                        <Td>{row?.supplier?.name}</Td>
                        <Td>{formatPrice(row.amount)}</Td>
                        <Td>{parseDate(row.date)}</Td>
                    </tr>
                )
                }
                <tr>
                    <Td colSpan={5} className="text-center">
                        Totali: {formatPrice(crusts?.totals?.amounts)}
                    </Td>
                </tr>
                </tbody>
            </Table>
            }

            {salesByClientTotals &&
            <div style={{fontSize: "10px"}}>
                <div className="font-bold">Periodo: dal {parseDate(byClientDates.start)} al {parseDate(byClientDates.end)}</div>
                <table>
                    <tr>
                        <td/>
                        <td>VENDITE</td>
                        <td>RESI</td>
                    </tr>
                    <tr>
                        <td>Totale nr.pezzi:</td>
                        <td>{salesByClientTotals?.sold_leathers?.nrpz}</td>
                        <td>{salesByClientTotals?.unsold_leathers?.nrpz}</td>
                    </tr>
                    <tr>
                        <td>Totale mq:</td>
                        <td>{salesByClientTotals?.sold_leathers?.mq}</td>
                        <td>{salesByClientTotals?.unsold_leathers?.mq}</td>
                    </tr>
                    <tr>
                        <td>Totale importo:</td>
                        <td>{salesByClientTotals?.sold_leathers?.amount}</td>
                        <td>{salesByClientTotals?.unsold_leathers?.amount}</td>
                    </tr>
                </table>
            </div>
            }

        </div>
    );
};

export default SalesByClientPrintable;