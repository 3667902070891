import React from 'react';

const BaseInput = React.forwardRef(({className = '', step = 'any', ...props}, ref) => {

    const {type = "text", label = ""} = props;

    return (
        <div className="w-full">
            {label !== "" &&
            <div className="p-0 m-0 text-xs uppercase text-gray-600">{label}</div>
            }
            <input type={type} {...props} step={step}
                   className={`${className} w-full p-1 bg-black bg-opacity-5 rounded shadow-inner `}/>
        </div>
    )
});

export default BaseInput;
