import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {GiCancel} from "react-icons/gi";

const MAX_OPTIONS_LENGTH = 20;

const SelectOption = ({option, onClick, selected}) => {

    const handleClick = () => {
        onClick && onClick(option);
    }

    return (
        <button
            type="button"
            className={`text-left ${selected ? "bg-blue-600 text-white" : ""} hover:bg-gray-300`}
            onClick={handleClick}
        >
            {option.name}
        </button>
    )
}

const SearchSelect = ({options, placeholder, onChange, value, required = false, ...props}) => {
    const [selectedValue, setSelectedValue] = useState(value ? value : null);
    const [selectedText, setSelectedText] = useState(value && value.name ? value.name : "");
    const [showOptions, setShowOptions] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(options ? options : []);
    const [arrowSelectedIndex, setArrowSelectedIndex] = useState(-1);

    const indexRef = React.useRef(arrowSelectedIndex);
    const setIndexRef = data => {
        setArrowSelectedIndex(data);
        indexRef.current = data;
    }

    const inputRef = useRef();

    const handleFocus = () => {
        setIndexRef(-1);
        setShowOptions(true);
    }

    const handleBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            let current = e.currentTarget;
            let related = e.relatedTarget;
            let parent = current.parentNode;

            if (!parent.contains(related)) {
                setShowOptions(false);
            } else {
                inputRef.current.focus();
            }
        }
    }

    const handleOptionClick = (option) => {
        if (option.name) {
            setSelectedText(option.name);
            setSelectedValue(option);
            onChange && onChange(option);
        } else {
            onChange && onChange(null);
        }
        setShowOptions(false);
        inputRef.current.blur();
    }

    const getFilteredOptions = () => {
        if (selectedText === "") {
            setFilteredOptions(options && options.length > 0 ? [...options.slice(0, MAX_OPTIONS_LENGTH)] : []);
        } else {
            let filtered = options.filter(option => {
                if (option && option.name && selectedText)
                    return option.name.toLowerCase().includes(selectedText.toLowerCase())
                else return false;
            })
            setFilteredOptions([...filtered.slice(0, MAX_OPTIONS_LENGTH)]);
        }
    }

    const handleTextChange = (e) => {
        if (selectedValue && e.target.value !== selectedValue.name) {
            setSelectedValue(null);
        }
        setSelectedText(e.target.value);
        setIndexRef(-1);
    }

    const handleRemoveOption = () => {
        setSelectedValue(null);
        setSelectedText("");

        onChange && onChange(null);
    }

    const handleKeyPress = (event) => {
        let currentIndex = indexRef.current;

        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            let optionsLength = filteredOptions.length;
            if (event.key === "ArrowDown") {
                if (filteredOptions && optionsLength > 0) {
                    if (currentIndex < 0) {
                        currentIndex = 0;
                    } else {
                        if (currentIndex < optionsLength - 1) {
                            currentIndex = currentIndex + 1;
                        } else {
                        }
                    }
                }
            } else {
                if (filteredOptions && optionsLength > 0) {
                    if (currentIndex < 0) {
                        currentIndex = 0;
                    } else {
                        if (currentIndex > 0) {
                            currentIndex = currentIndex - 1;
                        }
                    }
                }
            }
            setIndexRef(currentIndex);
        }

        if (event.key === 'Enter') {
            if (currentIndex >= 0 && filteredOptions[currentIndex])
                handleOptionClick(filteredOptions[currentIndex]);
        }

    }

    useEffect(() => {
        setSelectedText(value && value.name ? value.name : "");
        setSelectedValue(value ? value : null);
    }, [value]);

    useEffect(() => {
        setFilteredOptions(options && options.length > 0 ? [...options.slice(0, MAX_OPTIONS_LENGTH)] : [])
    }, [options]);

    useEffect(() => {
        getFilteredOptions();
    }, [selectedText]);

    // useEffect(() => {
    //     console.log('ADDING LISTENER')
    //     const eventRef = inputRef.current;
    //     if (eventRef)
    //         eventRef.addEventListener('keydown', (event) => handleKeyPress(event));
    //     return () => {
    //         if (eventRef)
    //             eventRef.removeEventListener('keydown', (event) => handleKeyPress(event));
    //     }
    // }, []);

    return (
        <div className="relative flex-1">
            <input type="text"
                   ref={inputRef}
                   value={selectedValue ? selectedValue.name : selectedText}
                   onKeyDown={handleKeyPress}
                   onChange={handleTextChange}
                   onFocus={handleFocus}
                   onBlur={handleBlur}
                   placeholder={placeholder ? placeholder : "Cerca"}
                   required={required}
                   className={`bg-black bg-opacity-5 rounded required:bg-yellow-500 required:bg-opacity-30 shadow-inner w-full pl-2 h-6 ${selectedValue ? "bg-opacity-20 bg-blue-400 outline-none font-semibold" : ""}`}
            />
            {selectedValue &&
            <span className="absolute right-1 top-1 text-black cursor-pointer hover:text-red-400"
                  onClick={handleRemoveOption}><GiCancel/></span>}
            {showOptions &&
            <div
                className="absolute top-6 left-0 right-0 bg-white shadow p-2 z-10 flex flex-col max-h-80 overflow-y-auto rounded">
                {options && options.length > 0 && filteredOptions.length > 0
                    ? filteredOptions.map((option, i) => <SelectOption key={i}
                                                                       option={option}
                                                                       selected={i === arrowSelectedIndex}
                                                                       onClick={() => handleOptionClick(option)}/>)
                    : <div className="text-gray-400 italic text-sm font-normal">Nessuna opzione disponibile</div>
                }
            </div>
            }
        </div>
    );
};

export default SearchSelect;