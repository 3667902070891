import React, {useEffect, useState} from 'react';
import {useRegistry} from "../utilities/contexts/registry-context";
import useFilter from "../utilities/customHooks/filter";
import useAsync from "../utilities/customHooks/async-call";
import {doGetArticles} from "../logics/registry";
import Layout from "../components/UI/Layout";
import BaseInput from "../components/UI/BaseInput";
import {AiOutlineReload} from "react-icons/ai";
import ArticlesTable from "../components/Articles/ArticlesTable";
import Loading from "../components/UI/Loading";

const Articles = () => {

    const {articles, loadArticles} = useRegistry();
    const {call} = useAsync();

    const [loading, setLoading] = useState(false);

    const filterByName = (article) => {
        return article.nomeart.toLowerCase().includes(filterText.toLowerCase());
    }

    const {filterText, setFilterText, filteredData} = useFilter(articles, filterByName);

    const reload = async () => {
        setLoading(true);
        setFilterText("");
        await call(
            () => doGetArticles(),
            (res) => {
                loadArticles(res);
            },
        );
        setLoading(false);
    }

    useEffect(() => {
        const load = async () => {
            await reload();
        }

        load();
    }, []);

    return (
        <Layout title="Articoli">
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
                <div className="mb-4">
                    <BaseInput placeholder={'Cerca articolo'}
                               className={'w-full mb-1'}
                               value={filterText}
                               onChange={(e) => setFilterText(e.target.value)}/>
                    <div className="text-sm pl-1 flex flex-row gap-1 items-center">
                        <span>Articoli elencati:</span>
                        <span>{filteredData.length}</span>
                        <AiOutlineReload onClick={reload} className="hover:text-gray-200 cursor-pointer"
                                         title="Ricarica articoli"/>
                    </div>
                </div>
                {loading ?
                    <Loading label="Caricamento articoli in corso"/>
                    :
                    <div className="h-full overflow-y-auto">
                        <ArticlesTable articles={filteredData}/>
                    </div>
                }
            </div>
        </Layout>
    );
};

export default Articles;