import React from 'react';
import Select from "../../../../UI/Select";
import VerticalLabelledField from "../../../../UI/Fields/VerticalLabelledField";

const ArticleColorInput = ({articlesColors, value, setValue}) => {
    return (
        <VerticalLabelledField label="Articolo e colore" className="mb-3">
            <Select value={value}
                    onChange={(e) => setValue(e.target.value)}
                    required={!value}>
                <option value="" disabled selected>Seleziona un articolo | colore</option>
                {articlesColors && articlesColors.length > 0 &&
                articlesColors.map((art, i) => <option value={art.id}
                                                            key={i}>{art.name}</option>)
                }
            </Select>
        </VerticalLabelledField>
    );
};

export default ArticleColorInput;
