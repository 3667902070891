import React from 'react';
import {useNavigate} from "react-router-dom";
import {CgListTree} from "react-icons/cg";
import {RiEditFill} from "react-icons/ri";

const LotRow = ({lot, ...props}) => {

    const navigate = useNavigate();

    const handleMovements = async () => {
        navigate(`../${lot.nr}/movimenti`);
    }

    const showEditLot = () => {
        navigate(`../${lot.nr}`);
    }

    const goToLot = () => {
        navigate(`../${lot.nr}`);
    }

    return (
        <tr className="hover:bg-blue-400  odd:bg-blue-100 cursor-pointer" onDoubleClick={goToLot} title="Doppio Click per aprire il lotto">
            <td className="pl-2">{lot.nr}</td>
            <td>{lot.supplier && lot.supplier.name}</td>
            <td>{lot.date}</td>
            <td>{lot.article && lot.article.name}</td>
            <td>{lot.color && lot.color.name}</td>
            <td className="text-right">{lot.leathers_amount}</td>
            <td className="text-right">{lot.in_house_leathers}</td>
            <td className="text-right">{lot.returned_leathers}</td>
            <td className="text-right">{lot.sold_leathers}</td>
            <td>
                <div className="flex flex-row items-center justify-end pr-1 gap-1">
                    {lot.has_movements &&
                    <CgListTree title={"Movimenta"} onClick={handleMovements} className="cursor-pointer"/>}
                    <RiEditFill title={"Modifica"} onClick={showEditLot} className="cursor-pointer"/>
                </div>
            </td>
        </tr>
    )
}

export default LotRow;
