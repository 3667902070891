import types from "../types/types";
import {type} from "@testing-library/user-event/dist/type";

//IN HOUSE
function loadInHouseRows(rows) {
    return {
        type: types.QUERIES_IN_HOUSE_LOAD_ROWS,
        rows,
    }
}

function loadInHouseTotals(totals) {
    return {
        type: types.QUERIES_IN_HOUSE_LOAD_TOTALS,
        totals,
    }
}

function toggleInHouseSelected(row) {
    return {
        type: types.QUERIES_IN_HOUSE_TOGGLE_SELECTED,
        row,
    }
}

function toggleAllInHouse() {
    return {
        type: types.QUERIES_IN_HOUSE_TOGGLE_ALL,
    }
}

//SUBCONTRACTOR
function loadSubcontractorRows(rows) {
    return {
        type: types.QUERIES_SUBCONTRACTOR_LOAD_ROWS,
        rows,
    }
}

function loadSubcontractorTotals(totals) {
    return {
        type: types.QUERIES_SUBCONTRACTOR_LOAD_TOTALS,
        totals,
    }
}

function toggleSubcontractorSelected(row) {
    return {
        type: types.QUERIES_SUBCONTRACTOR_TOGGLE_SELECTED,
        row,
    }
}

//WEEKLY
function loadWeeklyRows(rows) {
    return {
        type: types.QUERIES_WEEKLY_LOAD_ROWS,
        rows,
    }
}

function loadWeeklyTotals(totals) {
    return {
        type: types.QUERIES_WEEKLY_LOAD_TOTALS,
        totals,
    }
}

function setWeeklyDates(start, end) {
    return {
        type: types.QUERIES_WEEKLY_SET_DATES,
        start,
        end,
    }
}

//SALES
function loadSalesByClient(sales) {
    return {
        type: types.QUERIES_LOAD_SALES_BY_CLIENT,
        sales,
    }
}

function setByClientDates(start, end) {
    return {
        type: types.QUERIES_SET_BY_CLIENT_DATES,
        start,
        end,
    }
}

function setSalesByClientTotals(totals) {
    return {
        type: types.QUERIES_SET_BY_CLIENT_TOTALS,
        totals,
    }
}

//SUBCONTRACTOR DOCUMENTS
function loadSubcontractorQueryRows(rows) {
    return {
        type: types.QUERIES_SUBCONTRACTOR_DOCUMENTS_LOAD_ROWS,
        rows,
    }
}

function setSubcontractorDocumentsForm(subcontractor, start, end) {
    return {
        type: types.QUERIES_SUBCONTRACTOR_DOCUMENTS_SET_FORM,
        subcontractor,
        start,
        end,
    }
}

export const actions = {
    loadInHouseRows,
    loadInHouseTotals,
    toggleInHouseSelected,
    toggleAllInHouse,
    loadSubcontractorRows,
    loadSubcontractorTotals,
    toggleSubcontractorSelected,
    loadWeeklyRows, loadWeeklyTotals, setWeeklyDates,
    loadSalesByClient, setByClientDates, setSalesByClientTotals,
    loadSubcontractorQueryRows, setSubcontractorDocumentsForm,
}