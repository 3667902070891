import React from 'react';
import BaseInput from "../UI/BaseInput";
import Button from "../UI/Button";
import {doPostClient} from "../../logics/registry";
import useAsync from "../../utilities/customHooks/async-call";
import {useRegistry} from "../../utilities/contexts/registry-context";

const NewClientForm = ({...props}) => {

    const {call} = useAsync();
    const {clients, loadClients, setCreatePanelOpen} = useRegistry();

    const handleSubmit = async (e) => {
        e.preventDefault();

        let params = new URLSearchParams();
        params.append('ragsoccl', e.target.name.value);
        params.append('contatto', e.target.contatto.value);
        params.append('email', e.target.email.value);
        params.append('indcl', e.target.indcl.value);
        params.append('pivacl', e.target.pivacl.value);
        params.append('note', e.target.note.value);

        await call(
            () => doPostClient(params),
            (res) => {
                if(res) {
                    loadClients([res, ...clients])
                }
                setCreatePanelOpen(false);
            },
        )

    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col" style={{minWidth: "400px"}}>
            <BaseInput name={'name'}
                       className="mb-1"
                       label={'Ragione sociale'}
                       required
                       placeholder={'Ragione Sociale'}/>

            <BaseInput name={'contatto'}
                       className="mb-1"
                       label="contatto"
                       placeholder={'Contatto'}/>
            <BaseInput name={'email'}
                       className="mb-1"
                       label="email"
                       placeholder={'Email'}/>

            <BaseInput name={'indcl'}
                       className="mb-1"
                       label="indirizzo"
                       placeholder={'Indirizzo'}/>
            <BaseInput name={'pivacl'}
                       className="mb-1"
                       label="PIVA"
                       placeholder={'PIVA'}/>

            <BaseInput name={'note'}
                       className="mb-8"
                       label="note"
                       placeholder={'Note'}/>

            <Button type='submit'>Conferma</Button>
        </form>
    );
}

export default NewClientForm;
