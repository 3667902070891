import React from 'react';

const NoMatch = () => {
    return (
        <div className="p-1 bg-red-400 font-bold text-center text-white">
            Sezione in costruzione
        </div>
    );
};

export default NoMatch;