import types from "../types/types";

function setRegistryPanelOpen(status) {
    return {
        status,
        type: types.REGISTRY_SET_PANEL_OPEN,
    }
}

function setCreatePanelOpen(status) {
    return {
        status,
        type: types.REGISTRY_SET_CREATE_PANEL_OPEN,
    }
}

function loadClients(clients) {
    return {
        clients,
        type: types.REGISTRY_LOAD_CLIENTS
    }
}

function setCurrentClient(client) {
    return {
        client,
        type: types.REGISTRY_SET_CURRENT_CLIENT,
    }
}

function updateClient(client) {
    return {
        client,
        type: types.REGISTRY_UPDATE_CLIENT,
    }
}

function loadSuppliers(suppliers) {
    return {
        suppliers,
        type: types.REGISTRY_LOAD_SUPPLIERS,
    }
}

function setCurrentSupplier(supplier) {
    return {
        supplier,
        type: types.REGISTRY_SET_CURRENT_SUPPLIER,
    }
}

function updateSupplier(supplier) {
    return {
        supplier,
        type: types.REGISTRY_UPDATE_SUPPLIER,
    }
}

function loadSubcontractors(subcontractors) {
    return {
        subcontractors,
        type: types.REGISTRY_LOAD_SUBCONTRACTORS,
    }
}

function setCurrentSubcontractor(subcontractor) {
    return {
        subcontractor,
        type: types.REGISTRY_SET_CURRENT_SUBCONTRACTOR,
    }
}

function updateSubcontractor(subcontractor) {
    return {
        subcontractor,
        type: types.REGISTRY_UPDATE_SUBCONTRACTOR,
    }
}

function loadArticles(articles) {
    return{
        articles,
        type: types.REGISTRY_LOAD_ARTICLES
    }
}

function loadDocuments(documents) {
    return{
        documents,
        type: types.REGISTRY_LOAD_DOCUMENTS
    }
}

function loadProcesses(processes) {
    return {
        processes,
        type: types.REGISTRY_LOAD_PROCESSES,
    }
}

function loadColors(colors) {
    return {
        colors,
        type: types.REGISTRY_LOAD_COLORS,
    }
}

function loadMeasureUnits(measure_units) {
    return {
        measure_units,
        type: types.REGISTRY_LOAD_MEASURE_UNITS,
    }
}

function loadChoices(choices) {
    return {
        choices,
        type: types.REGISTRY_LOAD_CHOICES,
    }
}

function loadLotCategories(categories) {
    return {
        categories,
        type: types.REGISTRY_LOAD_LOT_CATEGORIES,
    }
}

const actions = {
    setRegistryPanelOpen,
    setCreatePanelOpen,
    loadClients,
    setCurrentClient,
    updateClient,
    loadSuppliers,
    setCurrentSupplier,
    updateSupplier,
    loadSubcontractors,
    setCurrentSubcontractor,
    updateSubcontractor,
    loadArticles,
    loadDocuments,
    loadProcesses,
    loadColors,
    loadMeasureUnits,
    loadChoices,
    loadLotCategories,
}

export default actions;
