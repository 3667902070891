import React, {useState} from 'react';
import BaseInput from "../UI/BaseInput";
import Button from "../UI/Button";
import {useNavigate} from "react-router-dom";
import useAuth from '../../utilities/customHooks/auth';
import useToast from "../../utilities/customHooks/toast";
import {useLocation} from "react-router";
import Loading from "../UI/Loading";

const LoginForm = () => {
    const navigate = useNavigate();


    const {login} = useAuth();
    const {toastError} = useToast();
    const {state} = useLocation();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let username = e.target.username.value;
        let password = e.target.password.value;
        await setLoading(true);
        let authed = await login(username, password);
        await setLoading(false);
        if (authed) navigate(state?.path || '/lotti/lista');
        else toastError("Errore di login");
    }

    return (
        <form onSubmit={handleSubmit} className="p-4 shadow-md rounded flex flex-col max-w-screen-sm">
            <div className="mb-2">Effettua l'accesso</div>
            {loading
                ? <Loading label={"Accesso in corso"} />
                : <>
                    <BaseInput name="username" placeholder="utente" className="mb-2"/>
                    <BaseInput name="password" placeholder="password" type='password' className='mb-4'/>
                    <Button>ACCEDI</Button>
                </>
            }


        </form>
    )
}

export default LoginForm;
