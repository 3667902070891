import React from 'react';
import Section from "../../UI/Structure/Section";
import SelectClient from "../../UI/Selects/SelectClient";
import VerticalLabelledField from "../../UI/Fields/VerticalLabelledField";
import BaseInput from "../../UI/BaseInput";
import Row from "../../UI/Structure/Row";
import Button from "../../UI/Button";
import LotCategorySelect from "../General/LotCategorySelect";
import {useQueries} from "../../../utilities/contexts/queries-context";

const Search = ({
                    client, setClient, nrLotto, setNrLotto,
                    onSubmit, showTotals, setShowTotals, displayCrusts, setDisplayCrusts, displayOnApprovalOnly,
                    setDisplayOnApprovalOnly
                }) => {

    const {byClientDates, setByClientDates} = useQueries();

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit && onSubmit();
    }

    return (
        <Section collapsable={true} label="Stampa vendite clienti">
            <form className="w-full flex flex-row justify-between items-center" onSubmit={handleSubmit}>
                <Row className="gap-2">
                    <VerticalLabelledField label="Cliente">
                        <SelectClient value={client} onChange={setClient}/>
                    </VerticalLabelledField>

                    <Row className="gap-2">
                        <VerticalLabelledField label="Dal">
                            <BaseInput type="date"
                                       value={byClientDates.start}
                                       required
                                       onChange={(e) => setByClientDates(e.target.value, byClientDates.end)}/>
                        </VerticalLabelledField>
                        <VerticalLabelledField label="Al">
                            <BaseInput type="date"
                                       value={byClientDates.end}
                                       required
                                       onChange={(e) => setByClientDates(byClientDates.start, e.target.value)}/>
                        </VerticalLabelledField>
                    </Row>
                    <VerticalLabelledField label="Ricerca per gruppo di lotti">
                        <LotCategorySelect classname="w-full" value={nrLotto} onChange={(e) => setNrLotto(e.target.value)} />
                    </VerticalLabelledField>

                    <VerticalLabelledField label="Nr. lotto">
                        <BaseInput type="text" placeholder="es: ZP22" value={nrLotto} onChange={(e) =>  setNrLotto(e.target.value)}/>
                    </VerticalLabelledField>

                    <VerticalLabelledField label="Visualizza totali generali">
                        <Row className="items-center gap-1">
                            <input type="checkbox"
                                   checked={showTotals}
                                   value={showTotals}
                                   onChange={(e) => setShowTotals(e.target.checked)}/>
                            <span>Visualizza</span>
                        </Row>
                    </VerticalLabelledField>

                    <VerticalLabelledField label="Visualizza vendita croste">
                        <Row className="items-center gap-1">
                            <input type="checkbox"
                                   checked={displayCrusts}
                                   value={displayCrusts}
                                   onChange={(e) => setDisplayCrusts(e.target.checked)}/>
                            <span>Visualizza</span>
                        </Row>
                    </VerticalLabelledField>
                    <VerticalLabelledField label="Visualizza solo c/to visione">
                        <Row className="items-center gap-1">
                            <input type="checkbox"
                                   checked={displayOnApprovalOnly}
                                   value={displayOnApprovalOnly}
                                   onChange={(e) => setDisplayOnApprovalOnly(e.target.checked)}/>
                            <span>Visualizza</span>
                        </Row>
                    </VerticalLabelledField>
                </Row>
                <Button>Ricerca</Button>
            </form>


        </Section>
    );
};

export default Search;