import React, {useEffect, useState} from 'react';
import Button from "../../../UI/Button";
import SelectColors from "../../../UI/Selects/SelectColors";
import SearchSelectArticle from "../../../UI/Selects/SearchSelectArticle";
import BaseInput from "../../../UI/BaseInput";
import SelectMeasureUnits from "../../../UI/Selects/SelectMeasureUnits";
import Row from "../../../UI/Structure/Row";
import RowAligned from "../../../UI/Structure/RowAligned";
import SelectChoice from "../../../UI/Selects/SelectChoice";
import HeaderSection from "../UI/HeaderSection";

const EditRowForm = ({row = null, onSubmit, onUndo}) => {

    const [color, setColor] = useState(row && row.color && row.color.id ? {idcol: row.color.id, nomecol: row.color.name} : null);
    const [article, setArticle] = useState(row && row.article ? row.article : null);
    const [choice, setChoice] = useState(row && row.idscelta ? row.idscelta : null);

    const formRef = React.useRef();

    const handleUndo = (e) => {
        console.log('undo')
        e.preventDefault();
        if(e.key === "Enter") {
            alert('Enter pressed');
        }
        onUndo && onUndo();
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let idart = article && article.id ? article.id : null;
        let idcol = color && color.id ? color.id : color && color.idcol ? color.idcol : null;
        let idscelta = choice;
        let nrpz = formRef.current.nrpz.value ? formRef.current.nrpz.value : null;
        let prezzo = formRef.current.prezzo.value ? formRef.current.prezzo.value : null;
        let qta = formRef.current.qta.value ? formRef.current.qta.value : null;
        let sp = formRef.current.sp.value ? formRef.current.sp.value : null;

        console.log(color);

        const fields = {
            idart,
            idcol,
            idscelta,
            nrpz,
            prezzo,
            qta,
            sp,
        }

        onSubmit && onSubmit(fields);
    }

    useEffect(() => {
        if(row) {
            setColor(row.color && row.color.id ? {idcol: row.color.id, nomecol: row.color.name} : null);
            setArticle(row.article ? row.article : null);
            setChoice(row.idscelta ? row.idscelta : null);
            formRef.current.nrpz.value = row.nrpz;
            formRef.current.prezzo.value = row.prezzo;
            formRef.current.qta.value = row.qta;
            formRef.current.sp.value = row.sp;
        }
    }, [row]);

    return (
        <form onSubmit={handleSubmit} ref={formRef}>

            <div className="p-0 m-0 text-xs uppercase text-gray-600">Articolo</div>
            <SearchSelectArticle value={article} onChange={(art) => setArticle(art)}/>

            <div className="p-0 m-0 text-xs uppercase text-gray-600">Colore</div>
            <SelectColors value={color} onChange={(col) => setColor(col)}/>

            <Row>
            <BaseInput label="nrpz"
                       type="number"
                       placeholder="nrpz"
                       step={1}
                       name="nrpz"/>

            <BaseInput label="Prezzo"
                       type="number"
                       placeholder="Prezzo"
                       step={'any'}
                       name="prezzo"/>
            </Row>

            <Row>
            <BaseInput label="Superficie"
                       type="number"
                       placeholder="Superficie"
                       step={'any'}
                       name="qta"/>

            <BaseInput label="Spessore"
                       type="number"
                       placeholder="Spessore"
                       step={'any'}
                       name="sp"/>
            </Row>

            <div className="p-0 m-0 text-xs uppercase text-gray-600">Scelta</div>
                <SelectChoice value={choice} onChange={(value) => setChoice(value)} />

            <RowAligned className="justify-between mt-4">
                <Button type="button" onClick={handleUndo}>Annulla</Button>
                <Button type="submit">Conferma</Button>
            </RowAligned>
        </form>
    );
};

export default EditRowForm;