import React from 'react';

const Button = ({onClick, type="", className, ...props}) => {

    const handleClick = (e) => {
        onClick && onClick(e);
    }

    return (
        <button onClick={handleClick} type={type} {...props} className={`p-1 inline-block shadow bg-secondary text-white bg-opacity-80 hover:bg-opacity-100 ${className}`}>
            {props.children}
        </button>
    )
}

export default Button;
