import React from 'react';
import {formatPrice, parseDate} from "../../../utilities/data/parser";
import Table from "../General/Printable/Table";
import TableHead from "../General/Printable/TableHead";
import Th from "../General/Printable/Th";
import Td from "../General/Printable/Td";

const WeeklyPrintable = ({startDate, endDate, sold, unsold, totals, ...props}) => {

    return (
        <div>
            <div className="flex flex-col items-center border-2 border-black mb-2">
                <div>Vendite dal {parseDate(startDate)} al {parseDate(endDate)}</div>
            </div>

            <Table className="w-full">
                <TableHead tr={<tr><Th colSpan={4}>VENDITE</Th></tr>}>
                    <Th className="text-left">Cliente</Th>
                    <Th className="text-left">Articolo - Colore</Th>
                    <Th className="text-right">Mq</Th>
                    <Th className="text-right">Pq</Th>
                </TableHead>
                <tbody>
                {sold && sold.length > 0 &&
                    sold.map((row,i) =>
                        <tr key={i}>
                            <Td>{row?.client?.name}</Td>
                            <Td>{row?.article?.name} - {row?.color?.name}</Td>
                            <Td className="text-right">{row?.mq}</Td>
                            <Td className="text-right">{row?.pq}</Td>
                        </tr>
                    )
                }
                <tr>
                    <Td colSpan={2} className="text-right">Totali:</Td>
                    <Td className="text-right">{formatPrice(totals?.total_mq_sold)}</Td>
                    <Td className="text-right">{formatPrice(totals?.total_pq_sold)}</Td>
                </tr>
                </tbody>
            </Table>
            <div>Vendite totali: {totals?.total_leathers_sold}</div>

            {unsold && unsold.length > 0 &&
            <div className="unsold_table mt-4">
                <div className="flex flex-col items-center border-2 border-black mb-2">
                    <div>Resi dal {parseDate(startDate)} al {parseDate(endDate)}</div>
                </div>

                <Table className="w-full">
                    <TableHead tr={<tr><Th colSpan={4}>RESI</Th></tr>}>
                        <Th className="text-left">Cliente</Th>
                        <Th className="text-left">Articolo - Colore</Th>
                        <Th className="text-right">Nr.pelli</Th>
                        <Th className="text-right">Mq</Th>
                    </TableHead>
                    <tbody>
                    {unsold.map((row,i) =>
                        <tr key={i}>
                            <Td>{row?.client?.name}</Td>
                            <Td>{row?.article?.name} - {row?.color?.name}</Td>
                            <Td className="text-right">{row?.nrpz}</Td>
                            <Td className="text-right">{row?.mq}</Td>
                        </tr>
                    )
                    }
                    <tr>
                        <Td colSpan={2} className="text-right">Totali:</Td>
                        <Td className="text-right">{formatPrice(totals?.total_leathers_unsold)}</Td>
                        <Td className="text-right">{formatPrice(totals?.total_mq_unsold)}</Td>
                    </tr>
                    </tbody>
                </Table>
                <div>Resi totali: {totals?.total_leathers_unsold}</div>
            </div>

            }
        </div>
    );
};

export default WeeklyPrintable;