import React from 'react';
import {BiPlus} from "react-icons/bi";
import {useLot} from "../../../../utilities/contexts/lot-context";
import TableHeader from "./TableHeader";
import Row from "./Row";
import RowAligned from "../../../UI/Structure/RowAligned";

const Table = ({onRowClick, className, onAdd, onDelete, ...props}) => {

    const {lotRows} = useLot();

    const handleRowClick = (row) => {
        onRowClick && onRowClick(row)
    }

    const handleDeleteClick = (row) => {
        onDelete && onDelete(row);
    }

    const handleAdd = () => onAdd && onAdd();

    return (
        <table className={`w-full ${className}`}>
            <TableHeader/>
            <tbody>
            {lotRows && lotRows.length > 0 ?
                lotRows.map((row, i) => <Row row={row} key={i} index={i} onClick={handleRowClick} onDelete={handleDeleteClick}/>)
                :
                <tr>
                    <td colSpan={7}>Nessuna riga</td>
                </tr>
            }
            </tbody>
            <tfoot className="bg-primary bg-opacity-40">
            <tr>
                <td colSpan={7}>
                    <RowAligned>
                        <RowAligned className="text-black hover:text-secondary cursor-pointer pl-2" onClick={handleAdd}>
                            <BiPlus className="font-bold"/>
                            <span>Aggiungi riga</span>
                        </RowAligned>
                    </RowAligned>
                </td>
            </tr>
            </tfoot>
        </table>
    );
};

export default Table;