import React from 'react';
import {FaTag} from "react-icons/fa";
import {useQueries} from "../../../utilities/contexts/queries-context";
import {formatInt, formatPrice} from "../../../utilities/data/parser";
import LotLink from "../../Lots/LotLink";
import DocumentInfo from "../../UI/DataDisplay/DocumentInfo";
import TableRow from "../General/TableRow";

const InHouseRow = ({row, displayCosts}) => {

    const {inHouseSelected, toggleInHouseSelected} = useQueries();

    const handleClick = () => {
        toggleInHouseSelected(row);
    }

    const isSelected = () => {
        return inHouseSelected.some(r => r.id === row.id);
    }

    return (
        <TableRow>
            <td className="pl-2">
                <FaTag title="Cartellino selezionato"
                       className={`${isSelected() ? "text-blue-500" : "text-gray-300"} hover:text-blue-600 cursor-pointer`} onClick={handleClick}/>
            </td>
            <td className={`text-center cursor-pointer `}>
                <div className="flex flex-row items-center gap-2 justify-end whitespace-nowrap pl-1">

                    <span onClick={handleClick}
                          className="hover:underline whitespace-nowrap"
                          title={`${isSelected() ? "Deseleziona" : "Seleziona"} cartellino`}>MAG-{row.id}</span>
                </div>
            </td>
            <td className="text-center"><LotLink nrlotto={row.nrlotto}/></td>
            <td className="pl-2"><DocumentInfo document={row.document}/></td>
            <td>{row.article.name} - {row.color.name}</td>
            <td className="w-24 text-right pr-1">{formatInt(row.available_leathers)}</td>
            {displayCosts && <td className="w-32 text-right pr-1">{formatPrice(row.costs.per_leather)}€</td>}
            {displayCosts && <td className="w-24 text-right pr-1">{formatPrice(row.costs.progressive)}€</td>}
        </TableRow>
    );
};

export default InHouseRow;