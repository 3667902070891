import React, {useEffect} from 'react';
import {useRegistry} from "../../../utilities/contexts/registry-context";
import {doGetProcesses} from "../../../logics/registry";
import useAsync from "../../../utilities/customHooks/async-call";
import SearchSelect from "./SearchSelect";
import parseOptions from "../../../utilities/data/parseOptions";

const SelectProcess = ({value, onChange}) => {

    const {processes, loadProcesses} = useRegistry();
    const {call} = useAsync();

    const handleChange = (_value) => {
        onChange && onChange(_value);
    }

    const getOptions = () => {
        return processes.map(process => parseOptions.parseProcess(process));
    }

    useEffect(() => {
        const load = async () => {
            if(!processes || !processes.length > 0) {
                await call(
                    () => doGetProcesses(),
                    (res) => {
                        console.log(res);
                        loadProcesses(res);
                    }
                )
            }
        }
        load();
    }, []);

    return (
        <SearchSelect options={getOptions()} value={value} onChange={handleChange}/>
    );
};

export default SelectProcess;