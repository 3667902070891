import React, {useEffect} from 'react';
import SearchSelect from "./SearchSelect";
import {doGetArticles, doGetProcesses} from "../../../logics/registry";
import {useRegistry} from "../../../utilities/contexts/registry-context";
import useAsync from "../../../utilities/customHooks/async-call";
import parseOptions from "../../../utilities/data/parseOptions";

const SearchSelectArticle = ({value, onChange, ...props}) => {

    const {articles, loadArticles} = useRegistry();
    const {call} = useAsync();

    const getOptions = () => {
        return articles.map(article => parseOptions.parseArticle(article));
    }

    const handleChange = (val) => {
        console.log(val);
        onChange && onChange(val);
    }

    useEffect(() => {
        const load = async () => {
            if(!articles || !articles.length > 0) {
                await call(
                    () => doGetArticles(),
                    (res) => {
                        console.log(res);
                        loadArticles(res);
                    }
                )
            }
        }
        load();
    }, []);

    return (
        <SearchSelect options={getOptions()} value={value} onChange={handleChange}/>
    );
};

export default SearchSelectArticle;