import React, {useEffect, useState} from 'react';
import {Outlet, useMatch, useNavigate, useParams} from "react-router-dom";
import {useLot} from "../../utilities/contexts/lot-context";
import useAsync from "../../utilities/customHooks/async-call";
import {doGetLotHeader, doGetLotRows} from "../../logics/lots";
import {BiArrowBack} from "react-icons/bi";
import Loading from "../UI/Loading";
import useToast from "../../utilities/customHooks/toast";

const Lot = () => {

    const {state, setCurrentLot, loadRows} = useLot();

    const {call} = useAsync();
    const params = useParams();
    const navigate = useNavigate();
    const {toastError} = useToast();
    const matchPath = useMatch('/lotti/:nr/movimenti');

    const [loading, setLoading] = useState(true);

    const patternMatch = () => {
        return matchPath && matchPath.pattern;
    }

    const goBack = () => {
        navigate('/lotti/lista', {replace:true});
    }

    const goMovements = () => {
        navigate('movimenti');
    }

    const goHeader = () => {
        navigate(`/lotti/${params.nr}`);
    }

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            await call(
                () => doGetLotHeader(params.nr),
                (header) => {
                    console.log(header);
                    if(header) {
                        let fullLot = {...header}
                        setCurrentLot(fullLot);
                    } else {
                        alert("missing header");
                    }
                },
                (err) => {
                    console.log(err);
                    setCurrentLot(null);
                    toastError("Errore caricamento Lotto");
                    navigate('/lotti/lista', {replace:true});
                }
            );


            await call(
                () => doGetLotRows(params.nr),
                (rows) => {
                    if(rows) {
                        console.log(rows);
                        loadRows(rows);
                    }
                }
            )

            console.log(state);

            await setLoading(false);
        }

        console.log("loading " + params.nr);
        load();
    }, [])

    return (
        <div className="flex flex-col h-full" style={{minHeight: "30px"}} >
            <div className="flex flex-row gap-2 items-center bg-secondary bg-opacity-50 pl-2">
                <button onClick={goBack} title="Torna alla lista"><BiArrowBack /></button>
                <button onClick={goHeader}
                        title="Vai a Situazione Lotto"
                        className={`${!patternMatch() ? "font-bold underline" : ""}`}>Situazione Lotto {params.nr}</button>
                <button onClick={goMovements}
                        title="Vai a Movimenti"
                        className={`${patternMatch() ? "font-bold underline" : ""}`} >Movimenti</button>
            </div>

            {loading ?
                <Loading label={"Caricamento lotto"} />
                :
                <Outlet />
            }

        </div>
    );
};

export default Lot;