import React, {useState, useRef} from 'react';
import {useReactToPrint} from "react-to-print";
import {useQueries} from "../../../utilities/contexts/queries-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {doGetInHouseLeather} from "../../../logics/queries";
import {printPageStyle} from "../../../utilities/data/tools";
import {formatInt, formatPrice} from "../../../utilities/data/parser";
import Search from "./Search";
import Loading from "../../UI/Loading";
import InHouseTable from "./InHouseTable";
import RowAligned from "../../UI/Structure/RowAligned";
import PrintButton from "../../UI/PrintButton";
import HiddenPrintContainer from "../../UI/HiddenPrintContainer";
import InHousePrintable from "./InHousePrintable";
import PrintableTags from "./PrintableTags";

const InHouseLeathers = () => {
    const [loading, setLoading] = useState(false);
    const [displayCosts, setDisplayCosts] = useState(false);

    const {call} = useAsync();
    const {inHouseRows, loadInHouseRows, inHouseTotals, loadInHouseTotals, inHouseSelected, toggleAllInHouse} = useQueries();

    const componentToPrint = useRef();
    const tagsToPrint = useRef();

    const handleSubmit = async (data) => {
        setLoading(true);
        setDisplayCosts(data.showCosts);

        await call(
            () => doGetInHouseLeather(data.nrlotto, data.date, data.showCosts),
            (res) => {
                if (res?.movements) {
                    loadInHouseRows(res.movements);
                } else {
                    loadInHouseRows([]);
                }

                loadInHouseTotals(res.totals ?? null);
                console.log(res);
            }
        )
        setLoading(false);
    }

    const handleToggleAll = () => {
        toggleAllInHouse();
    }

    const handlePrint = useReactToPrint({
        pageStyle: printPageStyle,
        content: () => componentToPrint.current,
        documentTitle: `pelli_in_sede`
    });

    const handlePrintTags = useReactToPrint({
        pageStyle: printPageStyle,
        content: () => tagsToPrint.current,
        documentTitle: `pelli_in_sede_cartellini`
    });


    return (
        <div className="flex flex-col flex-1 " style={{minHeight: "100px"}}>

            <Search onSubmit={handleSubmit}/>

            <div className="flex flex-col flex-1 overflow-y-auto relative" style={{minHeight: "50px"}}>
                {loading ?
                    <Loading label="Interrogazione in corso"/>
                    :
                    <InHouseTable rows={inHouseRows}
                                  displayCosts={displayCosts}
                                  selectAll={handleToggleAll}/>
                }
            </div>

            {!loading && inHouseRows.length > 0 &&
            <div className="flex flex-row justify-between font-medium border-t border-black">
                <RowAligned className="p-1 gap-2">
                    <PrintButton title="Stampa lista" onClick={handlePrint}/>
                    {inHouseSelected && inHouseSelected.length > 0 &&
                        <PrintButton title="Stampa cartellini" onClick={handlePrintTags} />
                    }
                </RowAligned>
                <RowAligned>
                    <div className="pr-1">Totali:</div>
                    <div className={`font-bold ${!displayCosts && "pr-2"}`}>{formatInt(inHouseTotals?.nrpz)}</div>
                    {displayCosts &&
                    <>
                        <div className="w-32" />
                        <div className="w-24">{formatPrice(inHouseTotals?.costs)}€</div>
                    </>
                    }

                </RowAligned>
            </div>
            }

            <HiddenPrintContainer>
                <div ref={componentToPrint}>
                    <InHousePrintable rows={inHouseRows} total={inHouseTotals?.nrpz} totalCosts={inHouseTotals?.costs} displayCosts={displayCosts} />
                </div>
            </HiddenPrintContainer>

            <HiddenPrintContainer>
                <div ref={tagsToPrint} className="">
                    {inHouseSelected && inHouseSelected.length > 0 &&
                        inHouseSelected.map((row,i) => <PrintableTags row={row} key={i} />)
                    }
                </div>
            </HiddenPrintContainer>
        </div>
    );
};

export default InHouseLeathers;
