import React from 'react';
import {FaTag} from "react-icons/fa";
import TableHeader from "../General/TableHeader";
import Table from "../General/Table";
import EmptyQuery from "../General/EmptyQuery";
import InHouseRow from "./InHouseRow";

const InHouseTable = ({rows, displayCosts, selectAll}) => {
    return (
        <div>
            {rows && rows.length > 0 ?
                <Table>
                    <TableHeader>
                        <th className="pl-2"><FaTag className="cursor-pointer hover:text-blue-500" title="Seleziona tutti" onClick={selectAll}/></th>
                        <th className="text-center">Cartellino</th>
                        <th className="text-center">Lotto</th>
                        <th className="pl-2">Documento e Data</th>
                        <th>Articolo - Colore</th>
                        <th className="text-right">Nr.pelli ferme</th>
                        {displayCosts && <th className="text-right">Costo al pezzo</th>}
                        {displayCosts && <th className="text-right">Valore</th>}
                    </TableHeader>
                    <tbody>
                    {rows.map((row,i) =>
                        <InHouseRow key={i} row={row} displayCosts={displayCosts}/>
                    )}
                    </tbody>
                </Table>
                :
                <EmptyQuery />
            }

        </div>
    );
};

export default InHouseTable;