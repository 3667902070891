import React from 'react';
import {formatInt, formatPrice} from "../../../utilities/data/parser";
import Table from "../General/Table";
import TableHeader from "../General/TableHeader";
import TableRow from "../General/TableRow";
import EmptyQuery from "../General/EmptyQuery";

const WeeklySalesTable = ({rows, total_mq, total_pq, unsold=false, total_leathers_unsold}) => {
    return (
        <div>
            {rows && rows.length > 0 ?
                <Table>
                    <TableHeader>
                        <th className="pl-2">Cliente</th>
                        <th className="pl-2">Articolo - Colore</th>
                        <th className="text-right">{unsold? "Nr.pelli" : "Mq"}</th>
                        <th className="text-right">{unsold ? "Mq" : "Pq"}</th>
                    </TableHeader>
                    <tbody>
                    {rows.map((row,i) =>
                        <TableRow key={i}>
                            <td className="text-left pl-2">{row?.client?.name}</td>
                            <td className="pl-2">{row?.article?.name} - {row?.color?.name}</td>
                            <td className="text-right pl-2">{unsold ? formatInt(row?.nrpz) : formatPrice(row?.mq)}</td>
                            <td className="text-right">{unsold ? formatPrice(row?.mq) : formatPrice(row?.pq)}</td>
                        </TableRow>
                    )}
                    </tbody>
                    <tfoot>
                    <tr className="border-t border-black font-bold">
                        <td colSpan={2} className="text-right">Totali:</td>
                        <td className="text-right">{unsold ? formatInt(total_leathers_unsold) : formatPrice(total_mq)}</td>
                        <td className="text-right">{formatPrice(total_pq)}</td>
                    </tr>
                    </tfoot>
                </Table>
                :
                <EmptyQuery />
            }

        </div>
    );
};

export default WeeklySalesTable;