import React from 'react';
import {parseDate} from "../../../utilities/data/parser";

const DocumentInfo = ({document, hideType = false, hideCompany = false}) => {
    if (document)
        return (
            <>
                {hideType ? "" : document?.type?.name ?? "..."} {document?.nrdoc ?? document?.nrdocfor ?? "..."} del {(document?.datadoc ?? document?.datadocfor) ? parseDate(document?.datadoc ?? document?.datadocfor) : "..."}
                {hideCompany ? "" : (document.company || document.company_name) && " - " + (document.company ?? document.company_name ?? "...")}
            </>
        );
    else return null;
};

export default DocumentInfo;
