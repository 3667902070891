import React from 'react';
import EditableText from "../UI/EditableText";
import {MdOutlineEmail, MdOutlinePhone} from "react-icons/md";
import {BiMap} from "react-icons/bi";
import {AiOutlineIdcard} from "react-icons/ai";
import {doPutSupplier} from "../../logics/registry";
import useAsync from "../../utilities/customHooks/async-call";
import {useRegistry} from "../../utilities/contexts/registry-context";

const SupplierPanel = ({supplier, ...props}) => {

    const {updateSupplier, setCurrentSupplier} = useRegistry();
    const {call} = useAsync();

    const handleEditSupplier = async (data) => {
        await call(
            () => doPutSupplier(supplier.idforn, data),
            (editedClient) => {
                updateSupplier(editedClient);
                setCurrentSupplier(editedClient);
            },
        );
    }

    return (
        <div>
            <div className="font-bold text-xl mb-2 border-b-2 border-gray-200 pb-1">
                <EditableText value={supplier && supplier.ragsocf}
                              handleConfirm={(value) => handleEditSupplier({ragsocf: value})}/>
            </div>

            <div className="mb-1 pb-1 border-b border-gray-200">
                <div className="flex flex-row items-center">
                    <MdOutlinePhone className='mr-2'/>
                    <EditableText value={supplier && supplier.contatto}
                                  handleConfirm={(value) => handleEditSupplier({contatto: value})}/>

                </div>
                <div className="flex flex-row items-center">
                    <MdOutlineEmail className='mr-2'/>
                    <EditableText value={supplier && supplier.email}
                                  handleConfirm={(value) => handleEditSupplier({email: value})}/>
                </div>
            </div>

            <div className="flex flex-row items-center">
                <BiMap className='mr-2'/>
                <EditableText value={supplier && supplier.indf}
                              handleConfirm={(value) => handleEditSupplier({indf: value})}/>
            </div>
            <div className="flex flex-row items-center">
                <AiOutlineIdcard className='mr-2'/>
                <EditableText value={supplier && supplier.pivaf}
                              handleConfirm={(value) => handleEditSupplier({pivaf: value})}/>
            </div>

            <div className="italic text-gray-400 text-xs text-center my-1">Campi modificabili con doppio click</div>
        </div>
    )
}

export default SupplierPanel;
