import React, {useEffect, useState} from 'react';
import Layout from "../components/UI/Layout";
import useAsync from "../utilities/customHooks/async-call";
import {doGetClients} from "../logics/registry";
import ClientTable from "../components/Clients/ClientTable";
import BaseInput from "../components/UI/BaseInput";
import Modal from "../components/UI/Modal";
import {useRegistry} from "../utilities/contexts/registry-context";
import ClientPanel from "../components/Clients/ClientPanel";
import useFilter from "../utilities/customHooks/filter";
import NewClientForm from "../components/Clients/NewClientForm";
import {AiOutlineReload} from "react-icons/ai";

const Clients = () => {

    const {call} = useAsync();
    const {clients, loadClients, panelOpen, currentClient, setRegistryPanelOpen, setCurrentClient, createPanelOpen, setCreatePanelOpen} = useRegistry();

    const filterByName = (client) => {
        return client.ragsoccl.toLowerCase().includes(filterText.toLowerCase());
    }

    const {filterText, setFilterText, filteredData} = useFilter(clients, filterByName);

    const reload = async () => {
        setFilterText("");
        await call(
            () => doGetClients(),
            (clients) => loadClients(clients),
            (error) => console.log(error),
        )
    }

    useEffect(() => {

        const loadData = async () => {
            await reload();
        }

        loadData();

    }, []);

    return (
        <Layout title={'Clienti'}>
            <div className="h-full flex flex-col" style={{minHeight: "10px"}}>
                <div className='mb-4'>
                    <BaseInput placeholder={'Cerca cliente'}
                               className={'w-full mb-1'}
                               value={filterText}
                               onChange={(e) => setFilterText(e.target.value)}/>
                    <div className="text-sm pl-1 flex flex-row gap-1 items-center">
                        <span>Clienti elencati:</span>
                        <span>{filteredData.length}</span>
                        <AiOutlineReload onClick={reload} className="hover:text-gray-200 cursor-pointer"
                                         title="Ricarica clienti"/>
                    </div>
                </div>

                <div className="h-full overflow-y-auto">
                    <ClientTable clients={filteredData}/>
                </div>
            </div>

            <Modal open={panelOpen} onOverlayClick={() => {
                setRegistryPanelOpen(false);
                setCurrentClient(null)
            }}>
                <ClientPanel client={currentClient}/>
            </Modal>

            <Modal open={createPanelOpen} onOverlayClick={() => {
                setCreatePanelOpen(false)
            }}>
                <NewClientForm/>
            </Modal>
        </Layout>
    )
}

export default Clients;
