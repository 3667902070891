import React from 'react';
import ModalTitleWithCloseButton from "../Creation/UI/ModalTitleWithCloseButton";
import {useLot} from "../../../../utilities/contexts/lot-context";
import VerticalLabelledField from "../../../UI/Fields/VerticalLabelledField";
import FormTitle from "../Creation/UI/FormTitle";
import DocumentInfo from "../../../UI/DataDisplay/DocumentInfo";
import RowAligned from "../../../UI/Structure/RowAligned";
import {priceFormat} from "../../../../utilities/data/parser";

const SimpleView = () => {

    const {currentLot, currentMovement} = useLot();

    return (
        <div className="flex flex-col gap-1">
            <ModalTitleWithCloseButton title="Visualizza dettagli"
                                       modal="view"/>
            <div className="flex flex-row items-start gap-2 justify-between">
            <VerticalLabelledField label="Nr. Lotto">
                <span className="font-bold">{currentLot.nrlotto}</span>
            </VerticalLabelledField>
            <VerticalLabelledField label="Nr. Pezzi">
                {currentMovement.nrpz}
            </VerticalLabelledField>
            <VerticalLabelledField label="Nr. Pezzi disponibili">
                {currentMovement.available_leathers ?? 0}
            </VerticalLabelledField>
            </div>
            <div className="border-b border-gray-400"/>
            <div className="flex flex-row items-start gap-2 justify-between">
                <VerticalLabelledField label="Documento">
                    <DocumentInfo document={currentMovement.document}/>
                </VerticalLabelledField>
                <VerticalLabelledField label="Azienda">
                    {currentMovement.document?.company ?? <span className="text-gray-400 italic text-sm">Non rilevata</span>}
                </VerticalLabelledField>
            </div>
            <VerticalLabelledField label="Articolo">{currentMovement?.article?.name}</VerticalLabelledField>
            <VerticalLabelledField label="Colore">{currentMovement?.color?.name}</VerticalLabelledField>
            <VerticalLabelledField label="Lavorazione">{currentMovement?.processing?.name}</VerticalLabelledField>
            <VerticalLabelledField label="Note movimento">{currentMovement?.notemov ?? <span className="text-gray-400 italic text-sm">Nessuna nota presente</span>}</VerticalLabelledField>
            <VerticalLabelledField label="Quantità movimento">
                ?
            </VerticalLabelledField>
            <div className="border-b border-gray-400"/>
            <div className="flex flex-row items-start gap-2 justify-between">
                <VerticalLabelledField label="Costo totale lavorazione">
                    {priceFormat(currentMovement?.costs?.movement)}€
                </VerticalLabelledField>
                <VerticalLabelledField label="Costo al pezzo">
                    {priceFormat(currentMovement?.costs?.per_leather)}€
                </VerticalLabelledField>
                <VerticalLabelledField label="Costo parziale al pezzo">
                    {priceFormat(currentMovement?.costs?.progressive_per_leather)}€
                </VerticalLabelledField>
            </div>
            <VerticalLabelledField label="Pelli inviate a">
                {currentMovement?.subcontractor?.name ?? currentMovement?.client?.name}

            </VerticalLabelledField>
        </div>
    );
};

export default SimpleView;