import React from 'react';
import {FaChevronDown, FaChevronUp} from "react-icons/fa";

const Toggle = ({open, onClick, inverted = false, className, ...props}) => {

    const handleClick = () => {
        onClick && onClick();
    }

    return (
        <div onClick={handleClick}
             className={`cursor-pointer ${className}`}
             title={open ? 'Chiudi' : 'Apri'}
             {...props}>
            {(inverted ? !open : open) ?
                <FaChevronUp/>
                :
                <FaChevronDown/>
            }
        </div>
    );
};

export default Toggle;
