import React from 'react';

const HorizontalLabelledField = ({label, className, ...props}) => {
    return (
        <div className={`flex flex-row items-center text-center leading-none gap-2 ${className}`} {...props}>
            <div className="uppercase font-medium text-gray-500 leading-none mt-0.5"
                 style={{fontSize: "10px"}}>{label}</div>
            <div>{props.children}</div>
        </div>
    );
};

export default HorizontalLabelledField;