import React, {useEffect} from 'react';
import {useRegistry} from "../../../utilities/contexts/registry-context";
import useAsync from "../../../utilities/customHooks/async-call";
import {doGetMeasureUnits} from "../../../logics/registry";
import Select from "../Select";

const SelectMeasureUnits = ({onChange, ...props}) => {

    const {measure_units, loadMeasureUnits} = useRegistry();
    const {call} = useAsync();

    const handleChange = (e) => {
        onChange && onChange(e.target.value);
    }

    useEffect(() => {
        const load = async () => {
            await call(
                () => doGetMeasureUnits(),
                (res) => {
                    res && res.length > 0 && loadMeasureUnits(res);
                }
            )
        }

        if(!measure_units || measure_units.length === 0)
            load();
    }, []);
    return (
        <Select onChange={handleChange} {...props}>
            <option value="" disabled={true}>Seleziona unità di misura</option>
            {measure_units && measure_units.length > 0 &&
                measure_units.map((unit, i) => <option key={i} value={unit.idum}>{unit.um}</option>)
            }
        </Select>
    );
};

export default SelectMeasureUnits;