import React from 'react';

const TableHeader = ({className = ""}) => {
    return (
        <thead className={`text-black bg-primary bg-opacity-40 ${className}`}>
        <tr>
            <td colSpan={7} className="font-bold pl-2 uppercase leading-none pt-2">Righe lotto</td>
        </tr>
        <tr className="font-medium">
            <td className="pl-2">Articolo</td>
            <td className="">Colore</td>
            <td className="">Scelta</td>
            <td className=" text-right">Superficie</td>
            <td className=" text-right">Nr.Pz</td>
            <td className=" text-right">Prezzo Un.</td>
            <td></td>
        </tr>
        </thead>
    )
};

export default TableHeader;
