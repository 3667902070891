import React from 'react';

const Row = ({className="gap-1", ...props}) => {
    return (
        <div className={`flex flex-row ${className}`} {...props}>
            {props.children}
        </div>
    );
}

export default Row;
