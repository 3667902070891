import React from 'react';

const Table = ({className, style, ...props}) => {
    return (
        <table className={`border border-black border-collapse ${className}`} style={style ?? {fontSize: "10px"}} {...props}>
            {props.children}
        </table>
    );
};

export default Table;