import React, {useEffect} from 'react';
import {useRegistry} from "../../../utilities/contexts/registry-context";
import {doGetColors} from "../../../logics/registry";
import useAsync from "../../../utilities/customHooks/async-call";
import SearchSelect from "./SearchSelect";
import parseOptions from "../../../utilities/data/parseOptions";

const SelectColors = ({value, onChange, ...props}) => {

    const {colors, loadColors} = useRegistry();
    const {call} = useAsync();

    const getOptions = () => {
        return colors.length > 0
            ?colors.map(color => parseOptions.parseColor(color))
            :[];
    }

    const handleChange = (val) => {
        onChange && onChange(val);
    }

    useEffect(() => {
        const load = async () => {
            if(!colors || !colors.length > 0) {
                await call(
                    () => doGetColors(),
                    (res) => {
                        console.log(res);
                        loadColors(res);
                    }
                )
            }
        }
        load();
    }, [])

    return (
        <SearchSelect value={parseOptions.parseColor(value)} options={getOptions()} onChange={handleChange}/>
    );
};

export default SelectColors;
