import React from 'react';
import Modal from "../../../UI/Modal";
import {useLot} from "../../../../utilities/contexts/lot-context";
import BaseInput from "../../../UI/BaseInput";
import Button from "../../../UI/Button";
import useAsync from "../../../../utilities/customHooks/async-call";
import {doPostLotFirstMovement} from "../../../../logics/lots";
import FirstMovementDetails from "../FirstMovementDetails";

const FirstMovementCreation = ({open, onOverlayClick, ...props}) => {

    const {currentLot} = useLot();
    const {call} = useAsync();

    const formRef = React.createRef();

    const handleSubmit = async (e) => {
        e.preventDefault();

        let datareg = formRef.current.datareg.value;
        let notereg = formRef.current.notereg.value;

        const fields = {
            datareg,
            notereg
        }

        console.log(fields);

        await call(
            () => doPostLotFirstMovement(currentLot.nrlotto, fields),
            (res) => {
                console.log(res);
                //TODO load first movement (tree)

                //TODO close form

                //TODO remove btn?
            },
            (err) => {
                console.log(err);
            }
        )

        onOverlayClick && onOverlayClick();
    }

    return (
        <Modal open={open} onOverlayClick={onOverlayClick}>
            <div>Aggiungi il primo movimento del lotto {currentLot.nrlotto}</div>

            <FirstMovementDetails />

            <form onSubmit={handleSubmit} ref={formRef}>

                <BaseInput label="Data registrazione" type="date" name="datareg" required />
                <div className="mt-2">
                    <div className="p-0 m-0 text-xs uppercase text-gray-600">Note registrazione</div>
                    <textarea name="notereg" className="w-full p-1 bg-black bg-opacity-5 rounded shadow-inner " />
                </div>
                <Button type="submit">Conferma</Button>
            </form>
        </Modal>
    );
};

export default FirstMovementCreation;