import React from 'react';
import actions from "../actions/tree";
import treeReducer, {defaultState} from "../reducers/tree-reducer";

const TreeContext = React.createContext();

function useTreeContextValue() {
    const [state, dispatch] = React.useReducer(treeReducer, defaultState);

    const {tree} = state;

    const loadTree = React.useCallback((nodes) => dispatch(actions.loadTree(nodes)), []);
    const updateNode = React.useCallback((node) => dispatch(actions.updateNode(node)), []);
    const addNode = React.useCallback((original, new_node) => dispatch(actions.addNode(original, new_node)), []);
    const removeNode = React.useCallback((idnodo) => dispatch(actions.removeNode(idnodo)), []);
    const setNodeOpen = React.useCallback((id, open) => dispatch(actions.setNodeOpen(id, open)), []);

    return React.useMemo(() => {
        return {
            state,
            tree,
            loadTree,
            updateNode,
            addNode,
            removeNode,
            setNodeOpen,
        }
    }, [
        state, tree,
        loadTree, addNode, removeNode,
        updateNode, setNodeOpen,
    ]);
}

function TreeProvider(props) {
    const value = useTreeContextValue();
    return <TreeContext.Provider value={value} {...props} />;
}

function useTree() {
    const context = React.useContext(TreeContext);
    if(!context)
        throw new Error("useTree must be used within a TreeProvider");
    return context;
}

export {
    TreeProvider,
    useTree,
}