import React from 'react';
import DocumentInfo from "../../UI/DataDisplay/DocumentInfo";
import {parseDate} from "../../../utilities/data/parser";

const Row = ({label, ...props}) => {
    return (
        <tr className="border-2 leading-none p-0 m-0" {...props}>
            <td className="border" style={{width: "82px"}}>{label}</td>
            <td className="border" style={{width: "212px"}}>{props.children}</td>
        </tr>
    );
}

const PrintableTags = ({row}) => {
    return (
        <div className="printable_container  mb-4">
            <div className="p-1 border-2 border-black" style={{maxWidth: "320px"}}>
            <table className=" printable_tag border-collapse" style={{width: "300px"}}>
                <thead>
                    <tr>
                        <td colSpan="2">
                            <div className="w-full flex flex-row items-center justify-between ">
                                <div className="font-bold ">ZETAPELLI S.R.L.</div>
                                <div>MAG-{row.id}</div>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody className="text-sm" style={{fontSize: "12px"}}>
                    <Row label="Data:">{parseDate(row?.data)}</Row>
                    <Row label="Lotto:">{row.nrlotto}</Row>
                    <Row label="Rifinizione:">{row?.subcontractor?.name}</Row>
                    <Row label="DDT:"><DocumentInfo document={row.document} /></Row>
                    <Row label="Articolo:">{row?.article?.name}</Row>
                    <Row label="Colore:">{row?.color?.name}</Row>
                    <Row label="Pezzi:">{row.nrpz}</Row>
                    <Row label="Pelli ferme:">{row.available_leathers}</Row>
                </tbody>
            </table>
            </div>
        </div>
    );
};

export default PrintableTags;