import React from 'react';
import Table from "../General/Table";
import DocumentInfo from "../../UI/DataDisplay/DocumentInfo";
import TableRow from "../General/TableRow";
import TableHeader from "../General/TableHeader";
import {formatPrice} from "../../../utilities/data/parser";

const SubcontractorDocTable = ({subcontractor}) => {
    return (
        <div className="mb-4">
            <Table>
                <TableHeader tr={
                    <tr className="text-center border-t border-b border-black bg-secondary bg-opacity-40">
                        <th colSpan={7}>{subcontractor?.name}</th>
                    </tr>
                }>
                    <th className="text-left pl-1">Lotto</th>
                    <th className="text-left">Documento</th>
                    <th className="text-left">Tipo Lav.</th>
                    <th className="text-right border-l border-black pr-1">Nr.Pz</th>
                    <th className="text-right border-l border-black pr-1">Quantità</th>
                    <th className="text-right border-l border-black pr-1">Prezzo</th>
                    <th className="text-right border-l border-black pr-1">Importo</th>
                </TableHeader>
                <tbody>
                {subcontractor?.documents && subcontractor?.documents?.length > 0 &&
                    subcontractor.documents.map((row, i) =>
                        <TableRow className="text-right">
                            <td className="text-left pl-1">{row.nrlotto}</td>
                            <td className="text-left"><DocumentInfo document={row.doc} hideCompany={true} hideType={true}/> </td>
                            <td className="text-left">{row?.processing?.name}</td>
                            <td className="border-l border-black pr-1">{row?.nrpz}</td>
                            <td className="border-l border-black pr-1">
                                {row?.costs?.row1?.qta1 > 0 && <div>{formatPrice(row?.costs?.row1?.qta1)} {row?.costs?.row1?.um1?.name}</div>}
                                {row?.costs?.row2?.qta2 > 0 && <div>{formatPrice(row?.costs?.row2?.qta2)} {row?.costs?.row2?.um2?.name}</div>}
                                {row?.costs?.row3?.qta3 > 0 && <div>{formatPrice(row?.costs?.row3?.qta3)} {row?.costs?.row3?.um3?.name}</div>}
                            </td>
                            <td className="border-l border-black pr-1">
                                {row?.costs?.row1?.qta1 > 0 && <div>{formatPrice(row?.costs?.row1?.prezzo1)}</div>}
                                {row?.costs?.row2?.qta2 > 0 && <div>{formatPrice(row?.costs?.row2?.prezzo2)}</div>}
                                {row?.costs?.row3?.qta3 > 0 && <div>{formatPrice(row?.costs?.row3?.prezzo3)}</div>}
                            </td>
                            <td className="border-l border-black pr-1">
                                {row?.costs?.row1?.qta1 > 0 && <div>{formatPrice(row?.costs?.row1?.qta1 * row?.costs?.row1?.prezzo1)}</div>}
                                {row?.costs?.row2?.qta2 > 0 && <div>{formatPrice(row?.costs?.row2?.qta2 * row?.costs?.row2?.prezzo2)}</div>}
                                {row?.costs?.row3?.qta3 > 0 && <div>{formatPrice(row?.costs?.row3?.qta3 * row?.costs?.row3?.prezzo3)}</div>}
                            </td>
                        </TableRow>
                    )
                }
                </tbody>
                <tfoot>
                    <tr className="border-t border-b border-black text-right">
                        <td colSpan={7}>{formatPrice(subcontractor?.totals?.amount)}</td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    );
};

export default SubcontractorDocTable;