import API from "./API";

async function doGetClients() {
    return await API.get('/clients');
}

async function doGetClient(id) {
    return await API.get(`/clients/${id}`);
}

async function doPutClient(id, data) {
    return await API.put(`/clients/${id}`, data);
}

async function doPostClient(data) {
    return await API.post(`/clients/`, data);
}

async function doGetSuppliers () {
    return API.get('/suppliers');
}

async function doGetSupplier(id) {
    return API.get(`/suppliers/${id}`)
}

async function doPutSupplier(id, data) {
    return API.put(`/suppliers/${id}`, data);
}

async function doPostSupplier(params) {
    return API.post('/suppliers/', params);
}

async function doGetSubcontractors () {
    return API.get('/subcontractors');
}

async function doGetSubcontractor(id) {
    return API.get(`/subcontractors/${id}`);
}

async function doPutSubcontractor(id, data) {
    return API.put(`/subcontractors/${id}`, data);
}

async function doPostSubcontractor(params) {
    return API.post('/subcontractors/', params);
}

async function doGetDocumentTypes() {
    return API.get('/document_types');
}

async function doGetProcesses() {
    return API.get('/processings');
}

async function doGetProcessing(id) {
    return API.get(`/processings/${id}`);
}

async function doGetArticles() {
    return API.get('/articles');
}

async function doGetArticle(id) {
    return API.get(`/articles/${id}`);
}

async function doGetColors() {
    return API.get('/colors');
}

async function doGetColor(id) {
    return API.get(`/colors/${id}`);
}

async function doGetMeasureUnits() {
    return API.get('/measure_units');
}

async function doGetChoices() {
    return API.get('/choices');
}

export {
    doGetClients,
    doGetClient,
    doPutClient,
    doPostClient,
    doGetSuppliers,
    doGetSupplier,
    doPutSupplier,
    doPostSupplier,
    doGetSubcontractors,
    doGetSubcontractor,
    doPutSubcontractor,
    doPostSubcontractor,
    doGetDocumentTypes,
    doGetProcesses,
    doGetProcessing,
    doGetArticles,
    doGetArticle,
    doGetColors,
    doGetColor,
    doGetMeasureUnits,
    doGetChoices,
}