import React, {useEffect, useState} from 'react';
import SelectSupplier from "../UI/Selects/SelectSupplier";
import SearchSelectArticle from "../UI/Selects/SearchSelectArticle";
import SelectColors from "../UI/Selects/SelectColors";
import {doGetLots} from "../../logics/lots";
import {useLot} from "../../utilities/contexts/lot-context";
import useAsync from "../../utilities/customHooks/async-call";
import BaseInput from "../UI/BaseInput";

const LotsTableHeader = ({setLoading}) => {

    const {lots, loadLots, loadPagination} = useLot();
    const {call} = useAsync();

    const [oldNumber, setOldNumber] = useState("");

    const [nrLotto, setNrLotto] = useState(""); //nr
    const [supplier, setSupplier] = useState(null); //supplier
    const [article, setArticle] = useState(null); //article
    const [color, setColor] = useState(null); //color

    const nrLotRef = React.createRef();

    const onArticleChange = async (article) => {
        setArticle(article);
    }

    const searchWithFilters = async () => {
        setOldNumber(nrLotto);
        let params = new URLSearchParams();

        article && params.append('article', article.id);
        color && params.append('color', color.id);
        supplier && params.append('supplier', supplier.id);
        nrLotto && params.append('lots', nrLotto);

        let urlPar = "?" + params.toString();

        setLoading(true);
        await call(() => doGetLots(urlPar),
            (data, pagination) => {
                console.log(data);
                loadLots([...data]);
                loadPagination(pagination);
            });
        setLoading(false);
    }

    const addParams = (condition, paramToAdd, oldParams) => {
        let urlParam = "";
        if (oldParams.includes('=')) {
            urlParam = "&";
        }
        return condition ? urlParam + paramToAdd : ""
    }

    const handleKeyPress = (e) => {
        if(e.key === "Enter"&& nrLotto !== oldNumber) {
            searchWithFilters()
        }
    }

    const handleOnBlur = () => {
        if(nrLotto !== oldNumber)
            searchWithFilters();
    }


    useEffect(() => {
        console.log("article changed");
        searchWithFilters();
    }, [article, color, supplier]);

    return (
        <thead className="sticky top-0 bg-white shadow-md text-sm">
        <tr className="text-left">
            <th className="align-top">
                <div className="flex flex-col items-start px-2">
                    <div>NrLotto</div>
                    <BaseInput type={"text"}
                           className="w-16 h-6 pl-2 mb-1"
                           placeholder="Nr"
                           value={nrLotto}
                           onChange={(e) => setNrLotto(e.target.value)}
                           onBlur={handleOnBlur}
                           onKeyPress={handleKeyPress}
                           ref={nrLotRef}
                           />
                </div>
            </th>
            <th className="align-top">
                <div className="flex flex-col justify-start w-full">
                    <div>Fornitore</div>
                    <SelectSupplier onChange={(e) => setSupplier(e)} value={supplier}/>
                </div>
            </th>
            <th className="align-top">
                <div className="flex flex-col h-full justify-start">
                    <div>Data Reg</div>
                    {/*<input type={"date"} className="bg-gray-100"/>*/}
                </div>
            </th>
            <th className="align-top">
                <div className="flex flex-col">
                    <div>Articolo</div>
                    <SearchSelectArticle value={article} onChange={onArticleChange}/>
                </div>
            </th>
            <th className="align-top">
                <div className="flex flex-col">
                    <div>Colore</div>
                    {/*<SelectColors*/}
                    {/*    value={color}*/}
                    {/*    onChange={(e) => {*/}
                    {/*        console.log("COLOR CHANGED")*/}
                    {/*        console.log(e);*/}
                    {/*        setColor(e)*/}
                    {/*    }}/>*/}
                </div>
            </th>
            <th className="align-top text-right">Totale</th>
            <th className="align-top text-right"><span className="whitespace-nowrap">In sede</span></th>
            <th className="align-top text-right">Resi</th>
            <th className="align-top text-right">Vendute</th>
            <th className="align-top pr-1 text-right">Azioni</th>
        </tr>
        </thead>
    );
};

export default LotsTableHeader;