import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useApp} from "../../utilities/contexts/app-context";
import useToast from "../../utilities/customHooks/toast";

const RequireAuth = ({children}) => {

    const {authed} = useApp();
    const {toastError} = useToast();
    const location = useLocation();

    const asyncError = async () => {
        await toastError("Effettuare l'accesso.");
    }

    const handleNotAuthed = () => {
        asyncError().then(() => {});
        return (<Navigate to={'/login'}
                         replace
                         state={{path: location.pathname}}
        />);
    }

    return authed !== null && authed === true
        ? <>{children}</>
        : <>{handleNotAuthed()}</>
}

export default RequireAuth;
