import React, {useEffect, useState} from 'react';
import Node from "./Node";
import {useTree} from "../../../utilities/contexts/tree-context";
import Arrows from "../Arrows";
import {useLot} from "../../../utilities/contexts/lot-context";
import MovementPanel from "./MovementPanel";
import MoveForm from "./Creation/Forms/MoveForm";
import MovementDeletion from "./MovementDeletion";
import FirstMovement from "./FirstMovement";
import Modal from "../../UI/Modal";
import BackloadForm from "./Creation/Forms/BackloadForm";
import SellForm from "./Creation/Forms/SellForm";
import UnsellForm from "./Creation/Forms/UnsellForm";
import TagView from "./Modals/TagView";
import SimpleView from "./Modals/SimpleView";
import EditMovementForm from "./EditMovementForm";
import EditForm from "./Creation/Forms/EditForm";

const LotMovements = ({...props}) => {

    const {movements, currentMovement, modal, openModal} = useLot();
    const {loadTree, tree} = useTree();

    useEffect(() => {
        const load = async () => {
            if (movements) {
                console.log(movements);
                await loadTree(movements);
            } else {
                await loadTree(null);
            }
        }
        load();
    }, [movements]);

    return (
        <div className="w-full flex-1 flex flex-col mt-1 " style={{minHeight: "200px"}}>
            {tree ?
                <>
                    <div className="mb-2">
                        {currentMovement
                            ? <MovementPanel/>
                            :
                            <div className="text-gray-400 italic">Clicca su un movimento per vederne il dettaglio</div>}
                    </div>
                    <div className="flex-1 flex flex-col mb-1 " style={{minHeight: "20px"}}>
                        <div className="font-bold uppercase text-gray-800 bg-gray-500 bg-opacity-30 px-1">Movimenti
                        </div>
                        <div className=" relative flex-1 overflow-y-auto bg-gray-500 bg-opacity-10"
                             style={{minHeight: "100px"}}>
                            {tree &&
                            <Arrows/>
                            }
                            {tree &&
                            <Node node={tree}/>
                            }
                        </div>
                    </div>
                </>
                :
                <FirstMovement/>
            }

            {currentMovement &&
            <Modal open={modal.add}
                   className="w-3/4">
                <MoveForm/>
            </Modal>
            }
            <MovementDeletion/>

            {currentMovement &&
            <Modal open={modal.backload}
                   className="w-3/4">
                <BackloadForm/>
            </Modal>
            }
            {currentMovement &&
            <Modal open={modal.sell}
                   className="w-3/4">
                <SellForm/>
            </Modal>
            }

            {currentMovement &&
            <Modal open={modal.unsell}
                   className="w-3/4">
                <UnsellForm/>
            </Modal>
            }

            {currentMovement &&
            <Modal open={modal.tag} onOverlayClick={() => openModal('tag', false)}>
                <TagView/>
            </Modal>
            }

            {currentMovement &&
            <Modal open={modal.view}>
                <SimpleView/>
            </Modal>
            }

            {currentMovement &&
            <Modal open={modal.edit}>
                <EditForm/>
            </Modal>
            }
        </div>
    );
};

export default LotMovements;